import { UserCredential } from 'firebase/auth';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

const appUserCredCookieName = 'ttp-app-user-cred';
const appStateTokenSessionCookieName = 'ttp-app-state-token-session';

export const getUserFromStorage = (): UserCredential => {
  const userFromStorageString = localStorage.getItem(appUserCredCookieName);
  const userFromStorage =
    userFromStorageString && userFromStorageString.length
      ? JSON.parse(userFromStorageString)
      : undefined;
  return userFromStorage;
};

export const setUserInStorage = (user: UserCredential | undefined): void => {
  if (!user) {
    localStorage.removeItem(appUserCredCookieName);
    return;
  }

  const userInStorageString = JSON.stringify(user);
  localStorage.setItem(appUserCredCookieName, userInStorageString);
};

export const setAppStateTokenSessionInStorage = (sessionToken: string | null | undefined): void => {
  if (!sessionToken) {
    localStorage.removeItem(appStateTokenSessionCookieName);
    return;
  }

  localStorage.setItem(appStateTokenSessionCookieName, sessionToken);
};

export interface AuthContextType {
  user: UserCredential | undefined;
  setUser: (user: UserCredential | undefined) => void;
  appStateTokenSession: string | null;
  setAppStateTokenSession: (sessionToken: string | null | undefined) => void;
  signout: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
  const [user, setUser] = useState<UserCredential>();
  const [appStateTokenSession, setAppStateTokenSession] = useState<string | null>(null);

  useEffect(() => {
    const userFromStorage = getUserFromStorage();
    if (userFromStorage) {
      setUser(userFromStorage);
    }
    const appStateTokenSessionFromStorage = localStorage.getItem(appStateTokenSessionCookieName);
    setAppStateTokenSession(appStateTokenSessionFromStorage);
  }, []);

  function signout() {
    setUserInStorage(undefined);
    setUser(undefined);
  }

  const value = {
    user: user,
    setUser: (user: UserCredential) => {
      setUserInStorage(user);
      setUser(user);
    },
    appStateTokenSession: appStateTokenSession,
    setAppStateTokenSession: (sessionToken: string) => {
      setAppStateTokenSessionInStorage(sessionToken);
      setAppStateTokenSession(sessionToken);
    },
    signout
  } as AuthContextType;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
