import { GameStatus } from '../common/models/game-status';
import IconWithTooltip from './IconWithTooltip';

export const GameStatusIcon = (props: {
  status: GameStatus;
  showLabel?: boolean;
  showTooltip?: boolean;
  iconStyle?: any;
}) => {
  const { status, showLabel, showTooltip, iconStyle } = props;
  const activeShowLabel = showLabel ?? true;
  const activeShowTooltip = showTooltip ?? true;

  switch (status) {
    case GameStatus.UPCOMING:
      return (
        <IconWithTooltip
          icon="pi-calendar"
          title={activeShowLabel ? 'Upcoming' : undefined}
          tooltip={activeShowTooltip ? 'Game is upcoming' : undefined}
          iconStyle={iconStyle}
        />
      );
    case GameStatus.IN_PROGRESS:
      return (
        <IconWithTooltip
          icon="pi-spin pi-spinner"
          title={activeShowLabel ? 'In progress' : undefined}
          tooltip={activeShowTooltip ? 'Game is in progress' : undefined}
          iconStyle={iconStyle}
        />
      );
    case GameStatus.COMPLETE:
      return (
        <IconWithTooltip
          icon="pi-check-circle"
          title={activeShowLabel ? 'Complete' : undefined}
          tooltip={activeShowTooltip ? 'Game is in complete' : undefined}
          iconStyle={iconStyle}
        />
      );
    case GameStatus.UNAVAILABLE:
      return (
        <IconWithTooltip
          icon="pi-question-circle"
          title={activeShowLabel ? 'Unavailable' : undefined}
          tooltip={activeShowTooltip ? 'Game status is unavailable' : undefined}
          iconStyle={iconStyle}
        />
      );
    default:
      return (
        <IconWithTooltip
          icon="pi-question-circle"
          title={activeShowLabel ? 'Unavailable' : undefined}
          tooltip={activeShowTooltip ? 'Game status is unavailable' : undefined}
          iconStyle={iconStyle}
        />
      );
  }
};
