import { Timestamp } from 'firebase/firestore';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import useAuth from '../auth/auth';
import { Bet, ParlayBet } from '../common/models/bet';
import { BetStatus } from '../common/models/bet-status';
import { BetType } from '../common/models/bet-type';
import { Contest } from '../common/models/contest';
import { Game } from '../common/models/game';
import { Odds } from '../common/models/odds';
import { Parlay } from '../common/models/parlay';
import { ErrorBlock } from '../components/ErrorBlock';
import IconWithTooltip from '../components/IconWithTooltip';
import { LoadingBlock } from '../components/LoadingBlock';
import useGlobalContestStateManagerContext from '../hooks/contest-state-manager';
import useGlobalToasts from '../hooks/global-toasts';
import {
  calculateCombinedParlayBetOdds,
  calculateWinAmountForBet,
  describeBet,
  getUserBalanceForContest,
  saveBet
} from '../utils/bet-utils';
import { getGamesByIds } from '../utils/game-utils';
import { formatMoney } from '../utils/utils';
import { ContestParlayBetEditor } from './ContestParlayBetEditor';

export interface ContestParylayBuilderProps {
  contest: Contest;
  onParlaySaved: (parlay: Parlay) => void;
}

export const ContestParlayBuilder = (props: ContestParylayBuilderProps) => {
  const globalContestStateManager = useGlobalContestStateManagerContext();
  const auth = useAuth();
  const globalToasts = useGlobalToasts();

  const { contest, onParlaySaved } = props;

  const [contestError, setContestError] = useState<string | undefined>();
  const [loadingGames, setLoadingGames] = useState<boolean>(false);
  const [errorGames, setErrorGames] = useState<string | undefined>(undefined);
  const [loadingContestBalance, setLoadingContestBalance] = useState(true);
  const [contestBalance, setContestBalance] = useState<number | undefined>();
  const [errorContestBalance, setErrorContestBalance] = useState<string | undefined>();
  const [amountValid, setAmountValid] = useState<boolean>(false);
  const [confirmingBet, setConfirmingBet] = useState<boolean>(false);
  const [savingBet, setSavingBet] = useState<boolean>(false);

  const [amount, setAmount] = useState<number | undefined>(1);
  const [games, setGames] = useState<Game[]>([]);
  const [parlay, setParlay] = useState<Parlay>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [bets, setBets] = useState<ParlayBet[]>([]);
  const [combinedOdds, setCombinedOdds] = useState<Odds | undefined>(undefined);

  const updateParlay = (contestId: string) => {
    const storedParlay = globalContestStateManager.getParlayForContest(contestId);
    setParlay(
      storedParlay ||
        ({
          bets: [],
          bet: {
            contestId: contestId,
            amount: 0,
            timestamp: Timestamp.now(),
            betType: BetType.PARLAY,
            owner: auth.user?.user?.email,
            status: BetStatus.PENDING
          } as Bet
        } as Parlay)
    );
  };

  useEffect(() => {
    if (!contest?.id) {
      setContestError('Contest is not valid');
      setRefresh(false);
      return;
    }

    // Validate the amount of the bet
    setAmountValid(contestBalance && amount && amount <= contestBalance ? true : false);

    setCombinedOdds(calculateCombinedParlayBetOdds(bets));

    const parlayToUpdate =
      parlay ||
      ({
        bets: [],
        bet: {
          contestId: contest.id,
          amount: 0,
          timestamp: Timestamp.now(),
          betType: BetType.PARLAY,
          owner: auth.user?.user?.email,
          status: BetStatus.PENDING
        } as Bet
      } as Parlay);
    parlayToUpdate.bets = bets;
    parlayToUpdate.bet.amount = amount ? amount : 0;
    globalContestStateManager.setParlayForContest(contest.id, parlayToUpdate);
  }, [bets, amount]);

  useEffect(() => {
    if (refresh) {
      if (!contest?.id) {
        setContestError('Contest is not valid');
        setRefresh(false);
        return;
      }
      updateParlay(contest?.id);
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    setContestError(undefined);
    if (!contest?.id) {
      setContestError('Contest is not valid');
      return;
    }
    updateParlay(contest?.id);
  }, [contest?.id]);

  useEffect(() => {
    setLoadingContestBalance(true);
    getUserBalanceForContest(auth, contest)
      .then((balance) => {
        setContestBalance(balance);
        setLoadingContestBalance(false);
      })
      .catch((error) => {
        console.error('Unable to load balance.', error);
        setErrorContestBalance('Unable to load balance.');
      });

    if (parlay) {
      setBets(parlay.bets);
      setAmount(parlay.bet.amount);
    }

    if (parlay && parlay.bets?.length) {
      setLoadingGames(true);
      setErrorGames(undefined);
      getGamesByIds(parlay.bets.map((bet) => bet.gameId))
        .then((games) => {
          setGames(games.sort((a, b) => a.startTime.seconds - b.startTime.seconds));
          setLoadingGames(false);
        })
        .catch((error) => {
          console.error('Unable to load games for parlay bets', error);
          setErrorGames('Unable to load games for parlay bets');
          setLoadingGames(false);
        });
    } else {
      setLoadingGames(true);
      setGames([]);
      setLoadingGames(false);
    }
  }, [parlay]);

  const handleBetsUpdate = (updatedBets: ParlayBet[], currentBets: ParlayBet[], game: Game) => {
    const currentBetsNotForGame = bets.filter((bet) => bet.gameId !== game.id);
    const newBets = [...currentBetsNotForGame, ...(updatedBets ? updatedBets : [])];
    setBets(newBets);
  };

  const clearBet = () => {
    setSavingBet(true);
    if (contest?.id?.length) {
      globalContestStateManager.clearParlayForContest(contest.id);
    }
    setSavingBet(false);
  };

  const isNotOKToPlaceBet = (): boolean => {
    // const dev = isDev();
    const okToPlaceBet =
      // !dev ||
      !amountValid ||
      !combinedOdds?.valid ||
      !bets ||
      bets.filter((bet) => !isNaN(bet.price)).length < 2 ||
      games.some((game) => game.startTime <= Timestamp.now())
        ? false
        : true;
    return !okToPlaceBet;
  };

  const placeBet = () => {
    setSavingBet(true);
    // const dev = isDev();
    // if (!dev) {
    //   globalToasts.sendToast(
    //     'error',
    //     'Bet failed',
    //     'Your bet could not be placed, this feature is not yet available.'
    //   );
    //   return;
    // }

    saveBet(
      auth,
      {
        owner: auth.user?.user?.email,
        betType: BetType.PARLAY,
        contestId: contest.id,
        amount: amount,
        currency: contest.initialBalanceCurrency,
        timestamp: Timestamp.now(),
        line: combinedOdds?.american,
        price: combinedOdds?.american,
        status: BetStatus.PENDING
      } as Bet,
      bets
    )
      .then(() => {
        setSavingBet(false);
        setConfirmingBet(false);
        globalToasts.sendToast('success', 'Bet placed', 'Your bet was successfully placed!');
        if (parlay && onParlaySaved) {
          onParlaySaved(parlay);
        }
      })
      .catch((error) => {
        console.error('Unable to save bet', error);
        setSavingBet(false);
        setConfirmingBet(false);
        globalToasts.sendToast('error', 'Bet failed', 'Your bet could not be placed, try again.');
      });
  };

  if (contestError || errorGames) {
    return <ErrorBlock message="Unable to load contest/games"></ErrorBlock>;
  } else if (loadingGames) {
    return <LoadingBlock message="Loading games..."></LoadingBlock>;
  } else if (!parlay) {
    return <ErrorBlock message="Unable to load parlay"></ErrorBlock>;
  } else {
    return (
      <>
        {!confirmingBet && !savingBet && (
          <>
            {games &&
              games.length > 0 &&
              games.map((game) => {
                const betsForGame = parlay?.bets?.filter((bet) => bet.gameId === game.id);
                return (
                  <div
                    key={game.id}
                    style={{
                      padding: '.5em',
                      marginBottom: '.5em',
                      marginTop: '.5em',
                      border: 'solid silver 1px',
                      borderRadius: '4px'
                    }}
                  >
                    <ContestParlayBetEditor
                      contest={contest}
                      bets={betsForGame}
                      game={game}
                      collapsed={true}
                      onRemoveGameFromParlay={(gameId) => {
                        if (!contest?.id) {
                          console.error('Unable to remove game from parlay, contest is not valid');
                        } else {
                          globalContestStateManager.removeGameFromParlayForContest(
                            contest?.id,
                            gameId
                          );
                          setRefresh(true);
                        }
                      }}
                      onBetsUpdated={(updatedBets) => handleBetsUpdate(updatedBets, bets, game)}
                    ></ContestParlayBetEditor>
                  </div>
                );
              })}
            <div style={{ marginTop: '1em', fontSize: 'small' }}>
              {(!bets || bets.filter((bet) => !isNaN(bet.price)).length < 2) && (
                <ErrorBlock message="You must select at least one game and at least two bets to build a parlay" />
              )}
              {bets &&
                bets.filter((bet) => !isNaN(bet.price)).length >= 2 &&
                !combinedOdds?.valid && (
                  <ErrorBlock message="Sorry, we couldn't combine those odds, something went wrong" />
                )}
              {bets &&
                bets.filter((bet) => !isNaN(bet.price)).length >= 2 &&
                combinedOdds?.valid && (
                  <>
                    {loadingContestBalance && (
                      <div style={{ width: '100%', textAlign: 'center' }}>
                        <i
                          className="pi pi-spin pi-spinner"
                          style={{ fontSize: '1em' }}
                          title="Checking your contest balance..."
                        ></i>
                      </div>
                    )}
                    {errorContestBalance && (
                      <div style={{ width: '100%', textAlign: 'center' }}>
                        <i
                          className="pi pi-exclamation-circle"
                          style={{ fontSize: '1em', color: '#ef9a9a' }}
                        ></i>
                        <span style={{ marginLeft: '.25em', color: '#ef9a9a' }}>
                          Unable to check current contest balance.
                        </span>
                      </div>
                    )}
                    {!loadingContestBalance &&
                      !errorContestBalance &&
                      (contestBalance === undefined ||
                        contestBalance == null ||
                        contestBalance === 0) && (
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          <i
                            className="pi pi-exclamation-circle"
                            style={{ fontSize: '1em', color: '#ef9a9a' }}
                          ></i>
                          <span style={{ marginLeft: '.25em', color: '#ef9a9a' }}>
                            Sorry, you don't have a sufficient balance to place a bet.
                          </span>
                        </div>
                      )}
                    {!loadingContestBalance &&
                      !errorContestBalance &&
                      contestBalance !== 0 &&
                      contestBalance !== undefined &&
                      contestBalance != null && (
                        <>
                          <div className="grid align-items-center">
                            <div className="col">Contest Balance Available</div>
                            <div className="fw-bold col text-right">
                              {formatMoney(contestBalance, contest.initialBalanceCurrency)}
                            </div>
                          </div>

                          <div className="grid align-items-center">
                            <div className="col">Parlay Odds</div>
                            <div className="fw-bold col text-right">
                              {combinedOdds?.valid && combinedOdds.american >= 0 ? '+' : ''}
                              {Math.round(combinedOdds.american)}
                            </div>
                          </div>

                          <form className="flex flex-column gap-2">
                            <div>
                              <label htmlFor="amount" className="fw-normal">
                                Amount
                              </label>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginTop: '.4em'
                                }}
                              >
                                <div className="p-inputgroup">
                                  <span className="p-inputgroup-addon">$</span>
                                  <InputNumber
                                    id="amount"
                                    name="amount"
                                    placeholder={'1 - ' + contestBalance.toFixed(0)}
                                    value={amount}
                                    onChange={(e) => setAmount(e.value ? e.value : undefined)}
                                    className={classNames({
                                      'p-invalid':
                                        amount === undefined ||
                                        isNaN(amount) ||
                                        amount < 1 ||
                                        amount > contestBalance
                                    })}
                                    style={{ width: '100%' }}
                                  />
                                  <span className="p-inputgroup-addon">.00</span>
                                </div>
                                {amount === undefined ||
                                  isNaN(amount) ||
                                  amount < 1 ||
                                  (amount > contestBalance && (
                                    <>
                                      <IconWithTooltip
                                        tooltip="Bet amount cannot exceed your current contest balance"
                                        icon={'pi-exclamation-circle'}
                                        iconStyle={{
                                          color: '#ef9a9a',
                                          fontSize: 'normal',
                                          marginLeft: '.4em'
                                        }}
                                      />
                                    </>
                                  ))}
                              </div>
                            </div>
                          </form>

                          <Divider className="mt-3" />

                          <div className="grid align-items-center">
                            <div className="col">Payout:</div>
                            <div className="fw-bold col text-right">
                              {formatMoney(
                                calculateWinAmountForBet({
                                  amount: amount,
                                  currency: contest.initialBalanceCurrency,
                                  betType: BetType.PARLAY,
                                  price: combinedOdds.american
                                } as Bet),
                                contest.initialBalanceCurrency
                              )}
                            </div>
                          </div>
                        </>
                      )}
                  </>
                )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '.5em'
              }}
            >
              <div>
                <Button
                  label="Place Bet"
                  disabled={
                    confirmingBet ||
                    savingBet ||
                    !amountValid ||
                    !combinedOdds?.valid ||
                    !bets ||
                    bets.filter((bet) => !isNaN(bet.price)).length < 2
                  }
                  onClick={() => setConfirmingBet(true)}
                ></Button>
              </div>
              <div style={{ marginLeft: '.5em' }}>
                <Button
                  label="Clear Bet"
                  disabled={confirmingBet || savingBet}
                  onClick={() => clearBet()}
                ></Button>
              </div>
            </div>
          </>
        )}
        {confirmingBet && (
          <>
            <div>
              Please confirm your bet below, once a bet is placed it{' '}
              <span className="fw-bold">cannot be changed or cancelled</span>.
            </div>
            <div className="mt-2 fw-bold fs-italics">
              {describeBet({
                betType: BetType.PARLAY,
                owner: auth.user?.user?.email?.toLowerCase(),
                timestamp: Timestamp.now(),
                amount: amount,
                contestId: contest.id,
                price: combinedOdds?.american,
                status: BetStatus.PENDING
              } as Bet)}
            </div>
            <div className="flex align-items-center justify-content-center mt-2">
              <Button
                className="mt-2 mr-1"
                color="primary"
                type="button"
                style={{ justifyContent: 'center' }}
                loading={savingBet}
                disabled={isNotOKToPlaceBet()}
                onClick={placeBet}
              >
                Place Bet
              </Button>

              <Button
                className="mt-2 ml-1"
                color="primary"
                type="button"
                style={{ justifyContent: 'center' }}
                onClick={() => setConfirmingBet(false)}
              >
                Cancel Bet
              </Button>
            </div>
          </>
        )}
      </>
    );
  }
};
