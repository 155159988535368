import { Timestamp } from 'firebase/firestore';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { Contest } from '../common/models/contest';
import {
  Game,
  isAwayTeamMoneylineValid,
  isAwayTeamScoreValid,
  isAwayTeamSpreadValid,
  isHomeTeamMoneylineValid,
  isHomeTeamScoreValid,
  isHomeTeamSpreadValid,
  isOverUnderLineValid
} from '../common/models/game';
import { Sport } from '../common/models/sport';
import {
  Team,
  getAwayRecord,
  getClubhouseLink,
  getConferenceRecord,
  getHomeRecord,
  getRosterLink,
  getScheduleLink,
  getStatsLink
} from '../common/models/team';
import useTeamDataManager from '../hooks/team-data-manager';
import { getCurrentGameStatus } from '../utils/game-utils';
import { formatReallyShortTimestamp } from '../utils/utils';
import GameDetails from './GameDetails';
import { GameStatusIcon } from './GameStatusIcon';
import { TeamAvatar } from './TeamAvatar';
import { LineMovementIndicator } from './LineMovementIndicator';
import { BetType } from '../common/models/bet-type';

export interface GameSummaryProps {
  game: Game;
  contest?: Contest;
  onBetPlaced?: () => void;
  disableGameDetails?: boolean;
  teamDetails?: boolean | undefined;
  teamRankings?: boolean | undefined;
  teamRecords?: boolean | undefined;
}

const GameSummary = (props: GameSummaryProps) => {
  const { game, contest, onBetPlaced, disableGameDetails, teamDetails, teamRankings, teamRecords } =
    props;
  const showTeamDetails = teamDetails === false ? false : true;
  const showTeamRankings = teamRankings === false ? false : true;
  const showTeamRecords = teamRecords === false ? false : false;
  const teamDataManager = useTeamDataManager();
  const [awayTeam, setAwayTeam] = useState<Team | undefined>();
  const [homeTeam, setHomeTeam] = useState<Team | undefined>();

  useEffect(() => {
    const awayTeam = teamDataManager.getTeam(game.awayTeam, game.sport);
    const homeTeam = teamDataManager.getTeam(game.homeTeam, game.sport);
    setAwayTeam(awayTeam);
    setHomeTeam(homeTeam);
  }, [game]);

  const [showGameDetails, setShowGameDetails] = useState(false);
  return (
    <>
      <div
        className="grid flex-row-center p-2"
        style={{
          fontSize: '.75em',
          cursor: !disableGameDetails ? 'pointer' : 'auto',
          opacity: contest && game.startTime <= Timestamp.now() ? 0.75 : 1
        }}
        onClick={() => {
          if (!disableGameDetails) {
            setShowGameDetails(true);
          }
        }}
      >
        <div className="col-4 flex flex-row">
          <GameStatusIcon showLabel={false} status={getCurrentGameStatus(game)} />
          <div className="ml-1">{formatReallyShortTimestamp(game.startTime)}</div>
        </div>
        <div className="col-4 fw-bold text-center">
          {awayTeam ? <TeamAvatar team={awayTeam} includeRanking={true} /> : game.awayTeam}
        </div>
        <div className="col-4 fw-bold text-center">
          {homeTeam ? <TeamAvatar team={homeTeam} includeRanking={true} /> : game.homeTeam}
        </div>

        {/* <div className="col-4">RECORD</div>
        <div className="col-4 text-center">{awayTeam ? getOverallRecord(awayTeam) : '?-?'}</div>
        <div className="col-4 text-center">{homeTeam ? getOverallRecord(homeTeam) : '?-?'}</div> */}

        <div className="col-4">MONEYLINE</div>
        <div
          className="col-4 text-center"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <div>
            {isAwayTeamMoneylineValid(game)
              ? (game.awayTeamMoneyline >= 0 ? '+' : '') + game.awayTeamMoneyline
              : ''}
          </div>
          <div style={{ marginLeft: '.25em' }}>
            <LineMovementIndicator
              game={game}
              betType={BetType.AWAY_TEAM_MONEYLINE}
            ></LineMovementIndicator>
          </div>
        </div>
        <div
          className="col-4 text-center"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <div>
            {isHomeTeamMoneylineValid(game)
              ? (game.homeTeamMoneyline >= 0 ? '+' : '') + game.homeTeamMoneyline
              : ''}
          </div>
          <div style={{ marginLeft: '.25em' }}>
            <LineMovementIndicator
              game={game}
              betType={BetType.HOME_TEAM_MONEYLINE}
            ></LineMovementIndicator>
          </div>
        </div>

        <div className="col-4">SPREAD</div>
        <div
          className="col-4 text-center"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <div>
            {isAwayTeamSpreadValid(game)
              ? (game.awayTeamSpread >= 0 ? '+' : '') +
                game.awayTeamSpread.toFixed(1) +
                ' (' +
                (game.awayTeamSpreadPrice >= 0 ? '+' : '') +
                game.awayTeamSpreadPrice +
                ')'
              : ''}
          </div>
          <div style={{ marginLeft: '.25em' }}>
            <LineMovementIndicator
              game={game}
              betType={BetType.AWAY_TEAM_SPREAD}
            ></LineMovementIndicator>
          </div>
        </div>
        <div
          className="col-4 text-center"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <div>
            {isHomeTeamSpreadValid(game)
              ? (game.homeTeamSpread >= 0 ? '+' : '') +
                game.homeTeamSpread.toFixed(1) +
                ' (' +
                (game.homeTeamSpreadPrice >= 0 ? '+' : '') +
                game.homeTeamSpreadPrice +
                ')'
              : ''}
          </div>
          <div style={{ marginLeft: '.25em' }}>
            <LineMovementIndicator
              game={game}
              betType={BetType.HOME_TEAM_SPREAD}
            ></LineMovementIndicator>
          </div>
        </div>

        <div className="col-4">OVER/UNDER</div>
        <div
          className="col-4 text-center"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <div>
            {isOverUnderLineValid(game)
              ? 'O ' +
                game.overUnderLine.toFixed(1) +
                ' (' +
                (game.overPrice >= 0 ? '+' : '') +
                game.overPrice +
                ')'
              : ''}
          </div>
          <div style={{ marginLeft: '.25em' }}>
            <LineMovementIndicator game={game} betType={BetType.OVER}></LineMovementIndicator>
          </div>
        </div>
        <div
          className="col-4 text-center"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <div>
            {' '}
            {isOverUnderLineValid(game)
              ? 'U ' +
                game.overUnderLine.toFixed(1) +
                ' (' +
                (game.underPrice >= 0 ? '+' : '') +
                game.underPrice +
                ')'
              : ''}
          </div>
          <div style={{ marginLeft: '.25em' }}>
            <LineMovementIndicator game={game} betType={BetType.UNDER}></LineMovementIndicator>
          </div>
        </div>

        {(isAwayTeamScoreValid(game) || isHomeTeamScoreValid(game)) && (
          <>
            <div className="col-4">SCORE</div>
            <div className="col-4 text-center">
              {isAwayTeamScoreValid(game) ? game.awayTeamScore : ''}
            </div>
            <div className="col-4 text-center">
              {isHomeTeamScoreValid(game) ? game.homeTeamScore : ''}
            </div>

            <div className="col-4">SCORE DIFF</div>
            <div className="col-4 text-center">
              {isAwayTeamScoreValid(game) && isHomeTeamScoreValid(game)
                ? game.awayTeamScore - game.homeTeamScore
                : ''}
            </div>
            <div className="col-4 text-center">
              {isAwayTeamScoreValid(game) && isHomeTeamScoreValid(game)
                ? game.homeTeamScore - game.awayTeamScore
                : ''}
            </div>

            <div className="col-4">TOTAL SCORE</div>
            <div className="col-8 text-center">
              {(isAwayTeamScoreValid(game) ? game.awayTeamScore : 0) +
                (isHomeTeamScoreValid(game) ? game.homeTeamScore : 0)}
            </div>
          </>
        )}

        {showTeamRankings &&
          (awayTeam?.sport === Sport.NCAAF || homeTeam?.sport === Sport.NCAAF) && (
            <>
              <div className="col-4">RANKING</div>
              <div className="col-4 text-center">
                {awayTeam?.sport === Sport.NCAAF && (
                  <>
                    <span>{awayTeam.rankingLatest ? '#' + awayTeam.rankingLatest : '-'}</span>
                    <span>
                      {awayTeam.rankingFirstPlaceVotes
                        ? '(' + awayTeam.rankingFirstPlaceVotes + ')'
                        : ''}
                    </span>
                  </>
                )}
              </div>
              <div className="col-4 text-center">
                {homeTeam?.sport === Sport.NCAAF && (
                  <>
                    <span>{homeTeam.rankingLatest ? '#' + homeTeam.rankingLatest : '-'}</span>
                    <span>
                      {homeTeam.rankingFirstPlaceVotes
                        ? '(' + homeTeam.rankingFirstPlaceVotes + ')'
                        : ''}
                    </span>
                  </>
                )}
              </div>
            </>
          )}

        {showTeamRecords && (
          <>
            <div className="col-4">HOME RECORD</div>
            <div className="col-4 text-center">{awayTeam ? getHomeRecord(awayTeam) : '?-?'}</div>
            <div className="col-4 text-center">{homeTeam ? getHomeRecord(homeTeam) : '?-?'}</div>

            <div className="col-4">AWAY RECORD</div>
            <div className="col-4 text-center">{awayTeam ? getAwayRecord(awayTeam) : '?-?'}</div>
            <div className="col-4 text-center">{homeTeam ? getAwayRecord(homeTeam) : '?-?'}</div>

            {(awayTeam?.conferenceRecordWins !== undefined ||
              homeTeam?.conferenceRecordWins !== undefined) && (
              <>
                <div className="col-4">CONF RECORD</div>
                <div className="col-4 text-center">
                  {awayTeam ? getConferenceRecord(awayTeam) : '?-?'}
                </div>
                <div className="col-4 text-center">
                  {homeTeam ? getConferenceRecord(homeTeam) : '?-?'}
                </div>
              </>
            )}
          </>
        )}

        {showTeamDetails && (getClubhouseLink(awayTeam) || getClubhouseLink(homeTeam)) && (
          <>
            <div className="col-4"></div>
            <div className="col-4 text-center">
              {getClubhouseLink(awayTeam) ? (
                <a target="_blank" href={getClubhouseLink(awayTeam)} title="Team news via ESPN">
                  News
                </a>
              ) : (
                ''
              )}
            </div>
            <div className="col-4 text-center">
              {getClubhouseLink(homeTeam) ? (
                <a target="_blank" href={getClubhouseLink(homeTeam)} title="Team news via ESPN">
                  News
                </a>
              ) : (
                ''
              )}
            </div>
          </>
        )}

        {showTeamDetails && (getStatsLink(awayTeam) || getStatsLink(homeTeam)) && (
          <>
            <div className="col-4"></div>
            <div className="col-4 text-center">
              {getStatsLink(awayTeam) ? (
                <a target="_blank" href={getStatsLink(awayTeam)} title="Team stats via ESPN">
                  Stats
                </a>
              ) : (
                ''
              )}
            </div>
            <div className="col-4 text-center">
              {getStatsLink(homeTeam) ? (
                <a target="_blank" href={getStatsLink(homeTeam)} title="Team stats via ESPN">
                  Stats
                </a>
              ) : (
                ''
              )}
            </div>
          </>
        )}

        {showTeamDetails && (getScheduleLink(awayTeam) || getScheduleLink(homeTeam)) && (
          <>
            <div className="col-4"></div>
            <div className="col-4 text-center">
              {getScheduleLink(awayTeam) ? (
                <a target="_blank" href={getScheduleLink(awayTeam)} title="Team schedule via ESPN">
                  Schedule
                </a>
              ) : (
                ''
              )}
            </div>
            <div className="col-4 text-center">
              {getScheduleLink(homeTeam) ? (
                <a target="_blank" href={getScheduleLink(homeTeam)} title="Team schedule via ESPN">
                  Schedule
                </a>
              ) : (
                ''
              )}
            </div>
          </>
        )}

        {showTeamDetails && (getRosterLink(awayTeam) || getRosterLink(homeTeam)) && (
          <>
            <div className="col-4"></div>
            <div className="col-4 text-center">
              {getRosterLink(awayTeam) ? (
                <a target="_blank" href={getRosterLink(awayTeam)} title="Team roster via ESPN">
                  Roster
                </a>
              ) : (
                ''
              )}
            </div>
            <div className="col-4 text-center">
              {getRosterLink(homeTeam) ? (
                <a target="_blank" href={getRosterLink(homeTeam)} title="Team roster via ESPN">
                  Roster
                </a>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </div>
      <Dialog
        header={game.awayTeam + ' @ ' + game.homeTeam}
        visible={showGameDetails}
        style={{ width: '95vw' }}
        onHide={() => setShowGameDetails(false)}
      >
        <GameDetails
          game={game}
          contest={contest}
          onBetPlaced={() => {
            if (onBetPlaced) {
              onBetPlaced();
            }
          }}
        ></GameDetails>
      </Dialog>
    </>
  );
};

export default GameSummary;
