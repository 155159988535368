import { appVersion } from '../utils/app-utils';

export const AppFooter = () => {
  return (
    <div
      style={{
        fontSize: 'x-small',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1em'
      }}
    >
      <div style={{ textAlign: 'left' }}>© 2023 OneNine Technology - All Rights Reserved.</div>
      <div style={{ textAlign: 'right' }}>v{appVersion.toFixed(1)}</div>
    </div>
  );
};
