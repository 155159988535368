import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Chips } from 'primereact/chips';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { array, boolean, date, number, object, ref, string } from 'yup';
import useAuth from '../auth/auth';
import { Contest } from '../common/models/contest';
import { ContestSetup } from '../common/models/contest-setup';
import { PoolSettings } from '../common/models/pool-settings';
import { buildSportOptions, Sport, SportName } from '../common/models/sport';
import { Team } from '../common/models/team';
import { ErrorBlock } from '../components/ErrorBlock';
import IconButtonWithTooltip from '../components/IconButtonWithTooltip';
import IconWithTooltip from '../components/IconWithTooltip';
import { LoadingBlock } from '../components/LoadingBlock';
import useGlobalToasts from '../hooks/global-toasts';
import {
  createContest,
  getAllActiveUpcomingContestSetups,
  getPoolPayoutByStandings,
  getSuggestedContestMembers
} from '../utils/contest-utils';
import { getAllTeams } from '../utils/team-utils';
import { formatMoney, getOrdinal } from '../utils/utils';
import './NewContest.css';

function NewContest() {
  const navigate = useNavigate();
  const auth = useAuth();
  const { user } = auth;
  const globalToasts = useGlobalToasts();

  const [savingContest, setSavingContest] = useState(false);

  const [contestSetups, setContestSetups] = useState<ContestSetup[]>([]);
  const [loadingContestSetups, setLoadingContestSetups] = useState(false);
  const [errorContestSetups, setErrorContestSetups] = useState<string | undefined>(undefined);

  const [teams, setTeams] = useState<Team[]>([]);
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [errorTeams, setErrorTeams] = useState<string | undefined>(undefined);

  const [suggestedContestMembers, setSuggestedContestMembers] = useState<string[]>([]);
  const [suggestedContestMembersLoading, setSuggestedContestMembersLoading] = useState(false);
  const [suggestedContestMembersError, setSuggestedContestMembersError] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    setLoadingContestSetups(true);
    setErrorContestSetups(undefined);
    getAllActiveUpcomingContestSetups()
      .then((contestSetups) => {
        setContestSetups(contestSetups);
        setLoadingContestSetups(false);
      })
      .catch((error) => {
        console.error('Unable to load contest setups: ', error);
        setErrorContestSetups('Unable to load contest setups');
        setLoadingContestSetups(false);
      });

    setLoadingTeams(true);
    setErrorTeams(undefined);
    getAllTeams()
      .then((teams) => {
        setTeams(teams);
        setLoadingTeams(false);
      })
      .catch((error) => {
        console.error('Unable to load teams: ', error);
        setErrorTeams('Unable to load teams');
        setLoadingTeams(false);
      });

    setSuggestedContestMembersLoading(true);
    setSuggestedContestMembersError(undefined);
    getSuggestedContestMembers(auth)
      .then((members) => {
        setSuggestedContestMembers(members.filter((member) => member !== auth?.user?.user?.email));
        setSuggestedContestMembersLoading(false);
      })
      .catch((error) => {
        console.error('Unable to load suggested contest members: ', error);
        setSuggestedContestMembersError('Unable to load suggested contest members');
        setSuggestedContestMembersLoading(false);
      });
  }, []);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  currentDate.setMinutes(0);
  const validationSchema = object({
    name: string().min(1, 'A name is required').required('A name is required'),
    contestType: string().required('A contest type is required'),
    sports: array().when('contestType', (contestType: string[], schema: any) => {
      const isCustom = contestType?.length ? contestType[0] === 'custom' : false;
      if (isCustom) {
        return schema
          .min(1, 'At least one sport must be selected')
          .required('Selecting a sport is required');
      }
      return schema;
    }),
    startDate: date().when('contestType', (contestType: string[], schema: any) => {
      const isCustom = contestType?.length ? contestType[0] === 'custom' : false;
      if (isCustom) {
        return schema
          .min(currentDate, 'Start date must be today or later')
          .required('Start date is required');
      }
      return schema;
    }),
    endDate: date().when('contestType', (contestType: string[], schema: any) => {
      const isCustom = contestType?.length ? contestType[0] === 'custom' : false;
      if (isCustom) {
        return schema.min(ref('startDate'), 'End date cannot be before start date');
      }
      return schema;
    }),
    invitedMembers: array()
      .min(2, 'At least two members must be invited')
      .required('Inviting members is required')
      .of(string().email(({ value }) => `${value} is not a valid email`))
      .test('unique', 'All emails must be unique.', (value) =>
        value ? value.length === new Set(value)?.size : true
      ),
    startingBalance: number()
      .required('Starting balance is required')
      .positive('Starting balance must be greater than 0')
      .max(1000000, 'Starting balance must be less than 1,000,000'),
    poolSettingsActive: boolean().notRequired(),
    poolSettingsBuyIn: number().when(
      'poolSettingsActive',
      (poolSettingsActive: boolean[], schema: any) => {
        const isActive = poolSettingsActive?.length ? poolSettingsActive[0] : false;
        if (isActive) {
          return schema
            .required('Buy-in is required')
            .min(1, 'Buy-in must be greater than 0')
            .max(1000000, 'Buy-in must be less than 1,000,000');
        }
        return schema;
      }
    ),
    poolSettingsNumPayouts: number().when(
      'poolSettingsActive',
      (poolSettingsActive: boolean[], schema: any) => {
        const isActive = poolSettingsActive?.length ? poolSettingsActive[0] : false;
        if (isActive) {
          return schema
            .required('At least one payout is required')
            .min(1, 'At least one payout is required')
            .when('invitedMembers', (invitedMembers: string[], schema: any) => {
              const numMembers = invitedMembers?.length
                ? invitedMembers[0]
                  ? invitedMembers[0].length
                  : 0
                : 0;
              return schema.max(
                numMembers,
                'Number of payments cannot be greater than number of members'
              );
            });
        }
        return schema;
      }
    ),
    poolSettingsPayoutByStandingsPosition: object().when(
      ['poolSettingsActive', 'poolSettingsNumPayouts', 'invitedMembers', 'poolSettingsBuyIn'],
      ([active, numPayouts, members, buyIn], schema: any) => {
        if (active) {
          return schema
            .test(
              'required',
              'An option must be selected for each payout',
              (value: {
                [key: number]: number | 'REMAINING' | 'BUYIN' | 'HALFBUYIN' | 'DOUBLEBUYIN';
              }) => {
                return Object.keys(value).length >= numPayouts;
              }
            )
            .test(
              'only-one-remaining',
              'Only one paymout can be set to "The rest of the pool".',
              (value: {
                [key: number]: number | 'REMAINING' | 'BUYIN' | 'HALFBUYIN' | 'DOUBLEBUYIN';
              }) => {
                let remainingCount = 0;
                for (let i = 0; i < numPayouts; i++) {
                  if (value[i] === 'REMAINING') {
                    remainingCount++;
                  }
                }
                return remainingCount <= 1;
              }
            )
            .test(
              'all-pool-value-allocated',
              'The total payout allocations must equal the total pool value.',
              (value: {
                [key: number]: number | 'REMAINING' | 'BUYIN' | 'HALFBUYIN' | 'DOUBLEBUYIN';
              }) => {
                const totalPoolValue = buyIn * (members?.length ? members.length : 0);
                if (totalPoolValue === 0) {
                  return true;
                }

                const activeValue: {
                  [key: number]: number | 'REMAINING' | 'BUYIN' | 'HALFBUYIN' | 'DOUBLEBUYIN';
                } = {};
                for (let i = 0; i < numPayouts; i++) {
                  activeValue[i] = value[i];
                }

                const payoutsByStandings = getPoolPayoutByStandings({
                  invitedMembers: members,
                  activeMembers: members,
                  poolSettings: {
                    buyIn: buyIn,
                    currency: 'USD',
                    payoutByStandingsPosition: activeValue
                  }
                } as unknown as Contest);
                const totalPayouts = Object.values(payoutsByStandings)
                  .filter((payout) => payout)
                  .reduce((acc, cur) => acc + cur, 0);
                return totalPayouts === totalPoolValue;
              }
            )
            .test(
              'remaining-has-payout',
              'Payouts are allocated such that "The rest of the pool" will not be paid out',
              (value: {
                [key: number]: number | 'REMAINING' | 'BUYIN' | 'HALFBUYIN' | 'DOUBLEBUYIN';
              }) => {
                const totalPoolValue = buyIn * (members?.length ? members.length : 0);
                if (totalPoolValue === 0) {
                  return true;
                }

                const activeValue: {
                  [key: number]: number | 'REMAINING' | 'BUYIN' | 'HALFBUYIN' | 'DOUBLEBUYIN';
                } = {};
                for (let i = 0; i < numPayouts; i++) {
                  activeValue[i] = value[i];
                }

                const payoutsByStandings = getPoolPayoutByStandings({
                  invitedMembers: members,
                  activeMembers: members,
                  poolSettings: {
                    buyIn: buyIn,
                    currency: 'USD',
                    payoutByStandingsPosition: activeValue
                  }
                } as unknown as Contest);

                const remainingPositions = Object.entries(activeValue).filter(
                  ([, value]) => value === 'REMAINING'
                );
                if (remainingPositions.length === 0) {
                  return true;
                }
                const remainingPosition = +remainingPositions[0][0];
                return payoutsByStandings[remainingPosition] > 0;
              }
            );
        }
      }
    )
  });

  const sportOptions = buildSportOptions(Object.values(Sport).filter((s) => s !== Sport.ALL));

  const getTeamOptions = (sport: Sport) => {
    return teams
      .filter((team) => team.sport === sport)
      .map((team) => ({ name: team.name, value: team.name }));
  };

  const getContestSetupOptions = () => {
    const options = contestSetups.map((contestSetup) => ({
      name: contestSetup.name,
      value: contestSetup.id
    }));
    options.push({ name: 'Custom', value: 'custom' });
    return options;
  };

  const getPayoutOptions = () => {
    const options = [];
    options.push({ name: 'The rest of the pool', value: 'REMAINING' });
    options.push({ name: '2x buy-in', value: 'DOUBLEBUYIN' });
    options.push({ name: '1x buy-in', value: 'BUYIN' });
    options.push({ name: '1/2x buy-in', value: 'HALFBUYIN' });
    // options.push({ name: 'Custom Amount', value: 'CUSTOM' });
    return options;
  };

  const initialSelectTeams: { [key: string]: boolean } = {};
  const initialTeams: { [key: string]: string[] } = {};
  Object.values(Sport)
    .filter((sport) => sport != Sport.ALL)
    .forEach((sport) => {
      initialSelectTeams[sport] = false;
      initialTeams[sport] = [];
    });

  const formik = useFormik({
    initialValues: {
      name: '',
      contestType: '',
      sports: [],
      invitedMembers: [user?.user.email?.toLowerCase()],
      startDate: new Date(),
      endDate: new Date(),
      startingBalance: 5000,
      selectTeams: initialSelectTeams,
      teams: initialTeams,
      poolSettingsActive: false,
      poolSettingsCurrency: 'USD',
      poolSettingsBuyIn: 1,
      poolSettingsNumPayouts: [user?.user.email?.toLowerCase()].length,
      poolSettingsPayoutByStandingsPosition: {
        0: 'REMAINING'
      } as {
        [key: number]: number | 'REMAINING' | 'BUYIN' | 'HALFBUYIN' | 'DOUBLEBUYIN';
      },
      poolSettingsCustomPayoutByStandingsPosition: {
        0: null
      } as {
        [key: number]: number | null | undefined;
      }
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSavingContest(true);
      const contestSetup =
        values.contestType === 'custom'
          ? undefined
          : contestSetups.find((cs) => cs.id === values.contestType);

      let poolSettings: PoolSettings | undefined;
      if (values.poolSettingsActive) {
        poolSettings = {
          buyIn: values.poolSettingsBuyIn,
          currency: values.poolSettingsCurrency,
          payoutByStandingsPosition: getActivePoolSettingsPayoutByStandingsPosition()
        };
      }

      createContest(
        auth,
        values.name,
        values.invitedMembers ? (values.invitedMembers as string[]) : [],
        values.startingBalance,
        contestSetup,
        values.sports,
        values.teams,
        values.startDate as Date,
        values.endDate as Date,
        poolSettings
      )
        .then((contest) => {
          setSavingContest(false);
          navigate(`/contests/${contest.id}`);
        })
        .catch((error) => {
          setSavingContest(false);
          console.error('Unable to save contest: ', error);
          globalToasts.sendToast('error', 'Error', 'Unable to create contest.');
        });
    }
  });

  const getErrorMessage = (field: string) => {
    const errors = (formik.errors as unknown as any)[field];
    if (errors instanceof Array) {
      return errors.filter((error) => error?.length).join('\n');
    }
    return errors;
  };

  const getActivePoolSettingsPayoutByStandingsPosition = (): {
    [key: number]: number | 'REMAINING' | 'BUYIN' | 'HALFBUYIN' | 'DOUBLEBUYIN';
  } => {
    const activeValue: {
      [key: number]: number | 'REMAINING' | 'BUYIN' | 'HALFBUYIN' | 'DOUBLEBUYIN';
    } = {};
    for (let i = 0; i < formik.values.poolSettingsNumPayouts; i++) {
      activeValue[i] = formik.values.poolSettingsPayoutByStandingsPosition[i];
    }
    return activeValue;
  };

  const getTotalActivePoolSettingsPayoutsAllocated = (): number => {
    const activeValue = getActivePoolSettingsPayoutByStandingsPosition();
    const payoutsByStandings = getPoolPayoutByStandings({
      invitedMembers: formik.values.invitedMembers,
      activeMembers: formik.values.invitedMembers,
      poolSettings: {
        buyIn: formik.values.poolSettingsBuyIn,
        currency: formik.values.poolSettingsCurrency,
        payoutByStandingsPosition: activeValue
      }
    } as unknown as Contest);
    return Object.values(payoutsByStandings)
      .filter((payout) => payout !== undefined)
      .reduce((acc, cur) => acc + cur, 0);
  };

  return (
    <>
      {(loadingTeams || loadingContestSetups) && <LoadingBlock message="Please wait" />}
      {(errorTeams || errorContestSetups) && (
        <ErrorBlock message="Unable to create contest, please refresh the page" />
      )}
      {!loadingTeams && !loadingContestSetups && !errorTeams && !errorContestSetups && (
        <div>
          <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
            <div>
              <label htmlFor="name">Name</label>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                <InputText
                  id="name"
                  name="name"
                  placeholder="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  className={classNames({
                    'p-invalid': formik.dirty && formik.errors.name
                  })}
                  style={{ width: '100%' }}
                />
                {formik.dirty && formik.errors.name && (
                  <>
                    <Tooltip target=".invalid-name-tooltip" />
                    <span
                      className="pi pi-exclamation-circle invalid-name-tooltip"
                      style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                      data-pr-tooltip={getErrorMessage('name')}
                      data-pr-position="left"
                    ></span>
                  </>
                )}
              </div>
            </div>

            <div>
              <label htmlFor="contestType">Contest Type</label>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                <Dropdown
                  value={formik.values.contestType}
                  onChange={(e) => formik.setFieldValue('contestType', e.value)}
                  options={getContestSetupOptions()}
                  optionLabel="name"
                  placeholder="Select or create your own"
                  className={classNames({
                    'w-full': true,
                    'p-invalid': formik.dirty && Boolean(formik.errors.contestType)
                  })}
                />
                {formik.dirty && formik.errors.contestType && (
                  <>
                    <Tooltip target=".invalid-contest-type-tooltip" />
                    <span
                      className="pi pi-exclamation-circle invalid-contest-type-tooltip"
                      style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                      data-pr-tooltip={getErrorMessage('contestType')}
                      data-pr-position="left"
                    ></span>
                  </>
                )}
              </div>
            </div>

            {formik.values.contestType === 'custom' && (
              <div>
                <label htmlFor="sports">Select Sports</label>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                  <MultiSelect
                    id="sports"
                    name="sports"
                    value={formik.values.sports}
                    onChange={(e) => {
                      formik.setFieldValue('sports', e.value);
                    }}
                    options={sportOptions}
                    optionLabel="name"
                    optionValue="value"
                    placeholder="Select Sports"
                    className={classNames({
                      'p-invalid': formik.dirty && Boolean(formik.errors.sports)
                    })}
                    style={{ width: '100%' }}
                  />
                  {formik.dirty && formik.errors.sports && (
                    <>
                      <Tooltip target=".invalid-sports-tooltip" />
                      <span
                        className="pi pi-exclamation-circle invalid-sports-tooltip"
                        style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                        data-pr-tooltip={getErrorMessage('sports')}
                        data-pr-position="left"
                      ></span>
                    </>
                  )}
                </div>
              </div>
            )}

            {formik.values.contestType === 'custom' &&
              formik.values.sports.length > 0 &&
              formik.values.sports.map((sport) => {
                return (
                  <div key={`select-teams-for-${sport}`}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                      <Checkbox
                        onChange={(e) => {
                          const selectTeams = { ...formik.values.selectTeams };
                          selectTeams[sport] = e.checked ? true : false;
                          formik.setFieldValue('selectTeams', selectTeams);
                        }}
                        checked={formik.values.selectTeams[sport]}
                        disabled={formik.values.sports.length === 0}
                      ></Checkbox>
                      <label className="pl-2" htmlFor={`select-teams-for-${sport}`}>
                        Select Teams for {SportName[sport]}
                      </label>
                    </div>
                    {formik.values.selectTeams[sport] && (
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                        <MultiSelect
                          id={`teams-for-${sport}`}
                          name={`teams-for-${sport}`}
                          value={formik.values.teams[sport]}
                          filter
                          onChange={(e) => {
                            const teams = { ...formik.values.teams };
                            teams[sport] = e.value;
                            formik.setFieldValue('teams', teams);
                          }}
                          options={getTeamOptions(sport)}
                          optionLabel="name"
                          optionValue="value"
                          placeholder="Select Teams"
                          className={classNames({
                            'p-invalid': formik.dirty && Boolean(formik.errors.sports)
                          })}
                          style={{ width: '100%' }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}

            {formik.values.contestType === 'custom' && (
              <div>
                <label htmlFor="startDate">Start Date</label>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                  <Calendar
                    id="startDate"
                    name="startDate"
                    value={formik.values.startDate}
                    onChange={(e) => formik.setFieldValue('startDate', e.value)}
                    placeholder="Select a start date"
                    className={classNames({
                      'p-invalid': formik.dirty && Boolean(formik.errors.startDate)
                    })}
                    style={{ width: '100%' }}
                  />
                  {formik.dirty && formik.errors.startDate && (
                    <>
                      <Tooltip target=".invalid-startdate-tooltip" />
                      <span
                        className="pi pi-exclamation-circle invalid-startdate-tooltip"
                        style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                        data-pr-tooltip={getErrorMessage('startDate')}
                        data-pr-position="left"
                      ></span>
                    </>
                  )}
                </div>
              </div>
            )}

            {formik.values.contestType === 'custom' && (
              <div>
                <label htmlFor="endDate">End Date</label>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                  <Calendar
                    id="endDate"
                    name="endDate"
                    value={formik.values.endDate}
                    onChange={(e) => formik.setFieldValue('endDate', e.value)}
                    placeholder="Select a end date"
                    className={classNames({
                      'p-invalid': formik.dirty && Boolean(formik.errors.endDate)
                    })}
                    style={{ width: '100%' }}
                  />
                  {formik.dirty && formik.errors.endDate && (
                    <>
                      <Tooltip target=".invalid-enddate-tooltip" />
                      <span
                        className="pi pi-exclamation-circle invalid-enddate-tooltip"
                        style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                        data-pr-tooltip={getErrorMessage('endDate')}
                        data-pr-position="left"
                      ></span>
                    </>
                  )}
                </div>
              </div>
            )}

            <div>
              <label htmlFor="startingBalance">Starting Balance</label>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">$</span>
                  <InputNumber
                    id="startingBalance"
                    name="startingBalance"
                    placeholder="1-1,000,000"
                    value={formik.values.startingBalance}
                    onChange={(e) => formik.setFieldValue('startingBalance', e.value)}
                    className={classNames({
                      'p-invalid': formik.dirty && Boolean(formik.errors.startingBalance)
                    })}
                    style={{ width: '100%' }}
                  />
                  <span className="p-inputgroup-addon">.00</span>
                </div>
                {formik.dirty && formik.errors.startingBalance && (
                  <>
                    <Tooltip target=".invalid-startingbalance-tooltip" />
                    <span
                      className="pi pi-exclamation-circle invalid-startingbalance-tooltip"
                      style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                      data-pr-tooltip={getErrorMessage('startingBalance')}
                      data-pr-position="left"
                    ></span>
                  </>
                )}
              </div>
            </div>

            <div>
              <label htmlFor="invitedMembers" className="flex-row-center">
                <span style={{ marginRight: '.25em' }}>Invite Members</span>
                {!suggestedContestMembersError && !suggestedContestMembersLoading && (
                  <IconButtonWithTooltip
                    icon={'pi-user-plus'}
                    tooltip={`Add ${suggestedContestMembers.length} suggested members`}
                    title={'Add suggested members'}
                    onClick={() => {
                      const allMembers = [
                        ...suggestedContestMembers,
                        ...formik.values.invitedMembers
                      ];
                      const uniqueMembers = [...new Set(allMembers)];
                      formik.setFieldValue('invitedMembers', uniqueMembers);
                    }}
                  />
                )}
              </label>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                <Chips
                  id="invitedMembers"
                  name="invitedMembers"
                  value={formik.values.invitedMembers}
                  onChange={(e) => formik.setFieldValue('invitedMembers', e.value)}
                  separator=","
                  placeholder="Invite members by email"
                  className={classNames({
                    'p-invalid': formik.dirty && Boolean(formik.errors.invitedMembers)
                  })}
                  style={{ width: '100%' }}
                />
                {formik.dirty && formik.errors.invitedMembers && (
                  <>
                    <Tooltip target=".invalid-invitedmembers-tooltip" />
                    <span
                      className="pi pi-exclamation-circle invalid-invitedmembers-tooltip"
                      style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                      data-pr-tooltip={getErrorMessage('invitedMembers')}
                      data-pr-position="left"
                    ></span>
                  </>
                )}
              </div>
            </div>

            <>
              <Divider className="mb-1" />
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                <Checkbox
                  name="pool-settings-active"
                  id="pool-settings-active"
                  onChange={(e) => {
                    formik.setFieldValue('poolSettingsActive', e.checked ? true : false);
                  }}
                  checked={formik.values.poolSettingsActive}
                ></Checkbox>
                <label className="pl-2" htmlFor="pool-settings-active">
                  Run as a pool?
                </label>
              </div>
              {formik.values.poolSettingsActive && (
                <>
                  <div className="mt-2">
                    <label htmlFor="pool-settings-buyin">Buy-in per Member</label>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">$</span>
                        <InputNumber
                          id="pool-settings-buyin"
                          name="pool-settings-buyin"
                          placeholder="1-1,000,000"
                          value={formik.values.poolSettingsBuyIn}
                          onChange={(e) => {
                            formik.setFieldValue('poolSettingsBuyIn', e.value);
                          }}
                          className={classNames({
                            'p-invalid': formik.dirty && Boolean(formik.errors.poolSettingsBuyIn)
                          })}
                          style={{ width: '100%' }}
                        />
                        <span className="p-inputgroup-addon">.00</span>
                      </div>
                      {formik.dirty && formik.errors.poolSettingsBuyIn && (
                        <>
                          <Tooltip target=".invalid-pool-settings-buyin-tooltip" />
                          <span
                            className="pi pi-exclamation-circle invalid-pool-settings-buyin-tooltip"
                            style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                            data-pr-tooltip={getErrorMessage('poolSettingsBuyIn')}
                            data-pr-position="left"
                          ></span>
                        </>
                      )}
                    </div>

                    <div className="mt-2">
                      <div>
                        Total Pool Value:{' '}
                        <span className="fw-bold">
                          {formatMoney(
                            formik.values.invitedMembers.length * formik.values.poolSettingsBuyIn,
                            formik.values.poolSettingsCurrency
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="mt-2">
                      <label htmlFor="pool-settings-numpayouts">Number of Payouts</label>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                        <InputNumber
                          id="pool-settings-numpayouts"
                          min={1}
                          max={formik.values.invitedMembers.length}
                          value={formik.values.poolSettingsNumPayouts}
                          onChange={(e) => {
                            if (!e.value || e.value > formik.values.invitedMembers.length) {
                              return;
                            }
                            formik.setFieldValue('poolSettingsNumPayouts', e.value);
                          }}
                          className={classNames({
                            'p-invalid':
                              formik.dirty && Boolean(formik.errors.poolSettingsNumPayouts)
                          })}
                          showButtons
                          buttonLayout="horizontal"
                          step={1}
                          incrementButtonIcon="pi pi-plus"
                          decrementButtonIcon="pi pi-minus"
                          style={{ width: '100%' }}
                        />
                        {formik.dirty && formik.errors.poolSettingsNumPayouts && (
                          <>
                            <Tooltip target=".invalid-pool-settings-numpayouts-tooltip" />
                            <span
                              className="pi pi-exclamation-circle invalid-pool-settings-numpayouts-tooltip"
                              style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                              data-pr-tooltip={getErrorMessage('poolSettingsNumPayouts')}
                              data-pr-position="left"
                            ></span>
                          </>
                        )}
                      </div>
                    </div>

                    {formik.values.poolSettingsNumPayouts &&
                      formik.values.poolSettingsNumPayouts <= formik.values.invitedMembers.length &&
                      Array(formik.values.poolSettingsNumPayouts)
                        .fill(0)
                        .map((_, index) => {
                          return (
                            <div key={`pool-settings-payout-${index}`} className="mt-2">
                              <div>
                                Payout for {+index + 1}
                                {getOrdinal(+index + 1)} Place
                              </div>
                              <div
                                style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}
                              >
                                <Dropdown
                                  id={`pool-settings-payout-${index}`}
                                  name={`pool-settings-payout-${index}`}
                                  value={
                                    formik.values.poolSettingsPayoutByStandingsPosition[+index]
                                  }
                                  onChange={(e) => {
                                    const newPayouts =
                                      formik.values.poolSettingsPayoutByStandingsPosition;
                                    newPayouts[+index] = e.value;
                                    formik.setFieldValue(
                                      'poolSettingsPayoutByStandingsPosition',
                                      newPayouts
                                    );
                                  }}
                                  options={getPayoutOptions()}
                                  optionLabel="name"
                                  placeholder="Select a payout option"
                                  className={classNames({
                                    'w-full': true,
                                    'p-invalid':
                                      formik.dirty &&
                                      Boolean(formik.errors.poolSettingsPayoutByStandingsPosition)
                                  })}
                                />
                                {/* {formik.values.poolSettingsPayoutByStandingsPosition[+index] ===
                                  'CUSTOM' && (
                                  <div className="p-inputgroup ml-2">
                                    <span className="p-inputgroup-addon">$</span>
                                    <InputNumber
                                      id={`pool-settings-payout-custom-${index}`}
                                      name={`pool-settings-payout-custom-${index}`}
                                      min={1}
                                      max={
                                        formik.values.poolSettingsBuyIn *
                                        formik.values.invitedMembers.length
                                      }
                                      placeholder={`1-${
                                        formik.values.poolSettingsBuyIn *
                                        formik.values.invitedMembers.length
                                      }`}
                                      value={
                                        formik.values.poolSettingsCustomPayoutByStandingsPosition[
                                          +index
                                        ]
                                      }
                                      onChange={(e) => {
                                        const newPayouts =
                                          formik.values.poolSettingsCustomPayoutByStandingsPosition;
                                        newPayouts[+index] = e.value;
                                        formik.setFieldValue(
                                          'poolSettingsCustomPayoutByStandingsPosition',
                                          newPayouts
                                        );
                                      }}
                                      className={classNames({
                                        'w-full': true,
                                        'p-invalid':
                                          formik.dirty &&
                                          Boolean(
                                            formik.errors
                                              .poolSettingsCustomPayoutByStandingsPosition
                                          )
                                      })}
                                      style={{ width: '100%' }}
                                    />
                                    <span className="p-inputgroup-addon">.00</span>
                                  </div>
                                )} */}
                                <div>
                                  <IconWithTooltip
                                    icon="pi pi-dollar"
                                    iconStyle={{
                                      fontSize: '1.25rem',
                                      marginLeft: '.25em',
                                      color: '#a5d6a7'
                                    }}
                                    tooltip={`Payout for ${+index + 1}${getOrdinal(
                                      +index + 1
                                    )} place: ${
                                      isNaN(
                                        getPoolPayoutByStandings({
                                          activeMembers: formik.values.invitedMembers,
                                          poolSettings: {
                                            buyIn: formik.values.poolSettingsBuyIn,
                                            currency: formik.values.poolSettingsCurrency,
                                            payoutByStandingsPosition:
                                              getActivePoolSettingsPayoutByStandingsPosition()
                                          }
                                        } as unknown as Contest)[+index]
                                      )
                                        ? '?'
                                        : formatMoney(
                                            getPoolPayoutByStandings({
                                              activeMembers: formik.values.invitedMembers,
                                              poolSettings: {
                                                buyIn: formik.values.poolSettingsBuyIn,
                                                currency: formik.values.poolSettingsCurrency,
                                                payoutByStandingsPosition:
                                                  getActivePoolSettingsPayoutByStandingsPosition()
                                              }
                                            } as unknown as Contest)[+index],
                                            formik.values.poolSettingsCurrency
                                          )
                                    }`}
                                  />
                                </div>
                                {formik.dirty &&
                                  formik.errors.poolSettingsPayoutByStandingsPosition && (
                                    <>
                                      <Tooltip
                                        target={`.invalid-pool-settings-payout-${index}-tooltip`}
                                      />
                                      <span
                                        className={`pi pi-exclamation-circle invalid-pool-settings-payout-${index}-tooltip`}
                                        style={{
                                          fontSize: '1.5rem',
                                          marginLeft: '.25em',
                                          color: '#ef9a9a'
                                        }}
                                        data-pr-tooltip={getErrorMessage(
                                          'poolSettingsPayoutByStandingsPosition'
                                        )}
                                        data-pr-position="left"
                                      ></span>
                                    </>
                                  )}
                              </div>
                            </div>
                          );
                        })}

                    {!isNaN(getTotalActivePoolSettingsPayoutsAllocated()) && (
                      <div className="mt-2">
                        <div>
                          Total Payouts Allocated:{' '}
                          <span className="fw-bold">
                            {formik.values.poolSettingsNumPayouts &&
                              formik.values.poolSettingsNumPayouts <=
                                formik.values.invitedMembers.length &&
                              formatMoney(
                                getTotalActivePoolSettingsPayoutsAllocated(),
                                formik.values.poolSettingsCurrency
                              )}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
            <div className="mt-1">
              <Button
                color="primary"
                type="submit"
                style={{ justifyContent: 'center' }}
                loading={savingContest}
                disabled={!(formik.isValid && formik.dirty) || savingContest}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default NewContest;
