import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../auth/auth';
import { Contest } from '../common/models/contest';
import { ContestStanding } from '../common/models/contest-standing';
import { ContestStatusIcon, ContestStatusName } from '../common/models/contest-status';
import { Season } from '../common/models/season';
import { formatShortDatestamp } from '../utils/utils';
import { ContestStandings } from './ContestStandings';
import IconButtonWithTooltip from './IconButtonWithTooltip';
import IconWithTooltip from './IconWithTooltip';
import { InfoBlock } from './InfoBlock';

export interface SeasonContestHistoryProps {
  season: Season;
  contests: Contest[];
  contestStandings: { [key: string]: ContestStanding[] | undefined };
}

export const SeasonContestHistory = (props: SeasonContestHistoryProps) => {
  const auth = useAuth();
  const { contests, contestStandings } = props;
  const [showContestStandings, setShowContestStandings] = useState<Contest | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [contests, contestStandings]);

  const contestNameTemplate = (contest: Contest) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <IconWithTooltip
            icon={ContestStatusIcon[contest.status]}
            tooltip={`${ContestStatusName[contest.status]} (${formatShortDatestamp(
              contest.start
            )} - ${formatShortDatestamp(contest.end)})`}
          ></IconWithTooltip>
        </div>
        <div style={{ marginLeft: '.4em' }}>
          <Link to={`/contests/${contest.id}`}>{contest.name}</Link>
        </div>
      </div>
    );
  };

  const contestPlaceTemplate = (contest: Contest) => {
    const standings = contest?.id?.length ? contestStandings[contest.id] : undefined;
    let myStandingIndex: number | undefined = undefined;
    standings?.forEach((standing, index) => {
      if (standing.member === auth?.user?.user?.email?.toLowerCase()) {
        myStandingIndex = index;
      }
    });

    if (standings?.length) {
      return (
        <>{myStandingIndex !== undefined ? `${myStandingIndex + 1}/${standings.length}` : 'N/A'}</>
      );
    } else {
      return (
        <IconWithTooltip icon="pi pi-question-circle" tooltip="Not available"></IconWithTooltip>
      );
    }
  };

  const contestStandingsTemplate = (contest: Contest) => {
    return (
      <IconButtonWithTooltip
        icon="pi pi-sort-numeric-down"
        tooltip="Standings"
        tooltipOptions={{ position: 'bottom' }}
        onClick={() => {
          setShowContestStandings(contest);
        }}
      />
    );
  };

  if (loading) {
    return <InfoBlock message="Collecting season data..." />;
  } else {
    return (
      <>
        <DataTable
          size="small"
          paginator
          paginatorTemplate={{
            layout: 'PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown'
          }}
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={contests}
        >
          <Column field="name" header="Contest" body={contestNameTemplate}></Column>
          <Column field="id" header="Place" body={contestPlaceTemplate}></Column>
          <Column field="id" body={contestStandingsTemplate}></Column>
        </DataTable>
        <Dialog
          header={`Standings: ${showContestStandings?.name}`}
          visible={showContestStandings ? true : false}
          style={{ width: '95vw' }}
          onHide={() => setShowContestStandings(undefined)}
        >
          {showContestStandings && (
            <ContestStandings contest={showContestStandings}></ContestStandings>
          )}
          {!showContestStandings && <InfoBlock message="Standings not available" />}
        </Dialog>
      </>
    );
  }
};
