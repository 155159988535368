import { UserCredential } from 'firebase/auth';
import { addDoc, collection, doc, getDoc, Timestamp } from 'firebase/firestore';
import { AuthContextType } from '../auth/auth';
import { AppStateToken } from '../common/models/app-state-token';
import { SignInEvent } from '../common/models/sign-in-event';
import { db } from '../firebase';

const COLLECTION_APP_STATE_TOKENS = 'appStateTokens';

export const saveSignInEvent = async (user: UserCredential): Promise<SignInEvent> => {
  const email = user?.user?.email;
  if (!email?.length) {
    throw new Error('Email provided in current auth context is invalid.');
  }
  const providerId = user?.providerId;
  if (!providerId?.length) {
    throw new Error('Provider ID provided in current auth context is invalid.');
  }
  const timestamp = Timestamp.now();

  const signInEvent = {
    email: email.toLowerCase(),
    url: window.location.href,
    timestamp: timestamp,
    providerId: providerId
  };
  const collectionRef = collection(db, 'signInEvents');
  const docRef = await addDoc(collectionRef, signInEvent);
  const savedDoc = { ...signInEvent, id: docRef.id };
  return savedDoc as SignInEvent;
};

export const isAuthContextTypeValid = (auth: AuthContextType): boolean => {
  return auth && auth.user && auth.user.user && auth.user.user.email && auth.user.user.email.length
    ? true
    : false;
};

export const getCurrentAppStateToken = async (): Promise<AppStateToken> => {
  const tokenDocRef = doc(db, COLLECTION_APP_STATE_TOKENS, 'current');
  return await getDoc(tokenDocRef).then(async (tokenDoc) => {
    return tokenDoc.data() as AppStateToken;
  });
};
