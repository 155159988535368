import axios from 'axios';
import { Avatar } from 'primereact/avatar';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useState } from 'react';
import { Team, getAbbreviation, getFullName } from '../common/models/team';

export interface TeamAvatarProps {
  team: Team | undefined;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right' | 'mouse';
  includeRanking?: boolean | undefined;
}

const defaultTooltipPosition = 'left';

export const TeamAvatar = (props: TeamAvatarProps) => {
  const { team, tooltipPosition, includeRanking } = props;
  const includeRankingActive = includeRanking ? includeRanking : false;
  const activeTooltipPosition = tooltipPosition ?? defaultTooltipPosition;

  const [loading, setLoading] = useState<boolean>(true);

  const [teamLogoUrl, setTeamLogoUrl] = useState<string | undefined>();
  const [teamName, setTeamName] = useState<string | undefined>();
  const [teamAbbreviation, setTeamAbbreviation] = useState<string | undefined>();

  const isTeamLogoUrlValid = async (url: string): Promise<boolean> => {
    try {
      const response = await axios.get(url, {
        responseType: 'arraybuffer'
      });
      if (!response || response.status !== 200) {
        throw new Error(`Unable to fetch image, response status was ${response?.status} not 200`);
      }
      return true;
    } catch (error) {
      throw new Error(`Unable to fetch image, an error occurred: ${error}`);
    }
  };

  useEffect(() => {
    setLoading(true);
    const activeTeam =
      team ??
      ({
        name: 'Unknown',
        abbreviation: 'UNK'
      } as Team);
    setTeamAbbreviation(getAbbreviation(activeTeam));
    setTeamName(getFullName(activeTeam));
    if (activeTeam.logo?.length) {
      isTeamLogoUrlValid(activeTeam.logo)
        .then((isValid) => {
          if (isValid) {
            setTeamLogoUrl(activeTeam.logo);
          } else {
            setTeamLogoUrl(undefined);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.warn('Unable to validate team logo, none will be displayed', error);
          setTeamLogoUrl(undefined);
          setLoading(false);
        });
    } else {
      setTeamLogoUrl(undefined);
      setLoading(false);
    }
  }, [team]);

  return (
    <>
      {loading && (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Tooltip target=".target-icon" />
          <Avatar
            label={'...'}
            className="target-icon"
            size="normal"
            shape="circle"
            data-pr-tooltip={'Loading...'}
            data-pr-position={activeTooltipPosition}
          />
        </div>
      )}
      {!loading && teamLogoUrl && (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          {includeRankingActive && team?.rankingLatest && (
            <div style={{ paddingRight: '.25em', fontSize: '.9em', fontWeight: '400' }}>
              <span>#{team.rankingLatest}</span>
            </div>
          )}
          <Tooltip target=".target-icon" />
          <Avatar
            image={teamLogoUrl}
            className="target-icon"
            size="normal"
            shape="circle"
            data-pr-tooltip={teamName}
            data-pr-position={activeTooltipPosition}
          />
        </div>
      )}
      {!loading && !teamLogoUrl && (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          {includeRankingActive && team?.rankingLatest && (
            <div style={{ paddingRight: '.25em' }}>
              <span>#{team.rankingLatest}</span>
            </div>
          )}
          <Tooltip target=".target-icon" />
          <Avatar
            label={teamAbbreviation}
            className="target-icon"
            size="normal"
            shape="circle"
            data-pr-tooltip={teamName}
            data-pr-position={defaultTooltipPosition}
          />
        </div>
      )}
    </>
  );
};
