import { Sport } from './sport';

export interface Team {
  id: string;
  name: string;
  sport: Sport;

  abbreviation?: string;
  color?: string;
  altColor?: string;
  location?: string;
  shortName?: string;
  logo?: string;
  logoDark?: string;

  espnName?: string;
  espnId?: string;
  espn?: any;

  rankingLatest?: number;
  rankingPrevious?: number;
  rankingFirstPlaceVotes?: number;
  rankingTrend?: string;

  totalRecordWins?: number;
  totalRecordLosses?: number;
  totalRecordTies?: number;
  homeRecordWins?: number;
  homeRecordLosses?: number;
  homeRecordTies?: number;
  awayRecordWins?: number;
  awayRecordLosses?: number;
  awayRecordTies?: number;
  conferenceRecordWins?: number;
  conferenceRecordLosses?: number;
  conferenceRecordTies?: number;
}

export const getFullName = (team: Team): string => {
  return team?.espnName?.length ? team.espnName : team.name;
};

export const getShortName = (team: Team): string => {
  return team.shortName?.length ? team.shortName : getFullName(team);
};

export const getAbbreviation = (team: Team): string => {
  return team.abbreviation?.length ? team.abbreviation : team.name.substring(0, 3).toUpperCase();
};

export const getLink = (team: Team | undefined, linkText: string): string | undefined => {
  if (!team) {
    return undefined;
  }
  const links = team?.espn?.links;
  if (!links) {
    return undefined;
  }
  const statsLink = links.find((link: any) => link?.text === linkText);
  return statsLink?.href;
};

export const getStatsLink = (team: Team | undefined): string | undefined => {
  return getLink(team, 'Statistics');
};

export const getScheduleLink = (team: Team | undefined): string | undefined => {
  return getLink(team, 'Schedule');
};

export const getRosterLink = (team: Team | undefined): string | undefined => {
  return getLink(team, 'Roster');
};

export const getClubhouseLink = (team: Team | undefined): string | undefined => {
  return getLink(team, 'Clubhouse');
};

export const getOverallRecord = (team: Team): string => {
  const wins = team.totalRecordWins || 0;
  const losses = team.totalRecordLosses || 0;
  const ties = team.totalRecordTies || 0;
  return `${wins}-${losses}${ties ? `-${ties}` : ''}`;
};

export const getHomeRecord = (team: Team): string => {
  const wins = team.homeRecordWins || 0;
  const losses = team.homeRecordLosses || 0;
  const ties = team.homeRecordTies || 0;
  return `${wins}-${losses}${ties ? `-${ties}` : ''}`;
};

export const getAwayRecord = (team: Team): string => {
  const wins = team.awayRecordWins || 0;
  const losses = team.awayRecordLosses || 0;
  const ties = team.awayRecordTies || 0;
  return `${wins}-${losses}${ties ? `-${ties}` : ''}`;
};

export const getConferenceRecord = (team: Team): string => {
  const wins = team.conferenceRecordWins || 0;
  const losses = team.conferenceRecordLosses || 0;
  const ties = team.conferenceRecordTies || 0;
  return `${wins}-${losses}${ties ? `-${ties}` : ''}`;
};
