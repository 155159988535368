import { Timestamp } from 'firebase/firestore';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { useEffect, useState } from 'react';
import { ParlayBet } from '../common/models/bet';
import { BetType } from '../common/models/bet-type';
import { Contest } from '../common/models/contest';
import {
  Game,
  isAwayTeamMoneylineValid,
  isAwayTeamSpreadValid,
  isHomeTeamMoneylineValid,
  isHomeTeamSpreadValid,
  isOverUnderLineValid
} from '../common/models/game';
import GameSummary from '../components/GameSummary';
import IconButtonWithTooltip from '../components/IconButtonWithTooltip';

export interface ContestParlayBetEditorProps {
  contest: Contest;
  bets: ParlayBet[];
  game: Game;
  collapsed: boolean;
  onRemoveGameFromParlay?: (gameId: string) => void;
  onBetsUpdated?: (bets: ParlayBet[]) => void;
}

export const ContestParlayBetEditor = (props: ContestParlayBetEditorProps) => {
  const { bets, collapsed, contest, game, onRemoveGameFromParlay, onBetsUpdated } = props;

  const [collapseGame, setCollapseGame] = useState(collapsed);

  const overBet = bets.find((b) => b.gameId === game.id && b.betType === BetType.OVER);
  const underBet = bets.find((b) => b.gameId === game.id && b.betType === BetType.UNDER);
  const homeTeamMoneylineBet = bets.find(
    (b) => b.gameId === game.id && b.betType === BetType.HOME_TEAM_MONEYLINE
  );
  const awayTeamMoneylineBet = bets.find(
    (b) => b.gameId === game.id && b.betType === BetType.AWAY_TEAM_MONEYLINE
  );
  const homeTeamSpreadBet = bets.find(
    (b) => b.gameId === game.id && b.betType === BetType.HOME_TEAM_SPREAD
  );
  const awayTeamSpreadBet = bets.find(
    (b) => b.gameId === game.id && b.betType === BetType.AWAY_TEAM_SPREAD
  );

  const validOverBet = overBet ? true : false;
  const validUnderBet = underBet ? true : false;

  const validHomeTeamMoneylineBet = homeTeamMoneylineBet ? true : false;
  const validAwayTeamMoneylineBet = awayTeamMoneylineBet ? true : false;
  const validHomeTeamSpreadBet = homeTeamSpreadBet ? true : false;
  const validAwayTeamSpreadBet = awayTeamSpreadBet ? true : false;

  const [overChecked, setOverChecked] = useState(
    validOverBet && validUnderBet ? false : validOverBet
  );
  const [underChecked, setUnderChecked] = useState(
    validOverBet && validUnderBet ? false : validUnderBet
  );

  const [homeTeamMoneylineChecked, setHomeTeamMoneylineChecked] = useState(
    validHomeTeamMoneylineBet &&
      (validAwayTeamMoneylineBet || validHomeTeamSpreadBet || validAwayTeamSpreadBet)
      ? false
      : validHomeTeamMoneylineBet
  );

  const [awayTeamMoneylineChecked, setAwayTeamMoneylineChecked] = useState(
    validAwayTeamMoneylineBet &&
      (validHomeTeamMoneylineBet || validHomeTeamSpreadBet || validAwayTeamSpreadBet)
      ? false
      : validAwayTeamMoneylineBet
  );

  const [homeTeamSpreadChecked, setHomeTeamSpreadChecked] = useState(
    validHomeTeamSpreadBet &&
      (validAwayTeamSpreadBet || validHomeTeamMoneylineBet || validAwayTeamMoneylineBet)
      ? false
      : validHomeTeamSpreadBet
  );

  const [awayTeamSpreadChecked, setAwayTeamSpreadChecked] = useState(
    validAwayTeamSpreadBet &&
      (validHomeTeamSpreadBet || validHomeTeamMoneylineBet || validAwayTeamMoneylineBet)
      ? false
      : validAwayTeamSpreadBet
  );

  useEffect(() => {
    const updatedBets: ParlayBet[] = [];

    const now = Timestamp.now();

    if (overChecked || underChecked) {
      const betType = overChecked ? BetType.OVER : BetType.UNDER;
      const betLine = game.overUnderLine;
      const betPrice = overChecked ? game.overPrice : game.underPrice;
      const betTeam = overChecked ? 'Over' : 'Under';
      const overUnderBet = {
        gameId: game.id,
        contestId: contest.id,
        betType: betType,
        line: betLine,
        price: betPrice,
        teamName: betTeam,
        timestamp: now
      } as ParlayBet;
      updatedBets.push(overUnderBet);
    }

    if (
      awayTeamSpreadChecked ||
      homeTeamSpreadChecked ||
      awayTeamMoneylineChecked ||
      homeTeamMoneylineChecked
    ) {
      let betType: BetType;
      if (awayTeamSpreadChecked) {
        betType = BetType.AWAY_TEAM_SPREAD;
      } else if (homeTeamSpreadChecked) {
        betType = BetType.HOME_TEAM_SPREAD;
      } else if (awayTeamMoneylineChecked) {
        betType = BetType.AWAY_TEAM_MONEYLINE;
      } else {
        betType = BetType.HOME_TEAM_MONEYLINE;
      }

      let betLine: number | undefined = undefined;
      if (awayTeamSpreadChecked) {
        betLine = game.awayTeamSpread;
      } else if (homeTeamSpreadChecked) {
        betLine = game.homeTeamSpread;
      } else if (awayTeamMoneylineChecked) {
        betLine = game.awayTeamMoneyline;
      } else if (homeTeamMoneylineChecked) {
        betLine = game.homeTeamMoneyline;
      }

      let betPrice: number;
      if (awayTeamSpreadChecked) {
        betPrice = game.awayTeamSpreadPrice;
      } else if (homeTeamSpreadChecked) {
        betPrice = game.homeTeamSpreadPrice;
      } else if (awayTeamMoneylineChecked) {
        betPrice = game.awayTeamMoneyline;
      } else {
        betPrice = game.homeTeamMoneyline;
      }

      let betTeam: string;
      if (awayTeamSpreadChecked || awayTeamMoneylineChecked) {
        betTeam = game.awayTeam;
      } else {
        betTeam = game.homeTeam;
      }

      const teamBet = {
        gameId: game.id,
        contestId: contest.id,
        betType: betType,
        line: betLine,
        price: betPrice,
        teamName: betTeam,
        timestamp: now
      } as ParlayBet;
      updatedBets.push(teamBet);
    }

    if (!updatedBets.length) {
      updatedBets.push({
        gameId: game.id,
        contestId: contest.id
      } as ParlayBet);
    }

    if (onBetsUpdated) {
      onBetsUpdated(updatedBets);
    }
  }, [
    overChecked,
    underChecked,
    homeTeamMoneylineChecked,
    awayTeamMoneylineChecked,
    homeTeamSpreadChecked,
    awayTeamSpreadChecked
  ]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          marginBottom: '.5em',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <IconButtonWithTooltip
          icon={`pi ${collapseGame ? 'pi-plus' : 'pi-minus'}`}
          tooltip={`${collapseGame ? 'Show game' : 'Hide game'}`}
          onClick={() => {
            setCollapseGame(!collapseGame);
          }}
        />
        <IconButtonWithTooltip
          icon="pi pi-times"
          title="Remove"
          tooltip="Remove from parlay"
          onClick={() => {
            if (onRemoveGameFromParlay) {
              onRemoveGameFromParlay(game.id);
            }
          }}
        />
      </div>
      {!collapseGame && (
        <>
          <GameSummary game={game} teamDetails={false} />
          <Divider />
          <div style={{ fontSize: 'small' }}>
            {isOverUnderLineValid(game) && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ paddingBottom: '.5em', display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    inputId={`overCheckbox${game.id}`}
                    onChange={(e) => {
                      const overSelected = e.checked ? true : false;
                      setOverChecked(overSelected);
                      if (overSelected) {
                        setUnderChecked(!overSelected);
                      }
                    }}
                    checked={overChecked}
                  ></Checkbox>
                  <label htmlFor={`overCheckbox${game.id}`} style={{ marginLeft: '.25em' }}>
                    Over {game.overUnderLine.toFixed(1)} ({game.overPrice > 0 ? '+' : ''}
                    {game.overPrice})
                  </label>
                </div>
                <div style={{ paddingBottom: '.5em', display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    inputId={`underCheckbox${game.id}`}
                    onChange={(e) => {
                      const underSelected = e.checked ? true : false;
                      setUnderChecked(underSelected);
                      if (underSelected) {
                        setOverChecked(!underSelected);
                      }
                    }}
                    checked={underChecked}
                  ></Checkbox>
                  <label htmlFor={`underCheckbox${game.id}`} style={{ marginLeft: '.25em' }}>
                    Under {game.overUnderLine.toFixed(1)} ({game.underPrice > 0 ? '+' : ''}
                    {game.underPrice})
                  </label>
                </div>
              </div>
            )}
            <div style={{ marginTop: '1.5em' }}>
              {isAwayTeamSpreadValid(game) && (
                <div style={{ paddingBottom: '.5em', display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    inputId={`awayTeamSpread${game.id}`}
                    onChange={(e) => {
                      const selected = e.checked ? true : false;
                      setAwayTeamSpreadChecked(selected);
                      if (selected) {
                        setHomeTeamSpreadChecked(!selected);
                        setAwayTeamMoneylineChecked(!selected);
                        setHomeTeamMoneylineChecked(!selected);
                      }
                    }}
                    checked={awayTeamSpreadChecked}
                  ></Checkbox>
                  <label htmlFor={`awayTeamSpread${game.id}`} style={{ marginLeft: '.25em' }}>
                    {game.awayTeam} {game.awayTeamSpread >= 0 ? '+' : ''}
                    {game.awayTeamSpread.toFixed(1)} ({game.awayTeamSpreadPrice > 0 ? '+' : ''}
                    {game.awayTeamSpreadPrice})
                  </label>
                </div>
              )}
              {isAwayTeamMoneylineValid(game) && (
                <div style={{ paddingBottom: '.5em', display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    inputId={`awayTeamMoneyline${game.id}`}
                    onChange={(e) => {
                      const selected = e.checked ? true : false;
                      setAwayTeamMoneylineChecked(selected);
                      if (selected) {
                        setHomeTeamSpreadChecked(!selected);
                        setAwayTeamSpreadChecked(!selected);
                        setHomeTeamMoneylineChecked(!selected);
                      }
                    }}
                    checked={awayTeamMoneylineChecked}
                  ></Checkbox>
                  <label htmlFor={`awayTeamMoneyline${game.id}`} style={{ marginLeft: '.25em' }}>
                    {game.awayTeam} {game.awayTeamMoneyline >= 0 ? '+' : ''}
                    {game.awayTeamMoneyline}
                  </label>
                </div>
              )}
              {isHomeTeamSpreadValid(game) && (
                <div style={{ paddingBottom: '.5em', display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    inputId={`homeTeamSpread${game.id}`}
                    onChange={(e) => {
                      const selected = e.checked ? true : false;
                      setHomeTeamSpreadChecked(selected);
                      if (selected) {
                        setAwayTeamSpreadChecked(!selected);
                        setAwayTeamMoneylineChecked(!selected);
                        setHomeTeamMoneylineChecked(!selected);
                      }
                    }}
                    checked={homeTeamSpreadChecked}
                  ></Checkbox>
                  <label htmlFor={`homeTeamSpread${game.id}`} style={{ marginLeft: '.25em' }}>
                    {game.homeTeam} {game.homeTeamSpread >= 0 ? '+' : ''}
                    {game.homeTeamSpread.toFixed(1)} ({game.homeTeamSpreadPrice > 0 ? '+' : ''}
                    {game.homeTeamSpreadPrice})
                  </label>
                </div>
              )}
              {isHomeTeamMoneylineValid(game) && (
                <div style={{ paddingBottom: '.5em', display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    inputId={`homeTeamMoneyline${game.id}`}
                    onChange={(e) => {
                      const selected = e.checked ? true : false;
                      setHomeTeamMoneylineChecked(selected);
                      if (selected) {
                        setHomeTeamSpreadChecked(!selected);
                        setAwayTeamSpreadChecked(!selected);
                        setAwayTeamMoneylineChecked(!selected);
                      }
                    }}
                    checked={homeTeamMoneylineChecked}
                  ></Checkbox>
                  <label htmlFor={`homeTeamMoneyline${game.id}`} style={{ marginLeft: '.25em' }}>
                    {game.homeTeam} {game.homeTeamMoneyline >= 0 ? '+' : ''}
                    {game.homeTeamMoneyline}
                  </label>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {collapseGame && (
        <div
          style={{
            fontSize: 'small'
          }}
          className="grid flex flex-row align-items-center"
        >
          <div className="col-8" style={{ fontWeight: '600', textAlign: 'left' }}>
            {game.awayTeam} @ {game.homeTeam}
          </div>
          <div className="col-4" style={{ fontWeight: '400', textAlign: 'right' }}>
            {
              bets.filter(
                (bet) => bet.gameId === game.id && bet.price !== undefined && bet.price != null
              ).length
            }{' '}
            bet
            {bets.filter(
              (bet) => bet.gameId === game.id && bet.price !== undefined && bet.price != null
            ).length == 1
              ? ''
              : 's'}
          </div>
        </div>
      )}
    </>
  );
};
