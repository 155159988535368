import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorBlock } from '../components/ErrorBlock';
import { InfoBlock } from '../components/InfoBlock';
import { auth } from '../firebase';
import { saveSignInEvent } from '../utils/auth-utils';
import { createDefaultOrUpdateUserProfile } from '../utils/user-utils';
import useAuth from './auth';

function SignIn() {
  const authContext = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || '/';

  const [signInWithGoogle, googleUser, loadingGoogleUser, errorGoogleUser] =
    useSignInWithGoogle(auth);
  // const [signInWithFacebook, facebookUser, loadingFacebookUser, errorFacebookUser] =
  //   useSignInWithFacebook(auth);
  const [facebookUser] = useState(null);
  const [errorFacebookUser] = useState(null);
  const [signInError, setSignInError] = useState(false);

  useEffect(() => {
    if (authContext.user) {
      navigate(from, { replace: true });
    }
  });

  useEffect(() => {
    if (!authContext.user && (googleUser || facebookUser)) {
      if (googleUser) {
        authContext.setUser(googleUser);
        saveSignInEvent(googleUser)
          .catch((error) => {
            console.error('Unable to save sign in event', error);
          })
          .finally(() => {
            createDefaultOrUpdateUserProfile(googleUser)
              .then(() => {
                if (from === '/') {
                  navigate('/home', { replace: true });
                } else {
                  navigate(from, { replace: true });
                }
              })
              .catch((error) => {
                console.error('Unable to create default user profile', error);
                setSignInError(true);
                navigate('/signout', { replace: true });
              });
          });
      }
      // } else if (facebookUser) {
      //   authContext.setUser(facebookUser);
      //   createDefaultUserProfile(facebookUser).catch((error) => {
      //     console.error('Unable to create default user profile', error);
      //   });
      // }
    }
  }, [googleUser, facebookUser]);

  useEffect(() => {
    if (errorGoogleUser || errorFacebookUser) {
      setSignInError(true);
      if (errorGoogleUser) {
        console.error('Unable to sign in with Google', errorGoogleUser);
      }
      if (errorFacebookUser) {
        console.error('Unable to sign in with Facebook', errorFacebookUser);
      }
    } else {
      setSignInError(false);
    }
  }, [errorGoogleUser, errorFacebookUser]);

  return (
    <div className="Login">
      <div className="flex-col-center p-4">
        <div className="flex-col-center m-2">
          <div>Welcome to</div>
          <span style={{ color: '#386dd1', fontFamily: 'Pacifico Regular', fontSize: '2em' }}>
            Take The Points
          </span>
        </div>
        <div className="m-1">
          <Button
            label="Sign In with Google"
            aria-label="Sign In with Google"
            icon="pi pi-google"
            iconPos="left"
            disabled={loadingGoogleUser}
            onClick={() => signInWithGoogle()}
          />
        </div>
        <div className="mt-3" style={{ width: '75vw' }}>
          <InfoBlock
            showIcon={false}
            message="By signing into Take The Points you are agreeing to our terms of service and to receive Email notifications related to our contests."
          />
          <div className="m-2"></div>
          <InfoBlock
            showIcon={false}
            message="You can update your notification settings at any time via your Account Settings."
          />
        </div>
        {/* <div className="m-1">
          <Button
            label="Sign In with Facebook"
            aria-label="Sign In with Facebook"
            icon="pi pi-facebook"
            iconPos="left"
            disabled={loadingFacebookUser}
            onClick={() => signInWithFacebook()}
          />
        </div> */}
        {signInError && (
          <div className="m-2">
            <ErrorBlock message="Unable to sign in" />
          </div>
        )}
      </div>
    </div>
  );
}

export default SignIn;
