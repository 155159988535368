import { useEffect, useState } from 'react';
import useAuth from '../auth/auth';
import { BetStatus } from '../common/models/bet-status';
import { Contest } from '../common/models/contest';
import { ContestStanding } from '../common/models/contest-standing';
import { getBetsForContestByStatus } from '../utils/bet-utils';
import { buildContestStandings, validatePoolSettings } from '../utils/contest-utils';
import { formatMoney, getOrdinal } from '../utils/utils';
import { ErrorBlock } from './ErrorBlock';
import IconWithTooltip from './IconWithTooltip';
import {
  INACTIVE_ERROR,
  NO_BETS_ERROR,
  SpotlightPanel,
  UNKNOWN_ERROR,
  UNQUALIFIED_ERROR
} from './SpotlightPanel';

export interface PerformanceSpotlightPanelsProps {
  contest: Contest;
}

export const renderContestStandingBetsSummary = (standing: ContestStanding) => {
  return (
    <>
      <div>{standing.numBets} bets</div>
      <div>
        {(standing.numBets ? (standing.numWinningBets / standing.numBets) * 100 : 0).toFixed(0)}%
        wins
      </div>
    </>
  );
};

export const renderContestStandingsBetsSummary = (
  member: string | null | undefined,
  standings: ContestStanding[]
) => {
  const includedStandings = standings.filter((standing) => standing.active && standing.qualifying);
  const numOtherBets = includedStandings.reduce((acc, standing) => acc + standing.numBets, 0);
  const numOtherWinningBets = includedStandings.reduce(
    (acc, standing) => acc + standing.numWinningBets,
    0
  );
  return (
    <>
      <div>{numOtherBets} bets</div>
      <div>{(numOtherBets ? (numOtherWinningBets / numOtherBets) * 100 : 0).toFixed(0)}% wins</div>
    </>
  );
};

export const renderContestStandingPoolEarningsSummary = (
  standing: ContestStanding,
  contest: Contest
) => {
  return `${formatMoney(standing.poolBalance, contest.poolSettings.currency)}`;
};

export const renderContestStandingsBalanceSummary = (
  standings: ContestStanding[],
  contest: Contest
) => {
  const includedStandings = standings.filter((standing) => standing.active && standing.qualifying);

  const totalBalance = includedStandings.reduce((acc, standing) => acc + standing.balance, 0);
  const totalInitialBalance =
    (contest?.activeMembers ? contest.activeMembers.length : 0) * contest.initialBalance;
  const totalBalanceFormatted = Intl.NumberFormat('en-US', {
    notation: 'compact',
    style: 'currency',
    currency: contest.initialBalanceCurrency,
    maximumFractionDigits: 1
  }).format(totalBalance || 0);

  const avgBalance = totalBalance / includedStandings.length;
  const avgBalanceFormatted = Intl.NumberFormat('en-US', {
    notation: 'compact',
    style: 'currency',
    currency: contest.initialBalanceCurrency,
    maximumFractionDigits: 1
  }).format(avgBalance || 0);

  return (
    <div>
      <div className="flex flex-row align-items-center flex-justify-center">
        <div>{totalBalanceFormatted}</div>
        <div>
          {totalBalance > totalInitialBalance && (
            <IconWithTooltip
              icon="pi pi-arrow-circle-up"
              iconStyle={{ color: '#a5d6a7', marginLeft: '.25em' }}
              tooltip={`Up ${formatMoney(totalBalance - totalInitialBalance)}`}
            />
          )}
          {totalBalance === totalInitialBalance && (
            <IconWithTooltip
              icon="pi pi-minus-circle"
              tooltip="Breaking even"
              iconStyle={{ marginLeft: '.25em' }}
            />
          )}
          {totalBalance < totalInitialBalance && (
            <IconWithTooltip
              icon="pi pi-arrow-circle-down"
              iconStyle={{ color: '#ef9a9a', marginLeft: '.25em' }}
              tooltip={`Down ${formatMoney(totalInitialBalance - totalBalance)}`}
            />
          )}
        </div>
      </div>
      <div className="flex flex-row align-items-center flex-justify-center">
        <div>{avgBalanceFormatted}</div>
        <div>
          {avgBalance > contest.initialBalance && (
            <IconWithTooltip
              icon="pi pi-arrow-circle-up"
              iconStyle={{ color: '#a5d6a7', marginLeft: '.25em' }}
              tooltip={`Up ${formatMoney(avgBalance - contest.initialBalance)}`}
            />
          )}
          {avgBalance === contest.initialBalance && (
            <IconWithTooltip
              icon="pi pi-minus-circle"
              tooltip="Breaking even"
              iconStyle={{ marginLeft: '.25em' }}
            />
          )}
          {avgBalance < contest.initialBalance && (
            <IconWithTooltip
              icon="pi pi-arrow-circle-down"
              iconStyle={{ color: '#ef9a9a', marginLeft: '.25em' }}
              tooltip={`Down ${formatMoney(contest.initialBalance - avgBalance)}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const renderContestStandingBalanceSummary = (
  standing: ContestStanding,
  contest: Contest
) => {
  return (
    <div className="flex flex-row align-items-center">
      <div>{formatMoney(standing.balance, contest.initialBalanceCurrency)}</div>
      <div>
        {standing.balance > contest.initialBalance && (
          <IconWithTooltip
            icon="pi pi-arrow-circle-up"
            iconStyle={{ color: '#a5d6a7', marginLeft: '.25em' }}
            tooltip={`Up ${formatMoney(standing.balance - contest.initialBalance)}`}
          />
        )}
        {standing.balance === contest.initialBalance && (
          <IconWithTooltip
            icon="pi pi-minus-circle"
            tooltip="Breaking even"
            iconStyle={{ marginLeft: '.25em' }}
          />
        )}
        {standing.balance < contest.initialBalance && (
          <IconWithTooltip
            icon="pi pi-arrow-circle-down"
            iconStyle={{ color: '#ef9a9a', marginLeft: '.25em' }}
            tooltip={`Down ${formatMoney(contest.initialBalance - standing.balance)}`}
          />
        )}
      </div>
    </div>
  );
};

const renderStandingsSummary = (
  member: string | null | undefined,
  standings: ContestStanding[]
) => {
  const standing = standings.find((standing) => standing.member === member);
  if (!standing) {
    return <ErrorBlock message="Standings not yet available" />;
  }

  const standingIndex = standings.findIndex((standing) => standing.member === member);
  const standingRank = standingIndex + 1;
  const ordinal = getOrdinal(standingRank);
  const standingRankText = `${standingRank}${ordinal}`;

  return `${standingRankText} / ${standings.length}`;
};

export const PerformanceSpotlightPanels = (props: PerformanceSpotlightPanelsProps) => {
  const auth = useAuth();
  const { contest } = props;

  const [loadingBets, setLoadingBets] = useState(true);
  const [errorBets, setErrorBets] = useState<string | undefined>();
  const [standings, setStandings] = useState<ContestStanding[]>([]);
  const [myStanding, setMyStanding] = useState<ContestStanding | undefined>(undefined);
  const [contestPoolSettingsValidationError, setContestPoolSettingsValidationError] = useState(
    validatePoolSettings(contest)
  );

  const loadBets = () => {
    setLoadingBets(true);
    setErrorBets(undefined);
    getBetsForContestByStatus(contest, [
      BetStatus.LOCKED,
      BetStatus.WON,
      BetStatus.LOST,
      BetStatus.PUSH
    ])
      .then((bets) => {
        if (bets?.length === 0) {
          setErrorBets(NO_BETS_ERROR);
          setLoadingBets(false);
          return;
        }

        const currentStandings = buildContestStandings(contest, bets);

        const activeStandings = currentStandings.filter(
          (standing) => standing.active && standing.qualifying
        );
        setStandings(activeStandings);

        const userStanding = currentStandings.find(
          (standing) => standing.member.toLowerCase() === auth?.user?.user?.email?.toLowerCase()
        );
        if (userStanding) {
          setMyStanding(userStanding);
          if (!userStanding?.active) {
            setErrorBets(INACTIVE_ERROR);
          } else if (!userStanding?.qualifying) {
            setErrorBets(UNQUALIFIED_ERROR);
          }
        } else {
          setErrorBets(UNKNOWN_ERROR);
        }

        setLoadingBets(false);
      })
      .catch((error) => {
        console.error('Unable to load bets.', error);
        setErrorBets('Unable to load bets for this contest.');
        setLoadingBets(false);
      });
  };

  useEffect(() => {
    setContestPoolSettingsValidationError(validatePoolSettings(contest));
    loadBets();
  }, [contest]);

  const balanceInfoTemplate = (
    <div style={{ maxWidth: '8em' }}>
      <div>Your current balance in the contest</div>
      <div style={{ marginTop: '.5em' }}>This is how you are ranked in the standings.</div>
    </div>
  );

  const contestBalanceInfoTemplate = (
    <div style={{ maxWidth: '8em' }}>
      <div>The total and average balance for all members of the contest.</div>
    </div>
  );

  return (
    <>
      <SpotlightPanel
        title="Standings"
        loading={loadingBets}
        error={errorBets || contestPoolSettingsValidationError}
        value={renderStandingsSummary(auth?.user?.user?.email, standings)}
      />
      {contest.poolSettings && !contestPoolSettingsValidationError && (
        <SpotlightPanel
          title="Pool Earnings"
          loading={loadingBets}
          error={errorBets || contestPoolSettingsValidationError}
          value={renderContestStandingPoolEarningsSummary(
            myStanding || ({} as ContestStanding),
            contest
          )}
        />
      )}
      <SpotlightPanel
        title="My Balance"
        infoTemplate={balanceInfoTemplate}
        loading={loadingBets}
        error={errorBets || contestPoolSettingsValidationError}
        value={renderContestStandingBalanceSummary(myStanding || ({} as ContestStanding), contest)}
      />
      <SpotlightPanel
        title="My Bets"
        loading={loadingBets}
        error={errorBets !== NO_BETS_ERROR ? undefined : errorBets}
        value={renderContestStandingBetsSummary(myStanding || ({} as ContestStanding))}
      />
      <SpotlightPanel
        title="Contest Balance"
        infoTemplate={contestBalanceInfoTemplate}
        loading={loadingBets}
        error={errorBets !== NO_BETS_ERROR ? undefined : errorBets}
        value={renderContestStandingsBalanceSummary(standings, contest)}
      />
      <SpotlightPanel
        title="Contest Bets"
        loading={loadingBets}
        error={errorBets !== NO_BETS_ERROR ? undefined : errorBets}
        value={renderContestStandingsBetsSummary(auth?.user?.user?.email, standings)}
      />
    </>
  );
};
