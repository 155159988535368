import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContestSetup } from '../common/models/contest-setup';
import { Team } from '../common/models/team';
import { ErrorBlock } from '../components/ErrorBlock';
import { LoadingBlock } from '../components/LoadingBlock';
import PageHeader from '../components/PageHeader';
import useGlobalToasts from '../hooks/global-toasts';
import { getContestSetup, saveContestSetup } from '../utils/contest-utils';
import { getAllTeams } from '../utils/team-utils';
import { ContestSetupForm } from './ContestSetupForm';

export interface EditContestSetupProps {
  contestSetupId?: string;
}

export const EditContestSetup = (props: EditContestSetupProps) => {
  const { contestSetupId } = props;
  const globalToasts = useGlobalToasts();

  const params = useParams();
  const navigate = useNavigate();

  const [contestSetup, setContestSetup] = useState<ContestSetup | undefined>(undefined);
  const [loadingContestSetup, setLoadingContestSetup] = useState(false);
  const [errorContestSetup, setErrorContestSetup] = useState<string | undefined>(undefined);

  const [teams, setTeams] = useState<Team[]>([]);
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [errorTeams, setErrorTeams] = useState<string | undefined>(undefined);

  const [savingContestSetup, setSavingContestSetup] = useState(false);

  let activeContestSetupId: string | undefined;
  if (params?.contestSetupId) {
    activeContestSetupId = params.contestSetupId;
  } else {
    activeContestSetupId = contestSetupId;
  }

  if (!activeContestSetupId?.length) {
    return <ErrorBlock message="Could not load contest setup from invalid ID" />;
  }

  useEffect(() => {
    setErrorContestSetup(undefined);
    setLoadingContestSetup(true);

    if (activeContestSetupId?.length) {
      getContestSetup(activeContestSetupId)
        .then((contestSetup) => {
          setContestSetup(contestSetup);
          setLoadingContestSetup(false);

          if (!contestSetup) {
            setErrorContestSetup('Could not load contest setup');
            setLoadingContestSetup(false);
            return;
          }
        })
        .catch((error) => {
          setErrorContestSetup(error.message);
          setLoadingContestSetup(false);
        });
    } else {
      setLoadingContestSetup(false);
    }

    setErrorTeams(undefined);
    setLoadingTeams(false);
    getAllTeams()
      .then((teams) => {
        setTeams(teams);
        setLoadingTeams(false);
      })
      .catch((error) => {
        setErrorTeams(error.message);
        setLoadingTeams(false);
      });
  }, [activeContestSetupId]);

  return (
    <>
      {(errorContestSetup || errorTeams) && <ErrorBlock />}
      {!(errorContestSetup || errorTeams) && (loadingContestSetup || loadingTeams) && (
        <LoadingBlock />
      )}
      {!(errorContestSetup || errorTeams) &&
        !(loadingContestSetup || loadingTeams) &&
        activeContestSetupId?.length &&
        !contestSetup && <ErrorBlock message="Contest setup not found" />}
      {!(errorContestSetup || errorTeams) &&
        !(loadingContestSetup || loadingTeams) &&
        contestSetup && (
          <>
            <PageHeader title={contestSetup.name} />
            <ContestSetupForm
              contestSetup={contestSetup}
              teams={teams}
              loading={savingContestSetup}
              disabled={savingContestSetup}
              onSubmit={(contestSetup) => {
                setSavingContestSetup(true);
                saveContestSetup(contestSetup)
                  .then(() => {
                    globalToasts.sendToast(
                      'success',
                      'Contest Setup Saved',
                      'Contest Setup saved successfully'
                    );
                    navigate('/contest-setups');
                  })
                  .catch((error) => {
                    setSavingContestSetup(false);
                    globalToasts.sendToast('error', 'Error Saving Contest Setup', error.message);
                  });
              }}
              onCancel={() => {
                navigate('/contest-setups');
              }}
            />
          </>
        )}
    </>
  );
};
