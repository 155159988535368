import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContestSetup } from '../common/models/contest-setup';
import { ErrorBlock } from '../components/ErrorBlock';
import IconButtonWithTooltip from '../components/IconButtonWithTooltip';
import { LoadingBlock } from '../components/LoadingBlock';
import PageHeader from '../components/PageHeader';
import { getAllContestSetups } from '../utils/contest-utils';

export const ContestSetups = () => {
  const navigate = useNavigate();
  const [contestSetups, setContestSetups] = useState<ContestSetup[]>([]);
  const [loadingContestSetups, setLoadingContestSetups] = useState(false);
  const [errorContestSetups, setErrorContestSetups] = useState<string | undefined>(undefined);

  useEffect(() => {
    setLoadingContestSetups(true);
    setErrorContestSetups(undefined);
    getAllContestSetups()
      .then((contestSetups) => {
        setContestSetups(contestSetups);
        setLoadingContestSetups(false);
      })
      .catch((error) => {
        console.error('Unable to load contest setups: ', error);
        setErrorContestSetups('Unable to load contest setups');
        setLoadingContestSetups(false);
      });
  }, []);

  const newContestSetupLink = (
    <Button
      label="New Contest Setup"
      className="p-button-text"
      icon="pi pi-plus-circle"
      onClick={() => navigate('/contest-setups/new')}
    />
  );

  return (
    <>
      <PageHeader title="Contest Setups" context={newContestSetupLink} />
      {errorContestSetups && !errorContestSetups && <ErrorBlock message={errorContestSetups} />}
      {loadingContestSetups && !errorContestSetups && (
        <LoadingBlock message="Loading contest setups..." />
      )}
      {!loadingContestSetups && !errorContestSetups && (
        <>
          <div className="grid">
            <div className="col-9 fw-bold">Name</div>
            <div className="col-3"></div>
          </div>
          {contestSetups.map((contestSetup) => (
            <div className="grid flex flex-row-center" key={contestSetup.id}>
              <div className="col-9">{contestSetup.name}</div>
              <div className="col-3 text-right">
                <IconButtonWithTooltip
                  icon="pi pi-pencil"
                  tooltip="Edit"
                  onClick={() => navigate(`/contest-setups/${contestSetup.id}/edit`)}
                />
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};
