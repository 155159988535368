import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { BetStatus } from '../common/models/bet-status';
import { Contest } from '../common/models/contest';
import { ContestStanding } from '../common/models/contest-standing';
import { getBetsForContestByStatus } from '../utils/bet-utils';
import { buildContestStandings, validatePoolSettings } from '../utils/contest-utils';
import { formatMoney } from '../utils/utils';
import { ErrorBlock } from './ErrorBlock';
import IconWithTooltip from './IconWithTooltip';
import { LoadingBlock } from './LoadingBlock';
import {
  renderContestStandingBalanceSummary,
  renderContestStandingBetsSummary
} from './PerformanceSpotlightPanels';
import { UserAvatar } from './UserAvatar';

export interface ContestStandingsProps {
  contest: Contest;
}

export const ContestStandings = (props: ContestStandingsProps) => {
  const { contest } = props;

  const [loadingBets, setLoadingBets] = useState(true);
  const [errorBets, setErrorBets] = useState<string | undefined>();
  const [standings, setStandings] = useState<ContestStanding[]>([]);
  const [contestPoolSettingsValidationError, setContestPoolSettingsValidationError] = useState(
    validatePoolSettings(contest)
  );

  const loadBets = () => {
    setLoadingBets(true);
    setErrorBets(undefined);
    getBetsForContestByStatus(contest, [
      BetStatus.LOCKED,
      BetStatus.WON,
      BetStatus.LOST,
      BetStatus.PUSH
    ])
      .then((bets) => {
        const currentStandings = buildContestStandings(contest, bets);

        const activeStandings = currentStandings.filter(
          (standing) => standing.active && standing.qualifying
        );
        setStandings(activeStandings);
        setLoadingBets(false);
      })
      .catch((error) => {
        console.error('Unable to load bets.', error);
        setErrorBets('Unable to load bets for this contest.');
        setLoadingBets(false);
      });
  };

  useEffect(() => {
    setContestPoolSettingsValidationError(validatePoolSettings(contest));
    loadBets();
  }, [contest]);

  const userTemplate = (standing: ContestStanding) => {
    return (
      <div className="flex flex-row flex-align-center">
        {contest.poolSettings &&
          !contestPoolSettingsValidationError &&
          standing.poolBalance > 0 && (
            <IconWithTooltip
              icon="pi pi-dollar"
              iconStyle={{ color: '#a5d6a7', marginRight: '.25em' }}
              tooltip={`Pool Winnings: ${formatMoney(
                standing.poolBalance,
                contest.poolSettings.currency
              )}`}
            />
          )}
        <UserAvatar email={standing.member} />
      </div>
    );
  };

  const balanceTemplate = (standing: ContestStanding) => {
    return renderContestStandingBalanceSummary(standing, contest);
  };

  const betsTemplate = (standing: ContestStanding) => {
    return renderContestStandingBetsSummary(standing);
  };

  return (
    <>
      {errorBets && <ErrorBlock message="Unable to load standings" />}
      {!errorBets && loadingBets && <LoadingBlock message="Loading standings..." />}
      {!errorBets && !loadingBets && standings && (
        <>
          <DataTable
            size="small"
            paginator
            paginatorTemplate={{
              layout: 'PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown'
            }}
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            value={standings}
          >
            <Column field="member" header="Member" body={userTemplate}></Column>
            <Column field="balance" header="Balance" body={balanceTemplate}></Column>
            <Column field="bets" header="Bets" body={betsTemplate}></Column>
          </DataTable>
        </>
      )}
    </>
  );
};
