import { useState } from 'react';
import { Bet, ParlayBet } from '../common/models/bet';
import { formatMoney } from '../utils/utils';
import { BetTypeIcon } from './BetTypeIcon';
import IconButtonWithTooltip from './IconButtonWithTooltip';
import { UserAvatar } from './UserAvatar';
import { Game } from '../common/models/game';
import { GameStatus } from '../common/models/game-status';
import { BetType } from '../common/models/bet-type';

const DEFAULT_COLLAPSED = false;

export interface BetPanelProps {
  bets: (Bet | ParlayBet | undefined)[];
  highlightUser?: string | undefined | null;
  game?: Game;
  collapsed?: boolean;
}

export const BetPanel = (props: BetPanelProps) => {
  const { bets, collapsed, game, highlightUser } = props;
  const activeBets = bets?.length ? bets.filter((bet) => bet) : [];
  const [isCollapsed, setIsCollapsed] = useState(collapsed ? collapsed : DEFAULT_COLLAPSED);

  const isBetCurrentlyWinning = (bet?: Bet | ParlayBet): boolean | undefined => {
    if (!bet || !game) {
      return undefined;
    }
    if (game.status === GameStatus.UPCOMING) {
      return undefined;
    }
    if (bet.betType === BetType.AWAY_TEAM_MONEYLINE) {
      return game.awayTeamScore > game.homeTeamScore;
    }
    if (bet.betType === BetType.HOME_TEAM_MONEYLINE) {
      return game.homeTeamScore > game.awayTeamScore;
    }
    if (bet.betType === BetType.AWAY_TEAM_SPREAD) {
      return game.awayTeamScore + bet.line > game.homeTeamScore;
    }
    if (bet.betType === BetType.HOME_TEAM_SPREAD) {
      return game.homeTeamScore + bet.line > game.awayTeamScore;
    }
    if (bet.betType === BetType.OVER) {
      return game.awayTeamScore + game.homeTeamScore > bet.line;
    }
    if (bet.betType === BetType.UNDER) {
      return game.awayTeamScore + game.homeTeamScore < bet.line;
    }
    return undefined;
  };

  if (isCollapsed) {
    return (
      <div
        style={{
          borderRadius: '4px',
          border: '1px solid #dee2e6',
          padding: '.5em .5em .5em .5em',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div>
          {activeBets.length} Bets (
          {formatMoney(
            activeBets.map((bet) => (bet && bet.amount ? bet.amount : 0)).reduce((a, b) => a + b, 0)
          )}
          )
        </div>
        <div>
          <IconButtonWithTooltip
            icon="pi-plus"
            tooltip="Expand"
            onClick={() => setIsCollapsed(false)}
          ></IconButtonWithTooltip>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div
          style={{
            borderRadius: '4px',
            border: '1px solid #dee2e6',
            padding: '.5em .5em .5em .5em'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: 'solid silver 1px',
              paddingBottom: '.5em',
              marginBottom: '.5em'
            }}
          >
            <div>
              {activeBets.length} Bets (
              {formatMoney(
                activeBets
                  .map((bet) => (bet && bet.amount ? bet.amount : 0))
                  .reduce((a, b) => a + b, 0)
              )}
              )
            </div>
            <div>
              <IconButtonWithTooltip
                icon="pi-minus"
                tooltip="Collapse"
                onClick={() => setIsCollapsed(true)}
              ></IconButtonWithTooltip>
            </div>
          </div>
          <div>
            {activeBets
              .sort((betA, betB) => {
                const amountA = betA?.amount ? betA?.amount : 0;
                const amountB = betB?.amount ? betB?.amount : 0;
                return amountB - amountA;
              })
              .map((bet) => {
                return (
                  <div
                    key={bet?.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '.25em .25em .25em .25em',
                      margin: '.25em 0em .25em 0em',
                      backgroundColor:
                        isBetCurrentlyWinning(bet) === undefined
                          ? 'unset'
                          : isBetCurrentlyWinning(bet)
                          ? '#d4edda'
                          : '#f8d7da',
                      border: highlightUser === bet?.owner ? '2px solid #386dd1' : 'unset'
                    }}
                  >
                    <div style={{ padding: '0em .25em 0em 0em' }}>
                      <UserAvatar email={bet?.owner}></UserAvatar>
                    </div>
                    <div style={{ padding: '0em .25em 0em .25em' }}>
                      {(bet as ParlayBet)?.betId
                        ? 'Parlay'
                        : bet?.amount
                        ? formatMoney(bet?.amount, bet?.currency)
                        : 'N/A'}
                    </div>
                    <div style={{ padding: '0em .25em 0em .25em' }}>
                      <BetTypeIcon betType={bet?.betType} game={game}></BetTypeIcon>
                    </div>
                    <div style={{ padding: '0em .25em 0em 0em' }}>{bet?.line ? bet?.line : ''}</div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
};
