import { Checkbox } from 'primereact/checkbox';
import { Panel } from 'primereact/panel';
import { useEffect, useState } from 'react';
import useAuth, { AuthContextType } from '../auth/auth';
import { UserProfile } from '../common/models/user-profile';
import { ErrorBlock } from '../components/ErrorBlock';
import IconWithTooltip from '../components/IconWithTooltip';
import { LoadingBlock } from '../components/LoadingBlock';
import PageHeader from '../components/PageHeader';
import { UserAvatar } from '../components/UserAvatar';
import {
  isUserActiveAdmin,
  isUserActiveCreator,
  isUserActiveSubscriber
} from '../utils/admin-utils';
import { getUserProfileForAuthContext, updateUserProfile } from '../utils/user-utils';

export const MyAccount = () => {
  const auth = useAuth();

  const [loadingUserInfo, setLoadingUserInfo] = useState(true);
  const [errorUserInfo, setErrorUserInfo] = useState<string | undefined>();

  const [isAdmin, setIsAdmin] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(undefined);

  const getUserInfo = async (auth: AuthContextType): Promise<void> => {
    try {
      setIsAdmin(await isUserActiveAdmin(auth));
    } catch (error) {
      setIsAdmin(false);
    }
    try {
      setIsCreator(await isUserActiveCreator(auth));
    } catch (error) {
      setIsCreator(false);
    }
    try {
      setIsSubscriber(await isUserActiveSubscriber(auth));
    } catch (error) {
      setIsSubscriber(false);
    }
    try {
      const profile = await getUserProfileForAuthContext(auth);
      setUserProfile(profile);
    } catch (error) {
      setUserProfile(undefined);
    }
  };

  useEffect(() => {
    setLoadingUserInfo(true);
    setErrorUserInfo(undefined);

    if (!auth?.user?.user?.email?.length) {
      setErrorUserInfo('Unable to verify your identify.');
      setLoadingUserInfo(false);
      return;
    }

    getUserInfo(auth)
      .then(() => {
        setLoadingUserInfo(false);
      })
      .catch((error) => {
        console.error('Error getting user info', error);
        setErrorUserInfo('Unable to confirm your subscription status.');
        setLoadingUserInfo(false);
      });
  }, [auth?.user?.user?.email]);

  return (
    <>
      <PageHeader title="Account Settings" />
      {errorUserInfo && <ErrorBlock message={errorUserInfo} />}
      {!errorUserInfo && loadingUserInfo && <LoadingBlock />}
      {!errorUserInfo && !loadingUserInfo && (
        <>
          <Panel header="Profile">
            <div className="flex flex-row flex-align-center flex-justify-between pt-2 pb-2 pl-1 pr-1 highlight">
              <div className="fw-bold">Name</div>
              <div style={{ textAlign: 'right' }}>
                {auth?.user?.user?.displayName?.length
                  ? auth.user.user.displayName
                  : 'Invalid Name'}
              </div>
            </div>
            <div className="flex flex-row flex-align-center flex-justify-between pt-2 pb-2 pl-1 pr-1 highlight">
              <div className="fw-bold">Avatar</div>
              <div style={{ textAlign: 'right' }}>
                <UserAvatar />
              </div>
            </div>
            <div className="flex flex-row flex-align-center flex-justify-between pt-2 pb-2 pl-1 pr-1 highlight">
              <div className="fw-bold">Email Address</div>
              <div style={{ textAlign: 'right' }}>
                {auth?.user?.user?.email?.length ? auth.user.user.email : 'Invalid Email'}
              </div>
            </div>
            <div className="flex flex-row flex-align-center flex-justify-between pt-2 pb-2 pl-1 pr-1 highlight">
              <div className="fw-bold">Signed In Via</div>
              <div style={{ textAlign: 'right' }}>
                {auth?.user?.providerId?.length ? auth.user.providerId : 'Invalid Sign In Provider'}
              </div>
            </div>
          </Panel>
          <Panel header="Subscription" className="pt-2">
            {!isSubscriber && (
              <>
                <div className="p-2">
                  <ErrorBlock message="You do not have a valid subscription." />
                </div>
              </>
            )}
            {isSubscriber && (
              <>
                <div className="flex flex-row flex-align-center flex-justify-between pt-2 pb-2 pl-1 pr-1 highlight">
                  <div className="fw-bold">Status</div>
                  <div style={{ textAlign: 'right' }}>Active</div>
                </div>
                <div className="flex flex-row flex-align-center flex-justify-between pt-2 pb-2 pl-1 pr-1 highlight">
                  <div className="fw-bold">Level</div>
                  <div style={{ textAlign: 'right' }}>
                    {isAdmin && 'Admin'}
                    {!isAdmin && isCreator && 'Creator'}
                    {!isAdmin && !isCreator && 'Basic'}
                  </div>
                </div>
              </>
            )}
          </Panel>
          <Panel header="Notification Preferences" className="pt-2">
            {loadingUserInfo && <LoadingBlock message="Loading preferences..." />}
            {!loadingUserInfo && !userProfile && (
              <ErrorBlock message="Unable to load preferences." />
            )}
            {!loadingUserInfo && userProfile && (
              <>
                <form>
                  <div className="flex flex-row flex-align-center flex-justify-between pt-2 pb-2 pl-1 pr-1">
                    <div className="fw-bold">Notification Type</div>
                    <div className="fw-bold">Email</div>
                  </div>
                  <div className="flex flex-row flex-align-center flex-justify-between pt-2 pb-2 pl-1 pr-1 highlight">
                    <div className="flex flex-row flex-align-center">
                      <span>Contest Invite</span>
                      <IconWithTooltip
                        icon="pi pi-question-circle"
                        iconStyle={{ marginLeft: '0.5rem' }}
                        tooltip="Get a notification when you are invited to a new contest"
                      />
                    </div>
                    <div>
                      <Checkbox
                        id="contest-invite-email"
                        name="contest-invite-email"
                        checked={
                          userProfile?.notificationPreferences?.contestInviteEmailActive
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          userProfile.notificationPreferences.contestInviteEmailActive = e.checked;
                          updateUserProfile(userProfile)
                            .then((updatedProfile) => {
                              setUserProfile(updatedProfile);
                            })
                            .catch((e) => {
                              console.error('Error updating user profile', e);
                            });
                        }}
                      ></Checkbox>
                    </div>
                  </div>
                  <div className="flex flex-row flex-align-center flex-justify-between pt-2 pb-2 pl-1 pr-1 highlight">
                    <div className="flex flex-row flex-align-center">
                      <span>Contest Completed</span>
                      <IconWithTooltip
                        icon="pi pi-question-circle"
                        iconStyle={{ marginLeft: '0.5rem' }}
                        tooltip="Get a notification when a contest you are a member of completes"
                      />
                    </div>
                    <div>
                      <Checkbox
                        id="contest-closed-email"
                        name="contest-closed-email"
                        checked={
                          userProfile?.notificationPreferences?.contestClosedEmailActive
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          userProfile.notificationPreferences.contestClosedEmailActive = e.checked;
                          updateUserProfile(userProfile)
                            .then((updatedProfile) => {
                              setUserProfile(updatedProfile);
                            })
                            .catch((e) => {
                              console.error('Error updating user profile', e);
                            });
                        }}
                        disabled={true}
                      ></Checkbox>
                    </div>
                  </div>
                  <div className="flex flex-row flex-align-center flex-justify-between pt-2 pb-2 pl-1 pr-1 highlight">
                    <div className="flex flex-row flex-align-center">
                      <span>Bet Completed</span>
                      <IconWithTooltip
                        icon="pi pi-question-circle"
                        iconStyle={{ marginLeft: '0.5rem' }}
                        tooltip="Get a notification when a bet you placed completes"
                      />
                    </div>
                    <div>
                      <Checkbox
                        id="bet-closed-email"
                        name="bet-closed-email"
                        checked={
                          userProfile?.notificationPreferences?.betClosedEmailActive ? true : false
                        }
                        onChange={(e) => {
                          userProfile.notificationPreferences.betClosedEmailActive = e.checked;
                          updateUserProfile(userProfile)
                            .then((updatedProfile) => {
                              setUserProfile(updatedProfile);
                            })
                            .catch((e) => {
                              console.error('Error updating user profile', e);
                            });
                        }}
                        disabled={true}
                      ></Checkbox>
                    </div>
                  </div>
                </form>
              </>
            )}
          </Panel>
        </>
      )}
    </>
  );
};
