import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { AuthContextType } from '../auth/auth';

export const isDev = () => {
  const isDev = process.env.NODE_ENV === 'development';
  return isDev;
};

export const getUniqueListByKey = (list: any[], key: string) => {
  const listItemsByKey = list.reduce((acc, item) => {
    const itemKey = item[key];
    if (!acc[itemKey]) {
      acc[itemKey] = [];
    }
    acc[itemKey].push(item);
    return acc;
  }, {});
  const uniqueListItems = Object.keys(listItemsByKey).map((key) => listItemsByKey[key][0]);
  return uniqueListItems;
};

export const formatMoney = (amount: number, currency = 'USD'): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2
  });
  return formatter.format(amount);
};

export const formatReallyShortTimestamp = (timestamp: Timestamp): string => {
  if (!timestamp) {
    return '';
  }
  return DateTime.fromJSDate(timestamp.toDate()).toFormat('MM/dd h:mm a');
};

export const formatShortDatestamp = (timestamp: Timestamp): string => {
  if (!timestamp) {
    return '';
  }
  return DateTime.fromJSDate(timestamp.toDate()).toFormat('MM/dd');
};

export const formatTimestamp = (timestamp: Timestamp, format = DateTime.DATE_MED): string => {
  if (format == DateTime.DATETIME_SHORT) {
    return formatReallyShortTimestamp(timestamp);
  }

  if (!timestamp) {
    return '';
  }
  return DateTime.fromJSDate(timestamp.toDate()).toLocaleString(format);
};

export const generateAvatarInitialsForAuthUser = (
  auth: AuthContextType,
  field: 'name' | 'email' = 'name'
): string | undefined => {
  if (field === 'name') {
    if (!auth?.user?.user?.displayName?.length) {
      return;
    }
    return generateInitialsForName(auth.user.user.displayName);
  } else {
    if (!auth?.user?.user?.email?.length) {
      return;
    }
    return generateAvatarInitialsForEmail(auth.user.user.email);
  }
};

export const generateInitialsForName = (name: string): string | undefined => {
  if (!name?.length) {
    return;
  }

  const words = name.split(' ');
  if (words?.length) {
    return words.map((word) => word[0]).join('');
  }

  return name[0];
};

export const generateAvatarInitialsForDisplayName = (name: string | null | undefined): string => {
  if (!name?.length) {
    return '?';
  }

  const wordsBySpace = name.trim().split(' ');
  if (wordsBySpace?.length) {
    if (wordsBySpace.length >= 2) {
      return `${wordsBySpace[0][0]}${wordsBySpace[wordsBySpace.length - 1][0]}`;
    } else {
      return wordsBySpace[0][0];
    }
  }

  return name[0];
};

export const generateAvatarInitialsForEmail = (email: string | null | undefined): string => {
  if (!email?.length) {
    return '?';
  }

  const pieces = email.split('@');

  if (pieces.length !== 2) {
    return '?';
  }

  const handle = pieces[0];
  if (!handle?.length) {
    return '?';
  }

  const wordsByDot = handle.split('.');
  if (wordsByDot?.length) {
    if (wordsByDot.length >= 2) {
      return `${wordsByDot[0][0]}${wordsByDot[wordsByDot.length - 1][0]}`;
    } else {
      return wordsByDot[0][0];
    }
  }

  const wordsByUnderscore = handle.split('_');
  if (wordsByUnderscore?.length) {
    if (wordsByUnderscore.length >= 2) {
      return `${wordsByUnderscore[0][0]}${wordsByUnderscore[wordsByUnderscore.length - 1][0]}`;
    } else {
      return wordsByUnderscore[0][0];
    }
  }

  return handle[0];
};

export const getOrdinal = (n: number) => {
  return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
};
