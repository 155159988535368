import { useEffect, useState } from 'react';
import { Bet, ParlayBet } from '../common/models/bet';
import { BetStatus } from '../common/models/bet-status';
import { BetType } from '../common/models/bet-type';
import { Contest } from '../common/models/contest';
import { getBetsForContestByStatus, getParlayBetsForContestByStatus } from '../utils/bet-utils';
import { InfoBlock } from './InfoBlock';
import { LiveGameActionViewer } from './LiveGameActionViewer';
import { LoadingBlock } from './LoadingBlock';

const DEFAULT_REFRESH_FREQUENCY_MS = 60000;

export interface LiveGameActionViewerListProps {
  contest: Contest;
  refreshFrequencyMs?: number;
}

export const LiveGameActionViewerList = (props: LiveGameActionViewerListProps) => {
  const { contest, refreshFrequencyMs } = props;
  const activeRefreshFrequency = refreshFrequencyMs
    ? refreshFrequencyMs
    : DEFAULT_REFRESH_FREQUENCY_MS;

  const [loadingBets, setLoadingBets] = useState(true);
  const [loadingParlayBets, setLoadingParlayBets] = useState(true);
  const [errorBets, setErrorBets] = useState<string | undefined>();
  const [errorParlayBets, setErrorParlayBets] = useState<string | undefined>();
  const [bets, setBets] = useState<Bet[]>([]);
  const [parlayBets, setParlayBets] = useState<ParlayBet[]>([]);
  const [gameIds, setGameIds] = useState<(string | undefined)[]>([]);

  const loadGamesForParlayBets = () => {
    setLoadingParlayBets(true);
    setErrorParlayBets(undefined);
    getParlayBetsForContestByStatus(contest, [BetStatus.LOCKED])
      .then((parlayBets) => {
        setParlayBets(parlayBets);
        setLoadingParlayBets(false);
      })
      .catch((error) => {
        console.error('Unable to load parlay bets.', error);
        setErrorParlayBets('Unable to load parlay bets for this contest.');
        setLoadingParlayBets(false);
      });
  };

  const loadGames = () => {
    setLoadingBets(true);
    setErrorBets(undefined);
    getBetsForContestByStatus(contest, [BetStatus.LOCKED])
      .then((bets) => {
        setBets(bets);
        if (bets?.length) {
          setGameIds(Array.from(new Set(bets.map((bet) => bet.gameId).filter((gameId) => gameId))));
        } else {
          setGameIds([]);
        }
        setLoadingBets(false);
      })
      .catch((error) => {
        console.error('Unable to load bets.', error);
        setErrorBets('Unable to load bets for this contest.');
        setLoadingBets(false);
      });
  };

  useEffect(() => {
    // console.log('Loading initial game status for contest', contest.id);
    loadGames();
    loadGamesForParlayBets();

    // console.log('Scheduling game updates for contest', contest.id);
    const gameUpdateInterval = setInterval(() => {
      // console.log('Loading scheduled game status updates for contest', contest.id);
      loadGames();
      loadGamesForParlayBets();
    }, activeRefreshFrequency);

    return () => {
      // console.log('Cancelling game updates for contest', contest.id);
      gameUpdateInterval && clearInterval(gameUpdateInterval);
    };
  }, []);

  const gameViewers = gameIds
    .filter((gameId) => gameId)
    .sort((gameIdA, gameIdB) => {
      const betsA = bets ? bets.filter((bet) => bet.gameId === gameIdA) : [];
      const betsB = bets ? bets.filter((bet) => bet.gameId === gameIdB) : [];
      return betsB.length - betsA.length;
    })
    .map((gameId) => {
      const allBets: (Bet | ParlayBet)[] = [];
      bets
        .filter((bet) => bet.gameId === gameId && bet.betType !== BetType.PARLAY)
        .forEach((bet) => allBets.push(bet));
      parlayBets
        .filter((parlayBet) => parlayBet.gameId === gameId)
        .forEach((parlayBet) => allBets.push(parlayBet));
      return (
        <LiveGameActionViewer
          key={gameId}
          gameId={gameId}
          bets={allBets}
          refreshFrequencyMs={activeRefreshFrequency}
        />
      );
    });

  if (errorBets || errorParlayBets) {
    return <></>;
  } else if (loadingBets || loadingParlayBets) {
    return <LoadingBlock></LoadingBlock>;
  } else if (gameIds?.length) {
    return <>{gameViewers}</>;
  } else {
    return (
      <InfoBlock message="When there are games being played with bets against them you can follow the action live here!"></InfoBlock>
    );
  }
};
