export enum ContestStatus {
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
  COMPLETE = 'COMPLETE'
}

export enum ContestStatusName {
  ACTIVE = 'Active',
  LOCKED = 'Locked',
  COMPLETE = 'Complete'
}

export enum ContestStatusIcon {
  ACTIVE = 'pi-spin pi-spinner',
  LOCKED = 'pi-lock',
  COMPLETE = 'pi-check-circle'
}

export const getContestStatusName = (status: ContestStatus): string => {
  if (!status) {
    return 'Invalid';
  }
  return ContestStatusName[status as keyof typeof ContestStatusName];
};

export const getContestStatusIcon = (status: ContestStatus): string => {
  if (!status) {
    return 'pi-question-circle';
  }
  return ContestStatusIcon[status as keyof typeof ContestStatusIcon];
};
