import { Bet } from '../common/models/bet';
import { BetType } from '../common/models/bet-type';
import { Contest } from '../common/models/contest';
import { ErrorBlock } from './ErrorBlock';

export interface SingleGameBetDetailsProps {
  contest: Contest;
  bet: Bet;
}

export const SingleGameBetDetails = (props: SingleGameBetDetailsProps) => {
  const { contest, bet } = props;

  if (!contest || !bet) {
    return <ErrorBlock message="Unable to load bet details" />;
  }
  if (bet.betType === BetType.PARLAY) {
    return <ErrorBlock message="Unable to load bet details, this bet is not a parlay" />;
  } else {
    return <></>;
  }
};
