import { Toast } from 'primereact/toast';
import { createContext, ReactNode, useContext, useRef } from 'react';

export interface GlobalToastContextType {
  sendToast: (
    level: 'success' | 'info' | 'warn' | 'error' | undefined,
    summary: string,
    message: string,
    life?: number
  ) => void;
}

const GlobalToastContext = createContext<GlobalToastContextType>({} as GlobalToastContextType);

export function GlobalToastProvider({ children }: { children: ReactNode }): JSX.Element {
  const toast = useRef<Toast>(null);

  const value = {
    sendToast: (
      level: 'success' | 'info' | 'warn' | 'error' | undefined,
      summary: string,
      message: string,
      life: number
    ) => {
      toast.current?.show({
        severity: level,
        summary,
        detail: message,
        life: life ? life : 3000
      });
    }
  } as GlobalToastContextType;

  return (
    <>
      <Toast ref={toast} position="bottom-right" />
      <GlobalToastContext.Provider value={value}>{children}</GlobalToastContext.Provider>
    </>
  );
}

export default function useGlobalToasts(): GlobalToastContextType {
  return useContext(GlobalToastContext);
}
