import { useEffect, useState } from 'react';
import { Contest } from '../common/models/contest';
import { ContestStanding } from '../common/models/contest-standing';
import { Season } from '../common/models/season';
import { LoadingBlock } from './LoadingBlock';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { validatePoolSettings } from '../utils/contest-utils';
import IconWithTooltip from './IconWithTooltip';
import { formatMoney } from '../utils/utils';
import { UserAvatar } from './UserAvatar';
import { ContestStatus } from '../common/models/contest-status';

export interface SeasonStandingsProps {
  season: Season;
  contests: Contest[];
  contestStandings: { [key: string]: ContestStanding[] | undefined };
}

interface MemberStanding {
  member: string;
  points: number;
  balance: number;
  numBets: number;
  numWinningBets: number;
  numContests: number;
}

export const SeasonStandings = (props: SeasonStandingsProps) => {
  const { season, contests, contestStandings } = props;
  const [loading, setLoading] = useState(true);
  const [memberStandings, setMemberStandings] = useState<MemberStanding[]>([]);
  const [poolSettingsValidationError] = useState(validatePoolSettings(season));

  useEffect(() => {
    setLoading(true);

    const memberPoints: { [key: string]: number } = {};
    const memberBalance: { [key: string]: number } = {};
    const memberContests: { [key: string]: number } = {};
    const memberWinningBets: { [key: string]: number } = {};
    const memberBets: { [key: string]: number } = {};
    const memberStandingsTemp: { [key: string]: MemberStanding } = {};

    const includedContestStandings: ContestStanding[][] = [];
    Object.keys(contestStandings).forEach((contestId) => {
      const standings = contestStandings[contestId];
      const contest = contests.find((contest) => contest.id === contestId);
      if (standings && contest && contest.status === ContestStatus.COMPLETE) {
        includedContestStandings.push(standings);
      }
    });

    includedContestStandings.forEach((standings) => {
      standings?.forEach((standing) => {
        if (standing.member) {
          let memberStandingIndex: number | undefined = undefined;
          standings?.forEach((standingTemp, index) => {
            if (standingTemp.member === standing.member.toLowerCase()) {
              memberStandingIndex = index;
            }
          });

          if (!memberContests[standing.member]) {
            memberContests[standing.member] = 0;
          }
          memberContests[standing.member] += 1;

          if (!memberPoints[standing.member]) {
            memberPoints[standing.member] = 0;
          }
          const points =
            memberStandingIndex !== undefined ? standings.length - memberStandingIndex : 0;
          memberPoints[standing.member] += points;

          if (!memberBets[standing.member]) {
            memberBets[standing.member] = 0;
          }
          memberBets[standing.member] += standing.numBets;

          if (!memberWinningBets[standing.member]) {
            memberWinningBets[standing.member] = 0;
          }
          memberWinningBets[standing.member] += standing.numWinningBets;

          if (!memberBalance[standing.member]) {
            memberBalance[standing.member] = 0;
          }
          memberBalance[standing.member] += standing.balance;
        }
      });
    });

    season.activeMembers?.forEach((member) => {
      const memberStanding: MemberStanding = {
        member: member,
        points: memberPoints[member] || 0,
        balance: memberBalance[member] || 0,
        numContests: memberContests[member] || 0,
        numBets: memberBets[member] || 0,
        numWinningBets: memberWinningBets[member] || 0
      };
      memberStandingsTemp[member] = memberStanding;
    });

    const sortedStandings = Object.values(memberStandingsTemp).sort((a, b) => {
      if (a.points === b.points) {
        if (a.balance === b.balance) {
          const aWinPercentage = a.numBets ? a.numWinningBets / a.numBets : 0;
          const bWinPercentage = b.numBets ? b.numWinningBets / b.numBets : 0;
          if (aWinPercentage === bWinPercentage) {
            if (a.numBets === b.numBets) {
              return a.member.localeCompare(b.member);
            }
            return b.numBets - a.numBets;
          }
          return bWinPercentage - aWinPercentage;
        }
        return b.balance - a.balance;
      }
      return b.points - a.points;
    });

    setMemberStandings(sortedStandings);
    setLoading(false);
  }, [season, contests, contestStandings]);

  const userTemplate = (standing: ContestStanding) => {
    return (
      <div className="flex flex-row flex-align-center">
        {season.poolSettings && !poolSettingsValidationError && standing.poolBalance > 0 && (
          <IconWithTooltip
            icon="pi pi-dollar"
            iconStyle={{ color: '#a5d6a7', marginRight: '.25em' }}
            tooltip={`Pool Winnings: ${formatMoney(
              standing.poolBalance,
              season.poolSettings.currency
            )}`}
          />
        )}
        <UserAvatar email={standing.member} />
      </div>
    );
  };

  const balanceTemplate = (standing: ContestStanding) => {
    return <>{formatMoney(standing.balance, season.poolSettings.currency)}</>;
  };

  if (loading) {
    return <LoadingBlock message="Loading season standings..." />;
  } else {
    return (
      <>
        <DataTable
          size="small"
          paginator
          paginatorTemplate={{
            layout: 'PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown'
          }}
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={memberStandings}
        >
          <Column field="member" header="Member" body={userTemplate}></Column>
          <Column field="points" header="Points"></Column>
          <Column field="balance" header="Balance" body={balanceTemplate}></Column>
          <Column field="numContests" header="Contests"></Column>
        </DataTable>
      </>
    );
  }
};
