import {
  collection,
  deleteDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  startAfter,
  Timestamp,
  where
} from 'firebase/firestore';
import { Bet } from '../common/models/bet';
import { Game } from '../common/models/game';
import { GameOdds } from '../common/models/game-odds';
import { GameScore } from '../common/models/game-score';
import { GameStatus } from '../common/models/game-status';
import { Sport } from '../common/models/sport';
import { db } from '../firebase';

export const getCurrentGameStatus = (game: Game): GameStatus => {
  if (!game || !game.status) {
    return GameStatus.UNAVAILABLE;
  }
  return game.status === GameStatus.UPCOMING && game.startTime <= Timestamp.now()
    ? GameStatus.IN_PROGRESS
    : game.status;
};

export const gameHasValidLines = (game: Game): boolean => {
  if (!game) {
    return false;
  }
  if (game.overUnderLine !== undefined && game.overUnderLine !== null) {
    return true;
  }
  if (game.homeTeamMoneyline !== undefined && game.homeTeamMoneyline !== null) {
    return true;
  }
  if (game.awayTeamMoneyline !== undefined && game.awayTeamMoneyline !== null) {
    return true;
  }
  if (game.homeTeamSpread !== undefined && game.homeTeamSpread !== null) {
    return true;
  }
  if (game.awayTeamSpread !== undefined && game.awayTeamSpread !== null) {
    return true;
  }
  return false;
};

export const gameHasFullValidLines = (game: Game): boolean => {
  if (!game) {
    return false;
  }
  if (game.overUnderLine === undefined || game.overUnderLine === null) {
    return false;
  }
  if (game.homeTeamMoneyline === undefined || game.homeTeamMoneyline === null) {
    return false;
  }
  if (game.awayTeamMoneyline === undefined || game.awayTeamMoneyline === null) {
    return false;
  }
  if (game.homeTeamSpread === undefined || game.homeTeamSpread === null) {
    return false;
  }
  if (game.awayTeamSpread === undefined || game.awayTeamSpread === null) {
    return false;
  }
  return true;
};

export const getGameOddsHistory = async (gameId: string): Promise<GameOdds[]> => {
  const gamesRef = collection(db, 'gameOdds');
  const q = query(gamesRef, where('gameId', '==', gameId), orderBy('updatedTime', 'asc'));
  const querySnapshot = await getDocs(q);
  const gameOdds: GameOdds[] = [];
  querySnapshot.forEach((doc) => {
    const gameOdd = doc.data() as GameOdds;
    gameOdds.push(gameOdd);
  });
  return gameOdds;
};

export const getGameScoreHistory = async (gameId: string): Promise<GameScore[]> => {
  const gamesRef = collection(db, 'gameScores');
  const q = query(gamesRef, where('gameId', '==', gameId), orderBy('updatedTime', 'asc'));
  const querySnapshot = await getDocs(q);
  const gameScores: GameScore[] = [];
  querySnapshot.forEach((doc) => {
    const gameScore = doc.data() as GameScore;
    gameScores.push(gameScore);
  });
  return gameScores;
};

export const getGame = async (gameId: string | undefined): Promise<Game | undefined> => {
  if (!gameId?.length) {
    return undefined;
  }
  const gamesRef = collection(db, 'games');
  const q = query(gamesRef, where('id', '==', gameId));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    return undefined;
  }
  return querySnapshot.docs[0].data() as Game;
};

export const deleteGame = async (gameId: string | undefined): Promise<void> => {
  if (!gameId?.length) {
    throw new Error('Game is not valid');
  }
  const game = await getGame(gameId);
  if (!game) {
    throw new Error('Game does not exist');
  }

  const gamesRef = collection(db, 'games');
  const betsRef = collection(db, 'bets');
  const gameOddsRef = collection(db, 'gameOdds');
  const gameScoresRef = collection(db, 'gameScores');

  const gameOddsQuery = query(gameOddsRef, where('gameId', '==', gameId));
  const gameScoresQuery = query(gameScoresRef, where('gameId', '==', gameId));
  const betsQuery = query(betsRef, where('gameId', '==', gameId));
  const gameQuery = query(gamesRef, where('id', '==', gameId));

  const gameOddsSnapshot = await getDocs(gameOddsQuery);
  if (!gameOddsSnapshot.empty) {
    console.log(`Deleting ${gameOddsSnapshot.size} game odds for game ${gameId}`);
    gameOddsSnapshot.docs.forEach((doc) => {
      deleteDoc(doc.ref);
    });
  }

  const gameScoresSnapshot = await getDocs(gameScoresQuery);
  if (!gameScoresSnapshot.empty) {
    console.log(`Deleting ${gameScoresSnapshot.size} game scores for game ${gameId}`);
    gameScoresSnapshot.docs.forEach((doc) => {
      deleteDoc(doc.ref);
    });
  }

  const betsSnapshot = await getDocs(betsQuery);
  if (!betsSnapshot.empty) {
    console.log(`Deleting ${betsSnapshot.size} bets for game ${gameId}`);
    betsSnapshot.docs.forEach((doc) => {
      deleteDoc(doc.ref);
    });
  }

  const gameSnapshot = await getDocs(gameQuery);
  if (!gameSnapshot.empty) {
    console.log(`Deleting game ${gameId}`);
    gameSnapshot.docs.forEach((doc) => {
      deleteDoc(doc.ref);
    });
  }
};

export const getGamesWhereSportAndStatus = async (
  sport?: Sport,
  statuses?: GameStatus[],
  pageSize = 5,
  startAfterDoc?: QueryDocumentSnapshot,
  sortBy = 'startTime',
  sort: 'asc' | 'desc' = 'asc'
): Promise<QuerySnapshot> => {
  return getGamesWhereSportAndStatusAndDate(
    sport,
    statuses,
    undefined,
    undefined,
    pageSize,
    startAfterDoc,
    sortBy,
    sort
  );
};

export const getGamesByIds = async (gameIds: (string | undefined)[]): Promise<Game[]> => {
  if (!gameIds?.length) {
    return [];
  }

  const uniqueGameIds = [...new Set(gameIds.filter((gameId) => gameId?.length))];
  const gamesRef = collection(db, 'games');

  // Break up the query into chunks of 10
  const queries = [];
  const chunkSize = 10;
  for (let i = 0; i < uniqueGameIds.length; i += chunkSize) {
    const chunk = uniqueGameIds.slice(i, i + chunkSize);
    const queryConstraints = [];
    queryConstraints.push(where('id', 'in', chunk));
    const q = query(gamesRef, ...queryConstraints);
    queries.push(q);
  }

  const allGames: Game[] = [];
  for (const q of queries) {
    await getDocs(q).then((querySnapshot) => {
      const games: Game[] = [];
      querySnapshot.forEach((doc) => {
        const game = doc.data() as Game;
        games.push(game);
      });
      allGames.push(...games);
    });
  }
  return allGames;
};

export const getGamesForBets = async (bets: Bet[]): Promise<Game[]> => {
  if (!bets?.length) {
    return [];
  }
  const gameIds = bets.map((bet) => bet.gameId);
  return getGamesByIds(gameIds);
};

export const getAllGamesWhereSportAndTeamsAndStatusAndDate = async (
  sport?: Sport,
  teams?: { [key: string]: string[] },
  statuses?: GameStatus[],
  startDate?: Timestamp,
  endDate?: Timestamp
): Promise<Game[]> => {
  const gamesRef = collection(db, 'games');

  // Break up the query into chucks of 10 teams
  const teamsForSport = sport ? teams?.[sport] : undefined;

  if (!teamsForSport?.length) {
    const queryConstraints = [];
    if (sport && sport !== Sport.ALL) {
      queryConstraints.push(where('sport', '==', sport));
    }
    if (statuses?.length) {
      queryConstraints.push(where('status', 'in', statuses));
    }
    if (startDate && endDate) {
      queryConstraints.push(where('startTime', '>=', startDate));
      queryConstraints.push(where('startTime', '<=', endDate));
    }
    const q = query(gamesRef, ...queryConstraints);
    const querySnapshot = await getDocs(q);
    const games: Game[] = [];
    querySnapshot.forEach((doc) => {
      const game = doc.data() as Game;
      games.push(game);
    });
    return games;
  }

  const queries = [];
  const chunkSize = 10;
  for (let i = 0; i < teamsForSport?.length; i += chunkSize) {
    const chunk = teamsForSport?.slice(i, i + chunkSize);
    const queryConstraints = [];
    if (sport && sport !== Sport.ALL) {
      queryConstraints.push(where('sport', '==', sport));
    }
    if (chunk?.length) {
      queryConstraints.push(where('teams', 'array-contains-any', chunk));
    }
    if (startDate && endDate) {
      queryConstraints.push(where('startTime', '>=', startDate));
      queryConstraints.push(where('startTime', '<=', endDate));
    }
    const q = query(gamesRef, ...queryConstraints);
    queries.push(q);
  }

  const allGames: Game[] = [];
  for (const q of queries) {
    await getDocs(q).then((querySnapshot) => {
      const games: Game[] = [];
      querySnapshot.forEach((doc) => {
        const game = doc.data() as Game;
        games.push(game);
      });
      allGames.push(...games);
    });
  }
  return allGames.filter((game) => {
    if (statuses?.length) {
      return statuses.includes(game.status);
    }
    return true;
  });
};

export const getGamesWhereSportAndStatusAndDate = async (
  sport?: Sport,
  statuses?: GameStatus[],
  startDate?: Timestamp,
  endDate?: Timestamp,
  pageSize = 5,
  startAfterDoc?: QueryDocumentSnapshot,
  sortBy = 'startTime',
  sort: 'asc' | 'desc' = 'asc'
): Promise<QuerySnapshot> => {
  const gamesRef = collection(db, 'games');
  const queryConstraints = [];
  if (sport && sport !== Sport.ALL) {
    queryConstraints.push(where('sport', '==', sport));
  }
  if (statuses?.length) {
    queryConstraints.push(where('status', 'in', statuses));
  }
  if (startDate && endDate) {
    queryConstraints.push(where('startTime', '>=', startDate));
    queryConstraints.push(where('startTime', '<=', endDate));
  }
  queryConstraints.push(orderBy(sortBy?.length ? sortBy : 'startTime', sort));
  queryConstraints.push(limit(pageSize));
  if (startAfterDoc) {
    queryConstraints.push(startAfter(startAfterDoc));
  }
  const q = query(gamesRef, ...queryConstraints);
  return getDocs(q);
};
