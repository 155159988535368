import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { LoadingBlock } from '../components/LoadingBlock';
import useGlobalToasts from '../hooks/global-toasts';
import { appVersion } from '../utils/app-utils';
import { getCurrentAppStateToken } from '../utils/auth-utils';
import useAuth from './auth';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const globalToasts = useGlobalToasts();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth?.user?.user?.email?.length) {
      getCurrentAppStateToken()
        .then((token) => {
          if (!isNaN(token.versionNumeric) && token.versionNumeric > appVersion) {
            globalToasts.sendToast(
              'warn',
              'New version available',
              'A new version of the application is available.  Please refresh the page.',
              3000
            );
          }

          if (!token.session?.length) {
            console.error('No current session appStateToken found for validation');
            auth.setAppStateTokenSession(null);
            auth.signout();
            navigate('/signin');
            return;
          }

          if (token.session === auth.appStateTokenSession) {
            setLoggedIn(true);
            setLoading(false);
          } else {
            globalToasts.sendToast(
              'info',
              'Session Expired',
              'Your session has expired. Please sign in again.',
              3000
            );
            auth.setAppStateTokenSession(token.session);
            auth.signout();
            navigate('/signin');
            return;
          }
        })
        .catch((error) => {
          console.error('Error retrieving the current session appStateToken for validation', error);
          auth.setAppStateTokenSession(null);
          auth.signout();
          navigate('/signin');
        });
    } else {
      setLoading(false);
    }
  });

  return (
    <>
      {loading && <LoadingBlock />}
      {!loading && (
        <>
          {!loggedIn && <Navigate to="/signin" state={{ from: location }} replace />}
          {loggedIn && children}
        </>
      )}
    </>
  );
};
