import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorBlock } from '../components/ErrorBlock';
import IconButtonWithTooltip from '../components/IconButtonWithTooltip';
import { LoadingBlock } from '../components/LoadingBlock';
import PageHeader from '../components/PageHeader';
import { SeasonContestHistory } from '../components/SeasonContestHistory';
import { SeasonInfo } from '../components/SeasonInfo';
import { getSeason } from '../utils/season-utils';
import { getContestStandings } from '../utils/contest-utils';
import { ContestStanding } from '../common/models/contest-standing';
import { Contest } from '../common/models/contest';
import { SeasonStandings } from '../components/SeasonStandings';

export const Season = () => {
  const [invalidSeasonId, setInvalidSeasonId] = useState(false);
  const [loadingSeason, setLoadingSeason] = useState(true);
  const [loadingContests, setLoadingContests] = useState(true);
  const [errorSeason, setErrorSeason] = useState<string | undefined>();
  const [season, setSeason] = useState<any | undefined>();
  const [contests, setContests] = useState<Contest[]>([]);
  const [errorContests, setErrorContests] = useState<string | undefined>();
  const [contestStandings, setContestStandings] = useState<{
    [key: string]: ContestStanding[] | undefined;
  }>({});
  const [showSeasonDetails, setShowSeasonDetails] = useState(false);

  let activeSeasonId: string | undefined;
  const params = useParams();
  if (params?.seasonId) {
    activeSeasonId = params.seasonId;
  }

  useEffect(() => {
    if (!activeSeasonId?.length) {
      setInvalidSeasonId(true);
      return;
    }
    setInvalidSeasonId(false);

    setLoadingSeason(true);
    setErrorSeason(undefined);
    getSeason(activeSeasonId)
      .then((season) => {
        setSeason(season);
        if (!season) {
          setErrorSeason('Unable to load season data.');
          setLoadingSeason(false);
        } else if (!season.contests?.length) {
          setErrorSeason('Season has no associated contests.');
          setLoadingSeason(false);
        } else {
          setLoadingContests(true);
          setLoadingSeason(false);
          const standingsRequests = season.contests.map((contest) => getContestStandings(contest));
          Promise.all(standingsRequests)
            .then((standings) => {
              const contestStandingsTemp: { [key: string]: ContestStanding[] | undefined } = {};
              season.contests?.forEach((contest, index) => {
                if (contest?.id?.length) {
                  contestStandingsTemp[contest.id] = standings[index];
                } else {
                  console.warn('Unable to load standings for invalid contest with no id');
                }
              });
              setContestStandings(contestStandingsTemp);
              setContests(
                season.contests
                  ? season.contests.sort((a, b) => b.end.toMillis() - a.end.toMillis())
                  : []
              );
              setLoadingContests(false);
            })
            .catch((error) => {
              console.error('Unable to load standings for contests', error);
              setErrorContests('Unable to load standings data');
              setContestStandings({});
              setContests([]);
              setLoadingContests(false);
            });
        }
      })
      .catch((error) => {
        setErrorSeason('Unable to load season data.');
        console.error('Unable to load season data.', error);
        setLoadingSeason(false);
      });
  }, []);

  const pageHeaderSubtitleTemplate = () => {
    const seasonInfo = (
      <IconButtonWithTooltip
        icon="pi pi-info-circle"
        tooltip="Season Info"
        tooltipOptions={{ position: 'bottom' }}
        onClick={() => {
          setShowSeasonDetails(true);
        }}
      />
    );

    return <div className="flex flex-row align-items-center">{seasonInfo}</div>;
  };

  if (errorSeason || invalidSeasonId) {
    return <ErrorBlock message={errorSeason} />;
  } else if (loadingSeason || loadingContests) {
    return <LoadingBlock />;
  } else if (errorContests || !contests?.length) {
    return (
      <>
        <PageHeader title={season.name} subtitleTemplate={pageHeaderSubtitleTemplate()} />
        <ErrorBlock message={'Season has no associated contests'} />
      </>
    );
  } else if (season) {
    return (
      <>
        <PageHeader title={season.name} subtitleTemplate={pageHeaderSubtitleTemplate()} />
        <div className="grid">
          <div className="col-12">
            <Panel header="Standings">
              <SeasonStandings
                season={season}
                contests={contests}
                contestStandings={contestStandings}
              />
            </Panel>
          </div>
          <div className="col-12">
            <Panel header="Contest History">
              <SeasonContestHistory
                season={season}
                contests={contests}
                contestStandings={contestStandings}
              />
            </Panel>
          </div>
        </div>

        <Dialog
          header="Season Info"
          visible={showSeasonDetails}
          style={{ width: '95vw' }}
          onHide={() => setShowSeasonDetails(false)}
        >
          <SeasonInfo season={season} />
        </Dialog>
      </>
    );
  } else {
    return <ErrorBlock message={'Season could not be loaded'} />;
  }
};
