import useAuth from '../auth/auth';
import { Bet } from '../common/models/bet';
import { BetStatus } from '../common/models/bet-status';
import { BetType } from '../common/models/bet-type';
import { Contest } from '../common/models/contest';
import { calculateWinAmountForBet } from '../utils/bet-utils';
import { formatMoney } from '../utils/utils';
import { BetStatusIcon } from './BetStatusIcon';
import { ErrorBlock } from './ErrorBlock';
import { ParlayDetails } from './ParlayDetails';
import { SingleGameBetDetails } from './SingleGameBetDetails';
import { UserAvatar } from './UserAvatar';

export interface BetDetailsProps {
  contest: Contest;
  bet: Bet;
}

export const BetDetails = (props: BetDetailsProps) => {
  const { contest, bet } = props;
  const auth = useAuth();
  const visible =
    bet &&
    bet.status &&
    bet.owner &&
    (bet.status !== BetStatus.PENDING ||
      bet.owner.toLowerCase() === auth?.user?.user?.email?.toLowerCase());

  if (!contest || !bet) {
    return <ErrorBlock message="Unable to load bet details" />;
  } else if (!visible) {
    return (
      <ErrorBlock message="Unable to load bet details, you cannot view this bet until it locks" />
    );
  } else {
    if (bet.betType === BetType.PARLAY) {
      return (
        <>
          <div className="grid align-items-center">
            <div className="col">Member</div>
            <div className="fw-bold col flex" style={{ justifyContent: 'right' }}>
              <UserAvatar email={bet.owner} />
            </div>
          </div>
          <div className="grid align-items-center">
            <div className="col">Status</div>
            <div className="fw-bold col flex" style={{ justifyContent: 'right' }}>
              <BetStatusIcon bet={bet} />
            </div>
          </div>
          <div className="grid align-items-center">
            <div className="col">Amount</div>
            <div className="fw-bold col text-right">
              {formatMoney(bet.amount, contest.initialBalanceCurrency)}
            </div>
          </div>
          <div className="grid align-items-center">
            <div className="col">Odds</div>
            <div className="fw-bold col text-right">
              {bet.price >= 0 ? '+' : ''}
              {Math.round(bet.price)}
            </div>
          </div>
          <div className="grid align-items-center">
            <div className="col">Payout</div>
            <div className="fw-bold col text-right">
              {formatMoney(
                calculateWinAmountForBet(bet) + bet.amount,
                contest.initialBalanceCurrency
              )}
            </div>
          </div>
          <ParlayDetails parlay={bet} contest={contest} />
        </>
      );
    } else {
      return (
        <>
          {' '}
          <div className="grid align-items-center">
            <div className="col">Member</div>
            <div className="fw-bold col flex" style={{ justifyContent: 'right' }}>
              <UserAvatar email={bet.owner} />
            </div>
          </div>
          <div className="grid align-items-center">
            <div className="col">Status</div>
            <div className="fw-bold col flex" style={{ justifyContent: 'right' }}>
              <BetStatusIcon bet={bet} />
            </div>
          </div>
          <div className="grid align-items-center">
            <div className="col">Amount</div>
            <div className="fw-bold col text-right">
              {formatMoney(bet.amount, contest.initialBalanceCurrency)}
            </div>
          </div>
          <div className="grid align-items-center">
            <div className="col">Odds</div>
            <div className="fw-bold col text-right">
              {bet.price >= 0 ? '+' : ''}
              {Math.round(bet.price)}
            </div>
          </div>
          <div className="grid align-items-center">
            <div className="col">Payout</div>
            <div className="fw-bold col text-right">
              {formatMoney(
                calculateWinAmountForBet(bet) + bet.amount,
                contest.initialBalanceCurrency
              )}
            </div>
          </div>
          <SingleGameBetDetails bet={bet} contest={contest} />
        </>
      );
    }
  }
};
