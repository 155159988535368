import { Panel } from 'primereact/panel';
import { useEffect, useState } from 'react';
import { Contest } from '../common/models/contest';
import { ErrorBlock } from '../components/ErrorBlock';
import { InfoBlock } from '../components/InfoBlock';
import { LoadingBlock } from '../components/LoadingBlock';
import PageHeader from '../components/PageHeader';
import { getAllContests } from '../utils/contest-utils';
import { ContestTable } from './ContestTable';

export const AdminContests = () => {
  const [contests, setContests] = useState<Contest[]>([]);
  const [loadingContests, setLoadingContests] = useState(true);
  const [errorContests, setErrorContests] = useState<string | undefined>();
  const [reloadContests, setReloadContests] = useState(true);

  useEffect(() => {
    if (reloadContests) {
      setLoadingContests(true);
      setErrorContests(undefined);
      getAllContests()
        .then((contests) => {
          setContests(contests.sort((a, b) => b.start.toMillis() - a.start.toMillis()));
          setLoadingContests(false);
          setReloadContests(false);
        })
        .catch((error) => {
          console.error('Unable to load contests.', error);
          setErrorContests('Unable to load contests.');
          setReloadContests(false);
        });
    }
  }, [reloadContests]);

  return (
    <>
      <PageHeader title="Contests Admin"></PageHeader>
      {errorContests && <ErrorBlock message="Unable to load contests."></ErrorBlock>}
      {!errorContests && loadingContests && (
        <LoadingBlock message="Loading contests..."></LoadingBlock>
      )}
      {!errorContests && !loadingContests && contests?.length === 0 && (
        <InfoBlock message="There are no contests to display."></InfoBlock>
      )}
      {!errorContests && !loadingContests && contests?.length !== 0 && (
        <Panel header="All Contests">
          <ContestTable
            contests={contests}
            onContestDeleted={() => setReloadContests(true)}
            allowDelete={true}
            allowEditing={true}
          />
        </Panel>
      )}
    </>
  );
};
