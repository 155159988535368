import { Menubar } from 'primereact/menubar';
import { ReactElement } from 'react';
import './PageHeader.css';

export interface PageHeaderProps {
  title: string;
  subtitle?: string;
  subtitleTemplate?: ReactElement | string;
  context?: ReactElement | string;
}

function PageHeader(props: PageHeaderProps) {
  const { title, subtitle, subtitleTemplate, context } = props;
  let start;
  if (subtitleTemplate !== undefined) {
    start = (
      <div className="page-header-title flex flex-row align-items-center">
        <div>{title}</div>
        <div className="ml-1">{subtitleTemplate}</div>
      </div>
    );
  } else if (subtitle?.length) {
    start = (
      <div className="page-header-title">
        <span>{title}</span>
        <span className="page-header-subtitle">{subtitle}</span>
      </div>
    );
  } else {
    start = <div className="page-header-title">{title}</div>;
  }
  const end = context ? <div className="page-header-context">{context}</div> : <></>;

  return (
    <div className="page-header">
      <Menubar start={start} end={end}></Menubar>
    </div>
  );
}

export default PageHeader;
