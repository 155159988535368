import { useEffect, useState } from 'react';
import { Team, getAbbreviation, getFullName, getShortName } from '../common/models/team';
import PageHeader from '../components/PageHeader';
import { ErrorBlock } from '../components/ErrorBlock';
import { LoadingBlock } from '../components/LoadingBlock';
import { SportName } from '../common/models/sport';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TeamAvatar } from '../components/TeamAvatar';
import useTeamDataManager from '../hooks/team-data-manager';

export const Teams = () => {
  const teamDataManager = useTeamDataManager();
  const [loading, setLoading] = useState<boolean>(true);
  const [error] = useState<string | undefined>();
  const [teamsBySport, setTeamsBySport] = useState<{ [sport: string]: Team[] }>({});

  const loadTeams = () => {
    setLoading(true);
    setTeamsBySport(teamDataManager.getTeamsBySport());
    setLoading(false);
  };

  useEffect(() => {
    loadTeams();
  }, []);

  return (
    <>
      <PageHeader title="Teams"></PageHeader>
      {error && <ErrorBlock message={error} />}
      {!error && loading && <LoadingBlock message="Loading teams..." />}
      {!error && !loading && (
        <>
          {Object.keys(teamsBySport)
            .sort((a, b) => {
              const sportNameA = SportName[a as keyof typeof SportName];
              const sportNameB = SportName[b as keyof typeof SportName];
              return sportNameA.localeCompare(sportNameB);
            })
            .map((sport) => {
              const sportName = SportName[sport as keyof typeof SportName];
              return (
                <div
                  key={sport}
                  style={{
                    marginBottom: '1em'
                  }}
                >
                  <Panel header={sportName} toggleable>
                    <DataTable
                      size="small"
                      paginator
                      paginatorTemplate={{
                        layout: 'PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown'
                      }}
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      value={teamsBySport[sport].sort((a, b) => a.name.localeCompare(b.name))}
                    >
                      <Column
                        header="Team Logo"
                        body={(team: Team) => <TeamAvatar team={team} />}
                      ></Column>
                      <Column
                        header="Display Name"
                        body={(team: Team) => getFullName(team)}
                      ></Column>
                      <Column
                        header="Short Name"
                        body={(team: Team) => getShortName(team)}
                      ></Column>
                      <Column
                        header="Abbreviation"
                        body={(team: Team) => getAbbreviation(team)}
                      ></Column>
                      <Column header="The Odds API Name" field="name"></Column>
                      <Column header="The Odds API ID" field="id"></Column>
                      <Column header="ESPN API Name" field="espnName"></Column>
                      <Column header="ESPN API ID" field="espnId"></Column>
                    </DataTable>
                  </Panel>
                </div>
              );
            })}
        </>
      )}
    </>
  );
};
