import { useEffect, useState } from 'react';
import { BetType } from '../common/models/bet-type';
import { Game } from '../common/models/game';
import { GameOdds } from '../common/models/game-odds';
import { getGameOddsHistory } from '../utils/game-utils';
import IconWithTooltip from './IconWithTooltip';
import { LoadingBlock } from './LoadingBlock';

interface LineMovementIndicatorProps {
  compareTo?: 'first' | 'previous';
  game: Game;
  betType: BetType;
}

const getOddsForBetType = (gameOdds: GameOdds, betType: BetType): number | undefined => {
  switch (betType) {
    case BetType.AWAY_TEAM_MONEYLINE:
      return gameOdds.awayTeamMoneyline;
    case BetType.HOME_TEAM_MONEYLINE:
      return gameOdds.homeTeamMoneyline;
    case BetType.AWAY_TEAM_SPREAD:
      return gameOdds.awayTeamSpread;
    case BetType.HOME_TEAM_SPREAD:
      return gameOdds.homeTeamSpread;
    case BetType.OVER:
      return gameOdds.overUnderLine;
    case BetType.UNDER:
      return gameOdds.overUnderLine;
    default:
      return undefined;
  }
};

export const LineMovementIndicator = (props: LineMovementIndicatorProps) => {
  const { game, betType, compareTo } = props;
  const activeCompareTo = compareTo ?? 'previous';

  const [loading, setLoading] = useState<boolean>(true);
  const [compareToValidOdds, setCompareToValidOdds] = useState<GameOdds | undefined>();
  const [lastValidOdds, setLastValidOdds] = useState<GameOdds | undefined>();

  const getOddsDifference = (): number | undefined => {
    if (!compareToValidOdds || !lastValidOdds) {
      return undefined;
    }
    const compareToOddsForBetType = getOddsForBetType(compareToValidOdds, betType);
    const lastOddsForBetType = getOddsForBetType(lastValidOdds, betType);
    if (compareToOddsForBetType == undefined || lastOddsForBetType == undefined) {
      return undefined;
    }
    return lastOddsForBetType - compareToOddsForBetType;
  };

  useEffect(() => {
    setLoading(true);
    getGameOddsHistory(game.id)
      .then((gameOddsHistory) => {
        if (gameOddsHistory?.length) {
          const sortedHistory = gameOddsHistory
            .filter((gameOdds: GameOdds) => {
              return getOddsForBetType(gameOdds, betType);
            })
            .sort((a, b) => {
              return a.updatedOddsTime.toMillis() - b.updatedOddsTime.toMillis();
            });

          let compareToValidOdds;
          if (activeCompareTo === 'first') {
            compareToValidOdds = sortedHistory?.length ? sortedHistory[0] : undefined;
          } else if (activeCompareTo === 'previous') {
            compareToValidOdds = sortedHistory?.length
              ? sortedHistory[sortedHistory.length - 2]
              : undefined;
          } else {
            compareToValidOdds = undefined;
          }

          const lastValidOdds = sortedHistory?.length
            ? sortedHistory[sortedHistory.length - 1]
            : undefined;

          setCompareToValidOdds(compareToValidOdds);
          setLastValidOdds(lastValidOdds);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [game]);

  if (loading) {
    return <LoadingBlock />;
  } else if (compareToValidOdds && lastValidOdds) {
    const oddsDifference = getOddsDifference();
    if (oddsDifference == undefined) {
      return <></>;
    } else if (oddsDifference < 0) {
      return <IconWithTooltip icon="pi pi-arrow-down" tooltip="Line is moving down" />;
    } else if (oddsDifference > 0) {
      return <IconWithTooltip icon="pi pi-arrow-up" tooltip="Line is moving up" />;
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};
