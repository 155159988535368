import { DateTime } from 'luxon';
import { Divider } from 'primereact/divider';
import { useEffect, useState } from 'react';
import { getContestStatusName } from '../common/models/contest-status';
import { Season } from '../common/models/season';
import { getPoolPayoutByStandings, validatePoolSettings } from '../utils/contest-utils';
import { formatMoney, formatTimestamp, getOrdinal } from '../utils/utils';
import { ErrorBlock } from './ErrorBlock';
import { UserAvatar } from './UserAvatar';

export interface SeasonInfoProps {
  season: Season;
}
export const SeasonInfo = (props: SeasonInfoProps) => {
  const { season } = props;

  const [seasonPoolSettingsValidationError, setSeasonPoolSettingsValidationError] = useState(
    validatePoolSettings(season)
  );

  useEffect(() => {
    setSeasonPoolSettingsValidationError(validatePoolSettings(season));
  }, [season]);

  return (
    <>
      <div className="grid">
        <div className="col">Status</div>
        <div className="col" style={{ textAlign: 'right' }}>
          {getContestStatusName(season.status)}
        </div>
      </div>
      <div className="grid flex-row-center">
        <div className="col">Owner</div>
        <div
          className="col"
          style={{ textAlign: 'right', justifyContent: 'end', alignItems: 'center' }}
        >
          {<UserAvatar email={season.owner} />}
        </div>
      </div>
      <div className="grid flex-row-center">
        <div className="col">Invited Members</div>
        <div
          className="col hidden md:flex"
          style={{ textAlign: 'right', alignItems: 'center', justifyContent: 'end' }}
        >
          {season?.invitedMembers?.map((member) => {
            return (
              <span key={member} style={{ padding: '.1em .1em .1em .1em' }}>
                <UserAvatar email={member} />
              </span>
            );
          })}
        </div>
        <div className="col md:hidden" style={{ textAlign: 'right' }}>
          {`${season?.invitedMembers?.length} members`}
        </div>
      </div>
      <div className="grid flex-row-center">
        <div className="col">Active Members</div>
        <div
          className="col hidden md:flex"
          style={{ textAlign: 'right', alignItems: 'center', justifyContent: 'end' }}
        >
          {season?.activeMembers?.map((member) => {
            return (
              <span key={member} style={{ padding: '.1em .1em .1em .1em' }}>
                <UserAvatar email={member} />
              </span>
            );
          })}
        </div>
        <div className="col md:hidden" style={{ textAlign: 'right' }}>
          {`${season?.activeMembers?.length} members`}
        </div>
      </div>
      <div className="grid">
        <div className="col">Starts</div>
        <div className="col" style={{ textAlign: 'right' }}>
          {formatTimestamp(season.start, DateTime.DATE_MED)}
        </div>
      </div>
      <div className="grid">
        <div className="col">Ends</div>
        <div className="col" style={{ textAlign: 'right' }}>
          {formatTimestamp(season.end, DateTime.DATE_MED)}
        </div>
      </div>
      {season.poolSettings && <Divider className="mt-1" />}
      {season.poolSettings && seasonPoolSettingsValidationError && (
        <ErrorBlock
          message={`The pool settings are invalid: ${seasonPoolSettingsValidationError}`}
        />
      )}
      {season.poolSettings && !seasonPoolSettingsValidationError && (
        <div className="grid">
          <div className="col">Pool Buy-in</div>
          <div className="col" style={{ textAlign: 'right' }}>
            {formatMoney(season.poolSettings.buyIn, season.poolSettings.currency)}
          </div>
        </div>
      )}
      {season.poolSettings && !seasonPoolSettingsValidationError && (
        <div className="grid">
          <div className="col">Total Pool Value</div>
          <div className="col" style={{ textAlign: 'right' }}>
            {formatMoney(
              season.poolSettings.buyIn * (season.activeMembers ? season.activeMembers.length : 0),
              season.poolSettings.currency
            )}
          </div>
        </div>
      )}
      {season.poolSettings &&
        !seasonPoolSettingsValidationError &&
        Object.entries(getPoolPayoutByStandings(season))
          .filter(([, payout]) => payout !== undefined)
          .map((entry) => {
            const [position, payout] = entry;
            return (
              <div className="grid" key={position}>
                <div className="col">
                  Payout for {+position + 1}
                  {getOrdinal(+position + 1)}
                </div>
                <div className="col" style={{ textAlign: 'right' }}>
                  {formatMoney(payout, season.poolSettings.currency)}
                </div>
              </div>
            );
          })}
    </>
  );
};
