import { Divider } from 'primereact/divider';
import { useEffect, useState } from 'react';
import { Bet, ParlayBet } from '../common/models/bet';
import { Contest } from '../common/models/contest';
import { Game } from '../common/models/game';
import { getParlayBetsForBetInStatus } from '../utils/bet-utils';
import { getGamesForBets } from '../utils/game-utils';
import { BetTable } from './BetTable';
import { ErrorBlock } from './ErrorBlock';
import { LoadingBlock } from './LoadingBlock';
import useAuth from '../auth/auth';
import { BetStatus } from '../common/models/bet-status';

export interface ParlayDetailsProps {
  contest: Contest;
  parlay: Bet;
}

export const ParlayDetails = (props: ParlayDetailsProps) => {
  const auth = useAuth();
  const { contest, parlay } = props;

  const [parlayBets, setParlayBets] = useState<ParlayBet[]>([]);
  const [games, setGames] = useState<Game[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const statuses =
      parlay.owner.toLowerCase() === auth?.user?.user?.email?.toLocaleLowerCase()
        ? []
        : [BetStatus.LOCKED, BetStatus.LOST, BetStatus.WON, BetStatus.PUSH];
    getParlayBetsForBetInStatus(parlay, statuses)
      .then((bets) => {
        setParlayBets(bets);
        getGamesForBets(bets as Bet[])
          .then((games) => {
            setGames(games);
            setLoading(false);
          })
          .catch((error) => {
            console.error('Unable to load games', error);
            setError('Unable to load related games');
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error('Unable to load parlay bets', error);
        setError('Unable to load details');
        setLoading(false);
      });
  }, [parlay, contest]);

  if (error) {
    return <ErrorBlock message={error} />;
  } else if (loading) {
    return <LoadingBlock />;
  } else {
    if (!parlay || !contest || !parlayBets?.length || !games?.length) {
      return <ErrorBlock message="Unable to load bet details" />;
    }
    return (
      <>
        <Divider />
        <div className="grid align-items-center">
          <div className="col text-left">Included Bets</div>
        </div>
        <BetTable contest={contest} bets={parlayBets as Bet[]} disableBetLinks={true} />
      </>
    );
  }
};
