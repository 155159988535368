import { useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PageHeader from '../components/PageHeader';
import { Bet, ParlayBet } from '../common/models/bet';
import { BetType } from '../common/models/bet-type';
import { calculateCombinedParlayBetOdds, calculateWinAmountForBet } from '../utils/bet-utils';

export const Admin = () => {
  const testBetCalculations = () => {
    console.log('Testing bet calculations...');
    console.log('Testing bets1...');
    const bets1 = [
      {
        betType: BetType.HOME_TEAM_SPREAD,
        price: -136
      } as ParlayBet,
      {
        betType: BetType.HOME_TEAM_SPREAD,
        price: -265
      } as ParlayBet
    ];
    console.log('bets1', bets1);
    const bets1Odds = calculateCombinedParlayBetOdds(bets1);
    console.log('bets1Odds', bets1Odds);
    const bet1Payout = calculateWinAmountForBet({
      amount: 100,
      currency: 'USD',
      betType: BetType.PARLAY,
      price: bets1Odds.american
    } as Bet);
    console.log('bet1Payout', bet1Payout);
    console.log(`bet1Payout should be 239.02, found ${bet1Payout + 100}`);

    console.log('Testing bets2...');
    const bets2 = [
      {
        betType: BetType.HOME_TEAM_SPREAD,
        price: -150
      } as ParlayBet,
      {
        betType: BetType.HOME_TEAM_SPREAD,
        price: 170
      } as ParlayBet,
      {
        betType: BetType.HOME_TEAM_SPREAD,
        price: -120
      } as ParlayBet
    ];
    console.log('bets2', bets2);
    const bets2Odds = calculateCombinedParlayBetOdds(bets2);
    console.log('bets2Odds', bets2Odds);
    const bet2Payout = calculateWinAmountForBet({
      amount: 100,
      currency: 'USD',
      betType: BetType.PARLAY,
      price: bets2Odds.american
    } as Bet);
    console.log('bet2Payout', bet2Payout);
    console.log(`bet2Payout should be 824.95, found ${bet2Payout + 100}`);
  };

  useEffect(() => {
    testBetCalculations();
    const functions = getFunctions();
    console.log(functions);
    httpsCallable(functions, 'helloWorld')()
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <PageHeader title="Admin" />
    </>
  );
};
