export enum BetStatus {
  PENDING = 'PENDING',
  LOCKED = 'LOCKED',
  WON = 'WON',
  LOST = 'LOST',
  PUSH = 'PUSH'
}

export const BetStatusSortOrder = {
  [BetStatus.PENDING]: 0,
  [BetStatus.LOCKED]: 1,
  [BetStatus.WON]: 2,
  [BetStatus.LOST]: 3,
  [BetStatus.PUSH]: 4
};

export const compareBetStatus = (a: BetStatus, b: BetStatus) => {
  return BetStatusSortOrder[a] - BetStatusSortOrder[b];
};
