import { Timestamp } from 'firebase/firestore';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { useEffect, useState } from 'react';
import useAuth from '../auth/auth';
import { Bet } from '../common/models/bet';
import { Contest } from '../common/models/contest';
import { ContestStatus } from '../common/models/contest-status';
import { Game } from '../common/models/game';
import { GameStatus } from '../common/models/game-status';
import useGlobalContestStateManagerContext from '../hooks/contest-state-manager';
import { getNonPendingBetsForContest, getUserBetsForContest } from '../utils/bet-utils';
import { gameHasValidLines } from '../utils/game-utils';
import GameHistory from './GameHistory';
import GameSummary from './GameSummary';
import IconButtonWithTooltip from './IconButtonWithTooltip';
import { LoadingBlock } from './LoadingBlock';
import PlaceBet from './PlaceBet';

export interface GameDetailsProps {
  game: Game;
  contest?: Contest;
  onBetPlaced?: () => void;
}

const GameDetails = (props: GameDetailsProps) => {
  const auth = useAuth();
  const globalContestStateManager = useGlobalContestStateManagerContext();
  const { contest, game, onBetPlaced } = props;

  const [showBetPanel, setShowBetPanel] = useState(false);

  const [myBets, setMyBets] = useState<Bet[]>([]);
  const [myBetsLoading, setMyBetsLoading] = useState(false);

  const [contestBets, setContestBets] = useState<Bet[]>([]);
  const [contestBetsLoading, setContestBetsLoading] = useState(false);

  useEffect(() => {
    if (!contest?.id?.length || !game) {
      setMyBetsLoading(false);
      setContestBetsLoading(false);
      return;
    }

    setMyBetsLoading(true);
    setContestBetsLoading(true);

    getNonPendingBetsForContest(contest)
      .then((bets) => {
        setContestBets(
          bets.filter(
            (bet) =>
              bet.owner.toLowerCase() !== auth?.user?.user?.email?.toLowerCase() &&
              bet.gameId === game.id
          )
        );
        setContestBetsLoading(false);
      })
      .catch((error) => {
        console.error('Unable to load contest bets.', error);
        setContestBetsLoading(false);
      });

    getUserBetsForContest(auth, contest.id)
      .then((bets) => {
        setMyBets(bets.filter((bet) => bet.gameId === game.id));
        setMyBetsLoading(false);
      })
      .catch((error) => {
        console.error('Unable to load bets.', error);
        setMyBetsLoading(false);
      });
  }, []);

  return (
    <>
      <Dialog
        header="Place a Bet"
        visible={showBetPanel}
        style={{ width: '95vw' }}
        onHide={() => setShowBetPanel(false)}
      >
        {contest && game ? (
          <PlaceBet
            game={game}
            contest={contest}
            onBetPlaced={() => {
              setShowBetPanel(false);
              if (onBetPlaced) {
                onBetPlaced();
              }
            }}
          ></PlaceBet>
        ) : (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <i className="pi pi-exclamation-triangle" style={{ fontSize: '1em' }}></i>
            <span style={{ marginLeft: '.25em', marginRight: '.25em' }}>
              Unable to place a bet on this game.
            </span>
          </div>
        )}
      </Dialog>

      <div style={{ textAlign: 'center' }}>
        {contest &&
          contest.status === ContestStatus.ACTIVE &&
          game.status === GameStatus.UPCOMING &&
          game.startTime > Timestamp.now() &&
          gameHasValidLines(game) && (
            <div>
              <IconButtonWithTooltip
                icon="pi-dollar"
                title=" Place a bet"
                onClick={() => setShowBetPanel(true)}
              ></IconButtonWithTooltip>
              <div style={{ paddingLeft: '.5em', display: 'inline-block' }}>
                <IconButtonWithTooltip
                  icon="pi-list"
                  title=" Add to parlay"
                  disabled={globalContestStateManager.isGameInParlayForContest(contest.id, game.id)}
                  onClick={() => {
                    if (contest && contest.id) {
                      globalContestStateManager.addGameToParlayForContest(contest.id, game.id);
                    } else {
                      console.error('Unable to add game to parlay. No contest id found.');
                    }
                  }}
                ></IconButtonWithTooltip>
              </div>
            </div>
          )}
      </div>

      <GameSummary
        game={game}
        contest={contest}
        onBetPlaced={onBetPlaced}
        disableGameDetails={true}
      ></GameSummary>

      <Divider />
      {(myBetsLoading || contestBetsLoading) && <LoadingBlock message="Loading bets..." />}
      {!myBetsLoading && !contestBetsLoading && (
        <div style={{ marginTop: '1em' }}>
          <GameHistory game={game} userBets={myBets} contestBets={contestBets}></GameHistory>
        </div>
      )}
    </>
  );
};

export default GameDetails;
