import { Tooltip } from 'primereact/tooltip';
import { ReactNode } from 'react';

export interface IconWithTooltipProps {
  key?: string;
  wrapperStyle?: any;
  icon: string;
  iconStyle?: any;
  title?: string;
  tooltip?: string;
  tooltipTemplate?: ReactNode;
  tooltipPosition?: 'left' | 'right' | 'top' | 'bottom';
}

const defaultTooltipPosition = 'left';

const IconWithTooltip = (props: IconWithTooltipProps) => {
  const { key, wrapperStyle, icon, title, tooltip, tooltipTemplate, tooltipPosition, iconStyle } =
    props;
  const activeKey = key ? key : `target-icon-${Math.random().toString(36).substring(2, 9)}`;

  const content = (
    <div className="flex flex-row align-items-center" style={wrapperStyle}>
      <Tooltip target={`.${activeKey}`}>
        {tooltipTemplate && tooltipTemplate}
        {!tooltipTemplate && tooltip?.length && <div style={{ maxWidth: '12em' }}>{tooltip}</div>}
      </Tooltip>
      <div>
        <span
          className={`pi ${icon} ${tooltipTemplate || tooltip?.length ? activeKey : ''}`}
          style={iconStyle ? iconStyle : {}}
          data-pr-position={tooltipPosition ? tooltipPosition : defaultTooltipPosition}
        ></span>
      </div>
      {title?.length && <div className="pl-1">{title}</div>}
    </div>
  );
  return content;
};

export default IconWithTooltip;
