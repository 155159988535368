import { Card } from 'primereact/card';
import { GameStatus } from '../common/models/game-status';
import GamesViewer from '../components/GamesViewer';
import PageHeader from '../components/PageHeader';

function Research() {
  return (
    <>
      <PageHeader title="Research"></PageHeader>
      <Card title="Upcoming Games">
        <GamesViewer
          pageSize={5}
          gameStatuses={[GameStatus.UPCOMING]}
          sortOrder="asc"
        ></GamesViewer>
      </Card>
      <Card title="Recent Games" className="mt-2">
        <GamesViewer
          pageSize={5}
          gameStatuses={[GameStatus.COMPLETE, GameStatus.IN_PROGRESS]}
          sortOrder="asc"
        ></GamesViewer>
      </Card>
    </>
  );
}

export default Research;
