import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { Sport } from '../common/models/sport';
import { Team } from '../common/models/team';
import { db } from '../firebase';

export const getTeamsForSport = async (sport: Sport): Promise<Team[]> => {
  const teamsRef = collection(db, 'teams');
  const queryConstraints = [];
  queryConstraints.push(where('sport', '==', sport));
  queryConstraints.push(orderBy('name', 'asc'));
  const q = query(teamsRef, ...queryConstraints);
  return (await getDocs(q)).docs.map((doc) => doc.data() as Team);
};

export const getAllTeams = async (): Promise<Team[]> => {
  const teamsRef = collection(db, 'teams');
  const queryConstraints = [];
  queryConstraints.push(orderBy('name', 'asc'));
  const q = query(teamsRef, ...queryConstraints);
  return (await getDocs(q)).docs.map((doc) => doc.data() as Team);
};
