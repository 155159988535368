import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { buildSportOptions, Sport } from '../common/models/sport';

export interface GameFilters {
  sport?: Sport;
}

interface GameFilterProps {
  sports?: Sport[];
  onFilterChange?: (gameFilters: GameFilters) => void;
}

const GameFilter = (gameFilterProps: GameFilterProps) => {
  const { sports, onFilterChange } = gameFilterProps;

  let activeSports: Sport[];
  if (!sports || sports.length === 0) {
    activeSports = Object.values(Sport);
  } else {
    activeSports = sports;
  }
  const sportOptions = buildSportOptions(activeSports.sort((a, b) => a.localeCompare(b)));
  const [selectedSport, setSelectedSport] = useState<Sport>(activeSports[0]);

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange({ sport: selectedSport });
    }
  }, [selectedSport]);

  return (
    <span>
      <Dropdown
        inputId="sportSelect"
        value={selectedSport}
        options={sportOptions}
        onChange={(e) => {
          const sport = e.value as Sport;
          setSelectedSport(sport);
        }}
        optionLabel="name"
        placeholder="Filter by sport"
      />
    </span>
  );
};

export default GameFilter;
