import { collection, deleteDoc, getDocs, orderBy, query, where } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { AuthContextType } from '../auth/auth';
import { Bet, ParlayBet } from '../common/models/bet';
import { BetStatus } from '../common/models/bet-status';
import { BetType } from '../common/models/bet-type';
import { Contest } from '../common/models/contest';
import { Odds } from '../common/models/odds';
import { db } from '../firebase';
import { formatMoney } from './utils';

const DEFAULT_PRICE = 100;

export const calculateWinAmountForBet = (bet: Bet): number => {
  if (!bet?.amount) {
    return 0;
  }

  let activePrice = bet.price || DEFAULT_PRICE;
  if (bet.betType === BetType.HOME_TEAM_MONEYLINE || bet.betType === BetType.AWAY_TEAM_MONEYLINE) {
    if (bet.price === undefined || bet.price === null) {
      activePrice = bet.line || DEFAULT_PRICE;
    }
  }

  if (activePrice < 0) {
    return (100 / Math.abs(activePrice)) * bet.amount;
  } else {
    return (activePrice / 100) * bet.amount;
  }
};

export const calculateWinPercentage = (bets: Bet[]): number => {
  if (!bets?.length) {
    return 0;
  }
  let wins = 0;
  let losses = 0;
  bets.forEach((bet) => {
    if (bet.status === BetStatus.WON) {
      wins++;
    } else if (bet.status === BetStatus.LOST) {
      losses++;
    }
  });
  return (wins / (wins + losses)) * 100;
};

export const calculateBalanceForContestBets = (
  contest: Contest,
  bets: Bet[],
  includePendingBets = true
): number => {
  if (!contest?.initialBalance) {
    throw new Error('Contest is not valid');
  }
  let balance = contest.initialBalance;
  bets
    .filter((bet) =>
      includePendingBets
        ? true
        : bet.status !== BetStatus.LOCKED && bet.status !== BetStatus.PENDING
    )
    .forEach((bet) => {
      if (bet.status !== BetStatus.WON) {
        balance -= bet.amount;
      } else {
        const price = bet.price || DEFAULT_PRICE;
        if (price < 0) {
          balance += (100 / Math.abs(price)) * bet.amount;
        } else {
          balance += (price / 100) * bet.amount;
        }
      }
    });
  return balance;
};

export const deleteBetsForContest = async (contestId: string): Promise<void> => {
  if (!contestId?.length) {
    throw new Error('Contest is not valid');
  }
  const betsRef = collection(db, 'bets');
  const q = query(betsRef, where('contestId', '==', contestId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (doc) => {
    await deleteDoc(doc.ref);
  });
};

export const getUserBalanceForContest = async (
  auth: AuthContextType,
  contest: Contest
): Promise<number> => {
  if (!contest?.id?.length) {
    throw new Error('Contest is not valid');
  }
  const bets = await getUserBetsForContest(auth, contest.id);
  const balance = calculateBalanceForContestBets(contest, bets);
  return balance;
};

export const getUserAndNonPendingBetsForContest = async (
  auth: AuthContextType,
  contest: Contest
): Promise<Bet[]> => {
  const email = auth?.user?.user?.email?.toLowerCase();
  if (!email?.length) {
    throw new Error('User is not logged in');
  }
  const contestId = contest?.id;
  if (!contestId?.length) {
    throw new Error('Contest is not valid');
  }

  const userBets = await getUserBetsForContest(auth, contestId);
  const nonPendingBets = await (
    await getNonPendingBetsForContest(contest)
  ).filter((bet) => bet.owner !== email);

  return [...userBets, ...nonPendingBets];
};

export const getUserBetsForContest = async (
  auth: AuthContextType,
  contestId: string
): Promise<Bet[]> => {
  if (!auth?.user?.user?.email?.length) {
    throw new Error('User is not logged in');
  }
  if (!contestId?.length) {
    throw new Error('Contest is not valid');
  }
  const betsRef = collection(db, 'bets');
  const queryConstraints = [];
  queryConstraints.push(where('owner', '==', auth.user.user.email.toLowerCase()));
  queryConstraints.push(where('contestId', '==', contestId));
  queryConstraints.push(orderBy('timestamp', 'desc'));
  const q = query(betsRef, ...queryConstraints);
  return await getDocs(q).then((querySnapshot) => {
    const bets: Bet[] = [];
    querySnapshot.forEach((doc) => {
      const bet = doc.data() as Bet;
      bet.id = doc.id;
      bets.push(bet);
    });
    return bets;
    // return bets.filter((bet) => bet.betType !== BetType.PARLAY);
  });
};

export const getBetsForGame = async (gameId: string): Promise<Bet[]> => {
  if (!gameId?.length) {
    throw new Error('Game is not valid');
  }
  const betsRef = collection(db, 'bets');
  const queryConstraints = [];
  queryConstraints.push(where('gameId', '==', gameId));
  queryConstraints.push(orderBy('timestamp', 'desc'));
  const q = query(betsRef, ...queryConstraints);
  return await getDocs(q).then((querySnapshot) => {
    const bets: Bet[] = [];
    querySnapshot.forEach((doc) => {
      const bet = doc.data() as Bet;
      bet.id = doc.id;
      bets.push(bet);
    });
    return bets;
  });
};

export const getNonPendingBetsForContest = async (contest: Contest): Promise<Bet[]> => {
  return getBetsForContestByStatus(contest, [
    BetStatus.LOCKED,
    BetStatus.WON,
    BetStatus.LOST,
    BetStatus.PUSH
  ]);
};

export const getBetsForContestByStatus = async (
  contest: Contest,
  statuses: BetStatus[]
): Promise<Bet[]> => {
  if (!contest?.id?.length) {
    throw new Error('Contest is not valid');
  }

  const betsRef = collection(db, 'bets');
  const queryConstraints = [];
  queryConstraints.push(where('contestId', '==', contest.id));
  queryConstraints.push(where('status', 'in', statuses));
  queryConstraints.push(orderBy('timestamp', 'desc'));
  const q = query(betsRef, ...queryConstraints);
  return await getDocs(q).then((querySnapshot) => {
    const bets: Bet[] = [];
    querySnapshot.forEach((doc) => {
      const bet = doc.data() as Bet;
      bet.id = doc.id;
      bets.push(bet);
    });
    return bets;
    // return bets.filter((bet) => bet.betType !== BetType.PARLAY);
  });
};

export const getParlayBetsForContestByStatus = async (
  contest: Contest,
  statuses: BetStatus[]
): Promise<ParlayBet[]> => {
  if (!contest?.id?.length) {
    throw new Error('Contest is not valid');
  }

  const betsRef = collection(db, 'parlayBets');
  const queryConstraints = [];
  queryConstraints.push(where('contestId', '==', contest.id));
  queryConstraints.push(where('status', 'in', statuses));
  queryConstraints.push(orderBy('timestamp', 'desc'));
  const q = query(betsRef, ...queryConstraints);
  return await getDocs(q).then((querySnapshot) => {
    const bets: ParlayBet[] = [];
    querySnapshot.forEach((doc) => {
      const bet = doc.data() as ParlayBet;
      bet.id = doc.id;
      bets.push(bet);
    });
    return bets;
    // return bets.filter((bet) => bet.betType !== BetType.PARLAY);
  });
};

export const saveBet = async (
  auth: AuthContextType,
  bet: Bet,
  parlayBets?: ParlayBet[]
): Promise<Bet> => {
  const functions = getFunctions();
  let betResponse;
  try {
    betResponse = await httpsCallable(
      functions,
      'placeBet'
    )(parlayBets?.length ? { bet: bet, parlayBets: parlayBets } : { bet: bet });
  } catch (error) {
    console.error('Unble to save bet', error);
  }

  if (!betResponse) {
    throw new Error('Unable to save bet.');
  }
  const data = betResponse.data as any;
  if (!data) {
    throw new Error('Unable to save bet.');
  }
  const status = data.status as 'success' | 'error';
  const message = data.message as string;
  if (status !== 'success') {
    throw new Error(message?.length ? message : 'Unable to save bet.');
  }
  return data.bet as Bet;
};

export const describeBet = (bet: Bet, includeTeamNames = true): string => {
  let activePrice = bet.price || 100;
  if (bet.betType === BetType.HOME_TEAM_MONEYLINE || bet.betType === BetType.AWAY_TEAM_MONEYLINE) {
    if (bet.price === undefined || bet.price === null) {
      activePrice = bet.line || 100;
    }
  }

  if (bet.betType === BetType.PARLAY) {
    return `${formatMoney(bet.amount, bet.currency)} on multiple games (${
      activePrice >= 0 ? '+' : ''
    }${activePrice.toFixed(0)}) for a payout of ${formatMoney(
      calculateWinAmountForBet(bet) + bet.amount,
      bet.currency
    )}`;
  }

  let description = '';
  // const amount = bet.amount ? formatMoney(bet.amount, bet.currency) + ' on the' : 'On the';
  const amount = bet.amount ? formatMoney(bet.amount, bet.currency) : '';
  const payout = bet.amount
    ? ` for a payout of ${formatMoney(calculateWinAmountForBet(bet) + bet.amount, bet.currency)}`
    : '';
  description += `${amount} `;
  switch (bet.betType) {
    case BetType.OVER:
      description += `on the total score being greater than ${bet.line} (${
        activePrice >= 0 ? '+' : ''
      }${activePrice})${payout}`;
      break;
    case BetType.UNDER:
      description += `on the total score being less than ${bet.line} (${
        activePrice >= 0 ? '+' : ''
      }${activePrice})${payout}`;
      break;
    default:
      description += `${includeTeamNames ? bet.teamName : ''} to win ${
        bet.betType === BetType.HOME_TEAM_MONEYLINE || bet.betType === BetType.AWAY_TEAM_MONEYLINE
          ? 'outright'
          : bet.line < 0
          ? 'by more than ' + Math.abs(bet.line)
          : 'or lose by less than ' + bet.line
      } (${activePrice >= 0 ? '+' : ''}${activePrice})${payout}`;
      break;
  }
  return description;
};

export const describeBetSmall = (bet: Bet): string => {
  let activePrice = bet.price || 100;
  if (bet.betType === BetType.HOME_TEAM_MONEYLINE || bet.betType === BetType.AWAY_TEAM_MONEYLINE) {
    if (bet.price === undefined || bet.price === null) {
      activePrice = bet.line || 100;
    }
  }

  if (bet.betType === BetType.PARLAY) {
    return `${formatMoney(bet.amount, bet.currency)} on multiple games (${
      activePrice >= 0 ? '+' : ''
    }${activePrice.toFixed(0)}) for a payout of ${formatMoney(
      calculateWinAmountForBet(bet) + bet.amount,
      bet.currency
    )}`;
  }

  const payout = bet.amount
    ? ` for a payout of ${formatMoney(calculateWinAmountForBet(bet) + bet.amount, bet.currency)}`
    : '';
  let description = '';
  switch (bet.betType) {
    case BetType.OVER:
      description += `Total score being greater than ${bet.line} (${
        activePrice >= 0 ? '+' : ''
      }${activePrice})${payout}`;
      break;
    case BetType.UNDER:
      description += `Total score being less than ${bet.line} (${
        activePrice >= 0 ? '+' : ''
      }${activePrice})${payout}`;
      break;
    default:
      description += `${bet.teamName} to win ${
        bet.betType === BetType.HOME_TEAM_MONEYLINE || bet.betType === BetType.AWAY_TEAM_MONEYLINE
          ? 'outright'
          : bet.line < 0
          ? 'by more than ' + Math.abs(bet.line)
          : 'or lose by less than ' + bet.line
      } (${activePrice >= 0 ? '+' : ''}${activePrice})${payout}`;
      break;
  }
  return description;
};

export const convertAmericanOddsToDecimal = (odds: number): number => {
  if (odds >= 0) {
    return odds / 100 + 1;
  } else {
    return 100 / Math.abs(odds) + 1;
  }
};

export const convertDecimalOddsToAmerican = (odds: number): number => {
  if (odds >= 2) {
    return (odds - 100) * 100;
  } else {
    return -100 / (odds - 1);
  }
};

export const getParlayBetsForBetInStatus = async (
  bet: Bet,
  statuses: BetStatus[]
): Promise<ParlayBet[]> => {
  if (!bet || !bet.id || bet.betType !== BetType.PARLAY) {
    throw new Error('Invalid bet');
  }
  const betsRef = collection(db, 'parlayBets');
  const queryConstraints = [];
  queryConstraints.push(where('betId', '==', bet.id));
  if (statuses?.length) {
    queryConstraints.push(where('status', 'in', statuses));
  }
  const q = query(betsRef, ...queryConstraints);
  const querySnapshot = await getDocs(q);
  if (querySnapshot.size) {
    const bets = querySnapshot.docs.map((doc) => doc.data() as ParlayBet);
    return bets;
  }
  return [];
};

export const getParlayBetsForBets = async (
  bets: Bet[],
  statuses: BetStatus[]
): Promise<ParlayBet[]> => {
  if (!bets?.length) {
    throw new Error('Invalid bets');
  }
  const betsRef = collection(db, 'parlayBets');
  const queryConstraints = [];
  queryConstraints.push(
    where(
      'betId',
      'in',
      bets.map((bet) => bet.id)
    )
  );
  if (statuses?.length) {
    queryConstraints.push(where('status', 'in', statuses));
  }
  const q = query(betsRef, ...queryConstraints);
  const querySnapshot = await getDocs(q);
  if (querySnapshot.size) {
    const bets = querySnapshot.docs.map((doc) => doc.data() as ParlayBet);
    return bets;
  }
  return [];
};

export const calculateCombinedParlayBetOdds = (bets: ParlayBet[]): Odds => {
  const activeBets = bets?.length ? bets.filter((bet) => bet && !isNaN(bet.price)) : [];

  if (!activeBets.length) {
    return {
      decimal: 0,
      american: 0,
      valid: false
    } as Odds;
  }

  if (activeBets.length === 1) {
    return {
      decimal: convertAmericanOddsToDecimal(activeBets[0].price),
      american: activeBets[0].price,
      valid: true
    };
  }

  const prices = activeBets.map((bet) => bet.price);
  const pricesDecimal = prices.map((price) => convertAmericanOddsToDecimal(price));
  const combinedPrices = pricesDecimal.reduce((a, b) => a * b, 1);
  const combined = combinedPrices;
  return {
    decimal: combined,
    american: (combined - 1) * 100,
    valid: true
  } as Odds;
};
