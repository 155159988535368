import {
  DocumentData,
  DocumentSnapshot,
  QuerySnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from 'firebase/firestore';
import { Season } from '../common/models/season';
import { db } from '../firebase';
import { getContest } from './contest-utils';
import { AuthContextType } from '../auth/auth';
import { isAuthContextTypeValid } from './auth-utils';

export const buildSeason = async (seasonDoc: DocumentSnapshot<DocumentData>): Promise<Season> => {
  if (!seasonDoc.exists()) {
    throw new Error('Could not build season, the season does not exist');
  }

  const season = seasonDoc.data() as Season;
  if (!season) {
    throw new Error('Could not build season');
  }
  season.id = seasonDoc.id;

  season.contests = [];
  if (season.contestIds?.length) {
    for (const contestId of season.contestIds) {
      const contest = await getContest(contestId);
      if (contest) {
        season.contests.push(contest);
      }
    }
  }

  return season;
};

export const buildSeasons = async (seasonDocs: QuerySnapshot<DocumentData>): Promise<Season[]> => {
  if (!seasonDocs) {
    throw new Error('Could not build seasons');
  }
  const seasons: Season[] = [];
  for (const seasonDoc of seasonDocs.docs) {
    try {
      const season = await buildSeason(seasonDoc);
      seasons.push(season);
    } catch (e) {
      console.error(`Error building contest ${seasonDoc.id}`, e);
    }
  }
  return seasons;
};

export const getSeason = async (id: string): Promise<Season> => {
  const seasonRef = doc(db, 'seasons', id);
  return await getDoc(seasonRef).then(async (seasonDoc) => {
    return await buildSeason(seasonDoc);
  });
};

export const getSeasonsImActiveIn = async (auth: AuthContextType): Promise<Season[]> => {
  if (!isAuthContextTypeValid(auth)) {
    throw new Error('User must be logged in and have a valid email');
  }
  const contestsRef = collection(db, 'seasons');
  const queryConstraints = [];
  queryConstraints.push(
    where('activeMembers', 'array-contains', auth?.user?.user?.email?.toLowerCase())
  );
  const q = query(contestsRef, ...queryConstraints);
  const seasonDocs = await getDocs(q);
  return await buildSeasons(seasonDocs);
};
