import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { Bet } from '../common/models/bet';
import { BetType } from '../common/models/bet-type';
import { Game } from '../common/models/game';
import { GameStatus } from '../common/models/game-status';
import { getCurrentGameStatus, getGameOddsHistory, getGameScoreHistory } from '../utils/game-utils';
import { formatTimestamp } from '../utils/utils';

export interface GameHistoryProps {
  game: Game;
  userBets?: Bet[];
  contestBets?: Bet[];
}

interface SpreadHistory {
  homeTeamSpread: number;
  awayTeamSpread: number;
  homeTeam: string;
  awayTeam: string;
  timestamp: Timestamp;
}

interface SpreadHistory {
  homeTeamSpread: number;
  awayTeamSpread: number;
  homeTeam: string;
  awayTeam: string;
  timestamp: Timestamp;
}

const isSpreadHistoryValid = (spreadHistory: SpreadHistory) => {
  return (
    spreadHistory.homeTeamSpread !== undefined &&
    spreadHistory.homeTeamSpread !== null &&
    spreadHistory.awayTeamSpread !== undefined &&
    spreadHistory.awayTeamSpread !== null &&
    spreadHistory.homeTeam !== undefined &&
    spreadHistory.homeTeam !== null &&
    spreadHistory.awayTeam !== undefined &&
    spreadHistory.awayTeam !== null &&
    spreadHistory.timestamp !== undefined &&
    spreadHistory.timestamp !== null
  );
};

interface MoneyLineHistory {
  homeTeamMoneyLine: number;
  awayTeamMoneyLine: number;
  homeTeam: string;
  awayTeam: string;
  timestamp: Timestamp;
  timestampString: string;
}

const isMoneylineHistoryValid = (moneylineHistory: MoneyLineHistory) => {
  return (
    moneylineHistory.homeTeamMoneyLine !== undefined &&
    moneylineHistory.homeTeamMoneyLine !== null &&
    moneylineHistory.awayTeamMoneyLine !== undefined &&
    moneylineHistory.awayTeamMoneyLine !== null &&
    moneylineHistory.homeTeam !== undefined &&
    moneylineHistory.homeTeam !== null &&
    moneylineHistory.awayTeam !== undefined &&
    moneylineHistory.awayTeam !== null &&
    moneylineHistory.timestamp !== undefined &&
    moneylineHistory.timestamp !== null
  );
};

interface OverUnderHistory {
  overUnderLine: number;
  timestamp: Timestamp;
}

const isOverUnderHistoryValid = (overUnderHistory: OverUnderHistory) => {
  return (
    overUnderHistory.overUnderLine !== undefined &&
    overUnderHistory.overUnderLine !== null &&
    overUnderHistory.timestamp !== undefined &&
    overUnderHistory.timestamp !== null
  );
};

const GameHistory = (props: GameHistoryProps) => {
  const { game, userBets, contestBets } = props;

  const [loadingOddsHistory, setLoadingOddsHistory] = useState<boolean>(false);
  const [loadingScoreHistory, setLoadingScoreHistory] = useState<boolean>(false);
  const [errorOddsHistory, setErrorOddsHistory] = useState<string | undefined>(undefined);
  const [errorScoreHistory, setErrorScoreHistory] = useState<string | undefined>(undefined);
  const [scoreHistoryChartOptions, setScoreHistoryChartOptions] = useState<EChartsOption>();
  const [spreadHistoryChartOptions, setSpreadHistoryChartOptions] = useState<EChartsOption>();
  const [moneylineHistoryChartOptions, setMoneylineHistoryChartOptions] = useState<EChartsOption>();
  const [overUnderHistoryChartOptions, setOverUnderHistoryChartOptions] = useState<EChartsOption>();

  const loadOddsHistory = async () => {
    setLoadingOddsHistory(true);
    setErrorOddsHistory(undefined);
    setLoadingScoreHistory(true);
    setErrorScoreHistory(undefined);

    let scoreHistory;
    try {
      scoreHistory = await getGameScoreHistory(game.id);
    } catch (error) {
      console.error('Unable to load score history.', error);
      setErrorScoreHistory('Unable to load score history.');
    }
    if (scoreHistory) {
      if (scoreHistory && scoreHistory.length) {
        setScoreHistoryChartOptions({
          grid: { top: 8, right: 8, bottom: 24, left: 36 },
          xAxis: {
            type: 'category',
            data: scoreHistory.map((score) =>
              formatTimestamp(score.updatedTime, DateTime.DATETIME_SHORT)
            )
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: game.homeTeam,
              data: scoreHistory.map((score) => score.homeTeamScore),
              type: 'line',
              smooth: true
            },
            {
              name: game.awayTeam,
              data: scoreHistory.map((score) => score.awayTeamScore),
              type: 'line',
              smooth: true
            }
          ],
          tooltip: {
            trigger: 'axis'
          }
        } as EChartsOption);
      }
    }

    let oddsHistory;
    try {
      oddsHistory = await getGameOddsHistory(game.id);
    } catch (error) {
      setErrorOddsHistory('Unable to load odds history.');
      console.error('Unable to load odds history.', error);
    }
    if (oddsHistory) {
      const spreadHistory = oddsHistory
        .map((odds) => {
          return {
            homeTeamSpread: odds.homeTeamSpread,
            awayTeamSpread: odds.awayTeamSpread,
            homeTeam: odds.homeTeam,
            awayTeam: odds.awayTeam,
            timestamp: odds.updatedTime
          } as SpreadHistory;
        })
        .filter(isSpreadHistoryValid);

      if (spreadHistory && spreadHistory.length) {
        const mySpreadBets = !userBets
          ? []
          : userBets.filter(
              (bet) =>
                bet.betType === BetType.AWAY_TEAM_SPREAD || bet.betType === BetType.HOME_TEAM_SPREAD
            );

        const contestSpreadBets = !contestBets
          ? []
          : contestBets.filter(
              (bet) =>
                bet.betType === BetType.AWAY_TEAM_SPREAD || bet.betType === BetType.HOME_TEAM_SPREAD
            );

        const allSpreadHistoryTimestamps = [
          ...mySpreadBets.map((bet) => bet.timestamp.toMillis()),
          ...contestSpreadBets.map((bet) => bet.timestamp.toMillis()),
          ...spreadHistory.map((spread) => spread.timestamp.toMillis())
        ].sort((a, b) => a - b);

        const mySpreadBetsByTimestamp = mySpreadBets.reduce((acc, bet) => {
          acc[bet.timestamp.toMillis()] = bet;
          return acc;
        }, {} as { [key: number]: Bet });

        const contestSpreadBetsByTimestamp = contestSpreadBets.reduce((acc, bet) => {
          acc[bet.timestamp.toMillis()] = bet;
          return acc;
        }, {} as { [key: number]: Bet });

        const spreadHistoryByTimestamp = spreadHistory.reduce((acc, spread) => {
          acc[spread.timestamp.toMillis()] = spread;
          return acc;
        }, {} as { [key: number]: SpreadHistory });

        setSpreadHistoryChartOptions({
          grid: { top: 8, right: 8, bottom: 24, left: 36 },
          xAxis: {
            type: 'category',
            data: allSpreadHistoryTimestamps.map((millis) =>
              formatTimestamp(Timestamp.fromMillis(millis), DateTime.DATETIME_SHORT)
            )
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: game.homeTeam,
              data: allSpreadHistoryTimestamps.map((millis, index) => {
                const spreadHistory: SpreadHistory = spreadHistoryByTimestamp[millis];
                while (index > 0 && !spreadHistory) {
                  index--;
                  const previousSpreadHistory = spreadHistoryByTimestamp[
                    allSpreadHistoryTimestamps[index]
                  ]
                    ? spreadHistoryByTimestamp[allSpreadHistoryTimestamps[index]].homeTeamSpread
                    : null;
                  if (previousSpreadHistory) {
                    return previousSpreadHistory;
                  }
                }
                return spreadHistory ? spreadHistory.homeTeamSpread : null;
              }),
              type: 'line',
              smooth: true,
              connectNulls: true
            },
            {
              name: game.awayTeam,
              data: allSpreadHistoryTimestamps.map((millis, index) => {
                const spreadHistory: SpreadHistory = spreadHistoryByTimestamp[millis];
                while (index > 0 && !spreadHistory) {
                  index--;
                  const previousSpreadHistory = spreadHistoryByTimestamp[
                    allSpreadHistoryTimestamps[index]
                  ]
                    ? spreadHistoryByTimestamp[allSpreadHistoryTimestamps[index]].awayTeamSpread
                    : null;
                  if (previousSpreadHistory) {
                    return previousSpreadHistory;
                  }
                }
                return spreadHistory ? spreadHistory.awayTeamSpread : null;
              }),
              type: 'line',
              smooth: true,
              connectNulls: true
            },
            {
              name: `My Bet: ${game.homeTeam}`,
              data: allSpreadHistoryTimestamps.map((millis) => {
                const bet = mySpreadBetsByTimestamp[millis];
                return bet && bet.betType === BetType.HOME_TEAM_SPREAD ? bet.line : null;
              }),
              type: 'scatter',
              symbolSize: 20
            },
            {
              name: `My Bet: ${game.awayTeam}`,
              data: allSpreadHistoryTimestamps.map((millis) => {
                const bet = mySpreadBetsByTimestamp[millis];
                return bet && bet.betType === BetType.AWAY_TEAM_SPREAD ? bet.line : null;
              }),
              type: 'scatter',
              symbolSize: 20
            },
            {
              name: `Other Bets: ${game.homeTeam}`,
              data: allSpreadHistoryTimestamps.map((millis) => {
                const bet = contestSpreadBetsByTimestamp[millis];
                return bet && bet.betType === BetType.HOME_TEAM_SPREAD ? bet.line : null;
              }),
              type: 'scatter',
              symbolSize: 20
            },
            {
              name: `Other Bets: ${game.awayTeam}`,
              data: allSpreadHistoryTimestamps.map((millis) => {
                const bet = contestSpreadBetsByTimestamp[millis];
                return bet && bet.betType === BetType.AWAY_TEAM_SPREAD ? bet.line : null;
              }),
              type: 'scatter',
              symbolSize: 20
            }
          ],
          tooltip: {
            trigger: 'axis'
          }
        } as EChartsOption);
      }

      const moneylineHistory = oddsHistory
        .map((odds) => {
          return {
            homeTeamMoneyLine: odds.homeTeamMoneyline,
            awayTeamMoneyLine: odds.awayTeamMoneyline,
            homeTeam: odds.homeTeam,
            awayTeam: odds.awayTeam,
            timestamp: odds.updatedTime
          } as MoneyLineHistory;
        })
        .filter(isMoneylineHistoryValid);

      if (moneylineHistory && moneylineHistory.length) {
        const myMoneylineBets = !userBets
          ? []
          : userBets.filter(
              (bet) =>
                bet.betType === BetType.AWAY_TEAM_MONEYLINE ||
                bet.betType === BetType.HOME_TEAM_MONEYLINE
            );

        const contestMoneylineBets = !contestBets
          ? []
          : contestBets.filter(
              (bet) =>
                bet.betType === BetType.AWAY_TEAM_MONEYLINE ||
                bet.betType === BetType.HOME_TEAM_MONEYLINE
            );

        const allMoneylineHistoryTimestamps = [
          ...myMoneylineBets.map((bet) => bet.timestamp.toMillis()),
          ...contestMoneylineBets.map((bet) => bet.timestamp.toMillis()),
          ...moneylineHistory.map((spread) => spread.timestamp.toMillis())
        ].sort((a, b) => a - b);

        const myMoneylineBetsByTimestamp = myMoneylineBets.reduce((acc, bet) => {
          acc[bet.timestamp.toMillis()] = bet;
          return acc;
        }, {} as { [key: number]: Bet });

        const contestMoneylineBetsByTimestamp = contestMoneylineBets.reduce((acc, bet) => {
          acc[bet.timestamp.toMillis()] = bet;
          return acc;
        }, {} as { [key: number]: Bet });

        const moneylineHistoryByTimestamp = moneylineHistory.reduce((acc, moneyline) => {
          acc[moneyline.timestamp.toMillis()] = moneyline;
          return acc;
        }, {} as { [key: number]: MoneyLineHistory });

        setMoneylineHistoryChartOptions({
          grid: { top: 8, right: 8, bottom: 24, left: 36 },
          xAxis: {
            type: 'category',
            data: allMoneylineHistoryTimestamps.map((millis) =>
              formatTimestamp(Timestamp.fromMillis(millis), DateTime.DATETIME_SHORT)
            )
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: game.homeTeam,
              data: allMoneylineHistoryTimestamps.map((millis, index) => {
                const moneylineHistory: MoneyLineHistory = moneylineHistoryByTimestamp[millis];
                while (index > 0 && !moneylineHistory) {
                  index--;
                  const previousSpreadHistory = moneylineHistoryByTimestamp[
                    allMoneylineHistoryTimestamps[index]
                  ]
                    ? moneylineHistoryByTimestamp[allMoneylineHistoryTimestamps[index]]
                        .homeTeamMoneyLine
                    : null;
                  if (previousSpreadHistory) {
                    return previousSpreadHistory;
                  }
                }
                return moneylineHistory ? moneylineHistory.homeTeamMoneyLine : null;
              }),
              type: 'line',
              smooth: true,
              connectNulls: true
            },
            {
              name: game.awayTeam,
              data: allMoneylineHistoryTimestamps.map((millis, index) => {
                const moneylineHistory: MoneyLineHistory = moneylineHistoryByTimestamp[millis];
                while (index > 0 && !moneylineHistory) {
                  index--;
                  const previousSpreadHistory = moneylineHistoryByTimestamp[
                    allMoneylineHistoryTimestamps[index]
                  ]
                    ? moneylineHistoryByTimestamp[allMoneylineHistoryTimestamps[index]]
                        .awayTeamMoneyLine
                    : null;
                  if (previousSpreadHistory) {
                    return previousSpreadHistory;
                  }
                }
                return moneylineHistory ? moneylineHistory.awayTeamMoneyLine : null;
              }),
              type: 'line',
              smooth: true,
              connectNulls: true
            },
            {
              name: `My Bet: ${game.homeTeam}`,
              data: allMoneylineHistoryTimestamps.map((millis) => {
                const bet = myMoneylineBetsByTimestamp[millis];
                return bet && bet.betType === BetType.HOME_TEAM_MONEYLINE ? bet.line : null;
              }),
              type: 'scatter',
              symbolSize: 20
            },
            {
              name: `My Bet: ${game.awayTeam}`,
              data: allMoneylineHistoryTimestamps.map((millis) => {
                const bet = myMoneylineBetsByTimestamp[millis];
                return bet && bet.betType === BetType.AWAY_TEAM_MONEYLINE ? bet.line : null;
              }),
              type: 'scatter',
              symbolSize: 20
            },
            {
              name: `Other Bets: ${game.homeTeam}`,
              data: allMoneylineHistoryTimestamps.map((millis) => {
                const bet = contestMoneylineBetsByTimestamp[millis];
                return bet && bet.betType === BetType.HOME_TEAM_MONEYLINE ? bet.line : null;
              }),
              type: 'scatter',
              symbolSize: 20
            },
            {
              name: `Other Bets: ${game.awayTeam}`,
              data: allMoneylineHistoryTimestamps.map((millis) => {
                const bet = contestMoneylineBetsByTimestamp[millis];
                return bet && bet.betType === BetType.AWAY_TEAM_MONEYLINE ? bet.line : null;
              }),
              type: 'scatter',
              symbolSize: 20
            }
          ],
          tooltip: {
            trigger: 'axis'
          }
        } as EChartsOption);
      }

      const overUnderHistory = oddsHistory
        .map((odds) => {
          return {
            overUnderLine: odds.overUnderLine,
            timestamp: odds.updatedTime
          } as OverUnderHistory;
        })
        .filter(isOverUnderHistoryValid);

      if (overUnderHistory && overUnderHistory.length) {
        const myOverUnderBets = !userBets
          ? []
          : userBets.filter((bet) => bet.betType === BetType.OVER || bet.betType === BetType.UNDER);

        const contestOverUnderBets = !contestBets
          ? []
          : contestBets.filter(
              (bet) => bet.betType === BetType.OVER || bet.betType === BetType.UNDER
            );

        const allOverUnderHistoryTimestamps = [
          ...myOverUnderBets.map((bet) => bet.timestamp.toMillis()),
          ...contestOverUnderBets.map((bet) => bet.timestamp.toMillis()),
          ...overUnderHistory.map((spread) => spread.timestamp.toMillis())
        ].sort((a, b) => a - b);

        const myOverUnderBetsByTimestamp = myOverUnderBets.reduce((acc, bet) => {
          acc[bet.timestamp.toMillis()] = bet;
          return acc;
        }, {} as { [key: number]: Bet });

        const contestOverUnderBetsByTimestamp = contestOverUnderBets.reduce((acc, bet) => {
          acc[bet.timestamp.toMillis()] = bet;
          return acc;
        }, {} as { [key: number]: Bet });

        const overUnderHistoryByTimestamp = overUnderHistory.reduce((acc, overUnder) => {
          acc[overUnder.timestamp.toMillis()] = overUnder;
          return acc;
        }, {} as { [key: number]: OverUnderHistory });

        setOverUnderHistoryChartOptions({
          grid: { top: 8, right: 8, bottom: 24, left: 36 },
          xAxis: {
            type: 'category',
            data: allOverUnderHistoryTimestamps.map((millis) =>
              formatTimestamp(Timestamp.fromMillis(millis), DateTime.DATETIME_SHORT)
            )
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: 'Over/Under',
              data: allOverUnderHistoryTimestamps.map((millis, index) => {
                const overUnderHistory: OverUnderHistory = overUnderHistoryByTimestamp[millis];
                while (index > 0 && !overUnderHistory) {
                  index--;
                  const previousOverUnderHistory = overUnderHistoryByTimestamp[
                    allOverUnderHistoryTimestamps[index]
                  ]
                    ? overUnderHistoryByTimestamp[allOverUnderHistoryTimestamps[index]]
                        .overUnderLine
                    : null;
                  if (previousOverUnderHistory) {
                    return previousOverUnderHistory;
                  }
                }
                return overUnderHistory ? overUnderHistory.overUnderLine : null;
              }),
              type: 'line',
              smooth: true,
              connectNulls: true
            },
            {
              name: 'My Bet: Over',
              data: allOverUnderHistoryTimestamps.map((millis) => {
                const bet = myOverUnderBetsByTimestamp[millis];
                return bet && bet.betType === BetType.OVER ? bet.line : null;
              }),
              type: 'scatter',
              symbolSize: 20
            },
            {
              name: 'My Bet: Under',
              data: allOverUnderHistoryTimestamps.map((millis) => {
                const bet = myOverUnderBetsByTimestamp[millis];
                return bet && bet.betType === BetType.UNDER ? bet.line : null;
              }),
              type: 'scatter',
              symbolSize: 20
            },
            {
              name: 'Other Bets: Over',
              data: allOverUnderHistoryTimestamps.map((millis) => {
                const bet = contestOverUnderBetsByTimestamp[millis];
                return bet && bet.betType === BetType.OVER ? bet.line : null;
              }),
              type: 'scatter',
              symbolSize: 20
            },
            {
              name: 'Other Bets: Under',
              data: allOverUnderHistoryTimestamps.map((millis) => {
                const bet = contestOverUnderBetsByTimestamp[millis];
                return bet && bet.betType === BetType.UNDER ? bet.line : null;
              }),
              type: 'scatter',
              symbolSize: 20
            }
          ],
          tooltip: {
            trigger: 'axis'
          }
        } as EChartsOption);
      }
    }

    setLoadingScoreHistory(false);
    setLoadingOddsHistory(false);
  };

  useEffect(() => {
    loadOddsHistory();
  }, []);

  return (
    <>
      {(errorOddsHistory || errorScoreHistory) && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <i className="pi pi-exclamation-circle" style={{ fontSize: '1em', color: '#ef9a9a' }}></i>
          <span style={{ marginLeft: '.25em', color: '#ef9a9a' }}>
            Unable to load game history.
          </span>
        </div>
      )}
      {!(errorOddsHistory || errorScoreHistory) && (loadingOddsHistory || loadingScoreHistory) && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <i
            className="pi pi-spin pi-spinner"
            style={{ fontSize: '1em' }}
            title="Loading game history..."
          ></i>
        </div>
      )}
      {!(errorOddsHistory || errorScoreHistory) && !(loadingOddsHistory || loadingScoreHistory) && (
        <TabView
          activeIndex={
            getCurrentGameStatus(game) === GameStatus.IN_PROGRESS ||
            getCurrentGameStatus(game) === GameStatus.COMPLETE
              ? 0
              : 1
          }
        >
          {(getCurrentGameStatus(game) === GameStatus.IN_PROGRESS ||
            getCurrentGameStatus(game) === GameStatus.COMPLETE) && (
            <TabPanel header="Score">
              {scoreHistoryChartOptions && (
                <ReactECharts option={scoreHistoryChartOptions}></ReactECharts>
              )}
              {!scoreHistoryChartOptions && (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <i className="pi pi-info-circle" style={{ fontSize: '1em' }}></i>
                  <span style={{ marginLeft: '.25em' }}>No score history available.</span>
                </div>
              )}
            </TabPanel>
          )}
          <TabPanel header="Spread">
            {spreadHistoryChartOptions && (
              <ReactECharts option={spreadHistoryChartOptions}></ReactECharts>
            )}
            {!spreadHistoryChartOptions && (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <i className="pi pi-info-circle" style={{ fontSize: '1em' }}></i>
                <span style={{ marginLeft: '.25em' }}>No spread history available.</span>
              </div>
            )}
          </TabPanel>
          <TabPanel header="Moneyline">
            {moneylineHistoryChartOptions && (
              <ReactECharts option={moneylineHistoryChartOptions}></ReactECharts>
            )}
            {!moneylineHistoryChartOptions && (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <i className="pi pi-info-circle" style={{ fontSize: '1em' }}></i>
                <span style={{ marginLeft: '.25em' }}>No moneyline history available.</span>
              </div>
            )}
          </TabPanel>
          <TabPanel header="Over/Under">
            {overUnderHistoryChartOptions && (
              <ReactECharts option={overUnderHistoryChartOptions}></ReactECharts>
            )}
            {!overUnderHistoryChartOptions && (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <i className="pi pi-info-circle" style={{ fontSize: '1em' }}></i>
                <span style={{ marginLeft: '.25em' }}>No over/under history available.</span>
              </div>
            )}
          </TabPanel>
        </TabView>
      )}
    </>
  );
};

export default GameHistory;
