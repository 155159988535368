import { Bet } from '../common/models/bet';
import { BetStatus } from '../common/models/bet-status';
import { calculateWinAmountForBet } from '../utils/bet-utils';
import { formatMoney } from '../utils/utils';
import IconWithTooltip from './IconWithTooltip';

export interface BetStatusIconProps {
  bet: Bet;
  showTooltip?: boolean;
}

export const BetStatusIcon = (props: BetStatusIconProps) => {
  const { bet, showTooltip } = props;
  const activeShowTooltip = showTooltip ?? true;
  let betStatusIcon;
  switch (bet.status) {
    case BetStatus.LOST:
      betStatusIcon = (
        <IconWithTooltip
          icon="pi-times-circle"
          tooltip={
            activeShowTooltip
              ? bet.amount !== undefined && bet.currency?.length
                ? `Bet lost (-${formatMoney(bet.amount, bet.currency)})`
                : 'Bet lost'
              : undefined
          }
          iconStyle={{ color: 'red', fontSize: '1.5em' }}
        ></IconWithTooltip>
      );
      break;
    case BetStatus.WON:
      betStatusIcon = (
        <IconWithTooltip
          icon="pi-check-circle"
          tooltip={
            activeShowTooltip
              ? bet.amount !== undefined && bet.currency?.length
                ? `Bet won (${formatMoney(calculateWinAmountForBet(bet), bet.currency)})`
                : 'Bet won'
              : undefined
          }
          iconStyle={{ color: 'green', fontSize: '1.5em' }}
        ></IconWithTooltip>
      );
      break;
    case BetStatus.PUSH:
      betStatusIcon = (
        <IconWithTooltip
          icon="pi-minus-circle"
          tooltip={activeShowTooltip ? 'Bet is a push' : undefined}
          iconStyle={{ color: 'blue', fontSize: '1.5em' }}
        ></IconWithTooltip>
      );
      break;
    default:
      betStatusIcon = (
        <IconWithTooltip
          icon="pi-question-circle"
          tooltip={activeShowTooltip ? 'Bet is pending' : undefined}
          iconStyle={{ fontSize: '1.5em' }}
        ></IconWithTooltip>
      );
      break;
  }
  return betStatusIcon;
};
