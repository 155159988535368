export enum Sport {
  ALL = 'ALL',
  NFL = 'NFL',
  NCAAF = 'NCAAF',
  NCAAB = 'NCAAB',
  NBA = 'NBA',
  NHL = 'NHL',
  MLB = 'MLB',
  XFL = 'XFL',
  MLB_PRESEASON = 'MLB_PRESEASON',
  NCAABASE = 'NCAABASE'
}

export enum SportName {
  ALL = 'All',
  NFL = 'NFL',
  NCAAF = 'NCAA Football',
  NCAAB = 'NCAA Mens Basketball',
  NBA = 'NBA',
  NHL = 'NHL',
  MLB = 'MLB',
  XFL = 'XFL',
  MLB_PRESEASON = 'MLB (Preseason)',
  NCAABASE = 'NCAA Baseball'
}

export const buildSportOptions = (availableSports: Sport[]): { [key: string]: string }[] => {
  return availableSports.map((sport) => {
    const name = SportName[sport as keyof typeof SportName];
    const option = { name: name, value: sport };
    return option;
  });
};
