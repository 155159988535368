import { Timestamp } from 'firebase/firestore';
import { createContext, ReactNode, useContext, useState } from 'react';
import useAuth from '../auth/auth';
import { ParlayBet } from '../common/models/bet';
import { BetType } from '../common/models/bet-type';
import { Parlay } from '../common/models/parlay';

const appContestParlaysCookieName = 'ttp-app-contest-parlays';

export interface GlobalContestStateManagerContextType {
  getParlayForContest: (contestId: string) => Parlay | undefined;
  setParlayForContest: (contestId: string, parlay: Parlay) => void;
  clearParlayForContest: (contestId: string) => void;
  addGameToParlayForContest: (contestId: string, gameId: string) => void;
  removeGameFromParlayForContest: (contestId: string, gameId: string) => void;
  isGameInParlayForContest: (contestId: string | undefined, gameId: string) => boolean;
}

const GlobalContestStateManagerContext = createContext<GlobalContestStateManagerContextType>(
  {} as GlobalContestStateManagerContextType
);

export function GlobalContestStateManagerContextProvider({
  children
}: {
  children: ReactNode;
}): JSX.Element {
  const auth = useAuth();

  let parlaysByContestIdFromStorage;
  try {
    parlaysByContestIdFromStorage = JSON.parse(
      localStorage.getItem(appContestParlaysCookieName) || '{}'
    );
  } catch (error) {
    parlaysByContestIdFromStorage = {};
  }
  const [parlaysByContestId, setParlaysByContestId] = useState<{ [key: string]: Parlay }>(
    parlaysByContestIdFromStorage
  );

  const updateParlaysByContestId = (parlays: { [key: string]: Parlay }) => {
    setParlaysByContestId(parlays);
    localStorage.setItem(appContestParlaysCookieName, JSON.stringify(parlays));
  };

  const value = {
    getParlayForContest: (contestId: string): Parlay | undefined => {
      return parlaysByContestId[contestId];
    },
    setParlayForContest: (contestId: string, parlay: Parlay) => {
      const newParlaysByContestId = { ...parlaysByContestId };
      newParlaysByContestId[contestId] = parlay;
      updateParlaysByContestId(newParlaysByContestId);
    },
    clearParlayForContest: (contestId: string) => {
      const newParlaysByContestId = { ...parlaysByContestId };
      delete newParlaysByContestId[contestId];
      updateParlaysByContestId(newParlaysByContestId);
    },
    isGameInParlayForContest: (contestId: string | undefined, gameId: string): boolean => {
      if (!contestId) {
        return false;
      }
      const parlay = parlaysByContestId[contestId];
      if (parlay) {
        return parlay.bets.some((bet) => bet.gameId === gameId);
      }
      return false;
    },
    addGameToParlayForContest: (contestId: string, gameId: string) => {
      const parlay = parlaysByContestId[contestId];
      if (parlay && parlay.bets.some((bet) => bet.gameId === gameId)) {
        return;
      }
      const parlayBet = {
        contestId: contestId,
        gameId: gameId,
        timestamp: Timestamp.now()
      } as ParlayBet;
      if (parlay) {
        const newParlay = {
          ...parlay,
          bets: [...parlay.bets, parlayBet]
        } as Parlay;
        const newParlaysByContestId = { ...parlaysByContestId };
        newParlaysByContestId[contestId] = newParlay;
        updateParlaysByContestId(newParlaysByContestId);
      } else {
        const newParlay = {
          bet: {
            contestId: contestId,
            amount: 0,
            timestamp: Timestamp.now(),
            betType: BetType.PARLAY,
            owner: auth.user?.user?.email
          },
          bets: [parlayBet]
        } as Parlay;
        const newParlaysByContestId = { ...parlaysByContestId };
        newParlaysByContestId[contestId] = newParlay;
        updateParlaysByContestId(newParlaysByContestId);
      }
    },
    removeGameFromParlayForContest: (contestId: string, gameId: string) => {
      const parlay = parlaysByContestId[contestId];
      if (parlay) {
        const newParlay = {
          ...parlay,
          bets: parlay.bets.filter((bet) => bet.gameId !== gameId)
        } as Parlay;
        const newParlaysByContestId = { ...parlaysByContestId };
        newParlaysByContestId[contestId] = newParlay;
        updateParlaysByContestId(newParlaysByContestId);
      }
    }
  } as GlobalContestStateManagerContextType;

  return (
    <>
      <GlobalContestStateManagerContext.Provider value={value}>
        {children}
      </GlobalContestStateManagerContext.Provider>
    </>
  );
}

export default function useGlobalContestStateManagerContext(): GlobalContestStateManagerContextType {
  return useContext(GlobalContestStateManagerContext);
}
