import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { Sidebar } from 'primereact/sidebar';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../auth/auth';
import { Contest } from '../common/models/contest';
import { ContestStatus } from '../common/models/contest-status';
import { GameStatus } from '../common/models/game-status';
import { ContestBets } from '../components/ContestBets';
import { ContestInfo } from '../components/ContestInfo';
import { ContestStandings } from '../components/ContestStandings';
import GamesViewer from '../components/GamesViewer';
import IconButtonWithTooltip from '../components/IconButtonWithTooltip';
import PageHeader from '../components/PageHeader';
import { PerformanceSpotlightPanels } from '../components/PerformanceSpotlightPanels';
import useGlobalContestStateManagerContext from '../hooks/contest-state-manager';
import { getContest, isContestOwner, isUserActiveInContest } from '../utils/contest-utils';
import { ContestParlayBuilder } from './ContestParlayBuilder';
import { LiveGameActionViewerList } from '../components/LiveGameActionViewerList';

export interface ContestProps {
  contestId?: string;
  allowEditing?: boolean;
}

const Contests = (contestProps: ContestProps) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const contestStateManager = useGlobalContestStateManagerContext();

  const { contestId, allowEditing } = contestProps;
  const activeAllowEditing = !allowEditing ? false : allowEditing;

  let activeContestId: string | undefined;
  const params = useParams();
  if (params?.contestId) {
    activeContestId = params.contestId;
  } else {
    activeContestId = contestId;
  }

  const [invalidContestId, setInvalidContestId] = useState(false);

  const [loadingContest, setLoadingContest] = useState(true);
  const [errorContest, setErrorContest] = useState<string | undefined>();
  const [contest, setContest] = useState<Contest | undefined>();
  const [myBetsReloadNeeded, setMyBetsReloadNeeded] = useState(false);
  const [showContestDetails, setShowContestDetails] = useState(false);
  const [showContestStandings, setShowContestStandings] = useState(false);
  const [parlaySidebarVisible, setParlaySidebarVisible] = useState(false);
  // const [contestSummaryReloadNeeded, setContestSummaryReloadNeeded] = useState(false);

  const hasParlayInProgress = (): boolean => {
    if (contest?.id) {
      const parlay = contestStateManager.getParlayForContest(contest?.id);
      if (parlay?.bets?.length) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (hasParlayInProgress()) {
      setParlaySidebarVisible(true);
    } else {
      setParlaySidebarVisible(false);
    }
  }, [contestStateManager]);

  useEffect(() => {
    if (!activeContestId?.length) {
      setInvalidContestId(true);
      return;
    }
    setInvalidContestId(false);

    setLoadingContest(true);
    setErrorContest(undefined);
    getContest(activeContestId)
      .then((contest) => {
        setContest(contest);
        document.title = document.title + ' | ' + contest.name;
        setLoadingContest(false);
      })
      .catch((error) => {
        setErrorContest('Unable to load contest data.');
        console.error('Unable to load contest data.', error);
        setLoadingContest(false);
      });
  }, []);

  const pageHeaderSubtitleTemplate = () => {
    const contestInfo = (
      <IconButtonWithTooltip
        icon="pi pi-info-circle"
        tooltip="Contest Info"
        tooltipOptions={{ position: 'bottom' }}
        onClick={() => {
          setShowContestDetails(true);
        }}
      />
    );

    const standings = (
      <IconButtonWithTooltip
        icon="pi pi-sort-numeric-down"
        tooltip="Standings"
        tooltipOptions={{ position: 'bottom' }}
        onClick={() => {
          setShowContestStandings(true);
        }}
      />
    );

    const edit = (
      <IconButtonWithTooltip
        icon="pi pi-file-edit"
        tooltip="Edit Contest"
        tooltipOptions={{ position: 'bottom' }}
        onClick={() => navigate(`/contests/${contest?.id}/edit`)}
      />
    );

    const parlay = (
      <IconButtonWithTooltip
        icon="pi-list"
        tooltip="Build a Parlay"
        tooltipOptions={{ position: 'bottom' }}
        disabled={!hasParlayInProgress()}
        onClick={() => {
          setParlaySidebarVisible(!parlaySidebarVisible);
        }}
      />
    );

    return (
      <div className="flex flex-row align-items-center">
        {contestInfo}
        {standings}
        {parlay}
        {contest && isContestOwner(auth, contest) && activeAllowEditing && edit}
      </div>
    );
  };

  return (
    <>
      {(errorContest || invalidContestId) && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <i className="pi pi-exclamation-circle" style={{ fontSize: '1em', color: '#ef9a9a' }}></i>
          <span style={{ marginLeft: '.25em', color: '#ef9a9a' }}>Unable to load contest.</span>
        </div>
      )}
      {!(errorContest || invalidContestId) && loadingContest && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <i
            className="pi pi-spin pi-spinner"
            style={{ fontSize: '1em' }}
            title="Loading contest..."
          ></i>
        </div>
      )}
      {!(errorContest || invalidContestId) && !loadingContest && contest && (
        <>
          <Sidebar
            position="right"
            visible={parlaySidebarVisible}
            onHide={() => setParlaySidebarVisible(false)}
          >
            <ContestParlayBuilder
              contest={contest}
              onParlaySaved={() => {
                setMyBetsReloadNeeded(true);
                setParlaySidebarVisible(false);
                setTimeout(() => {
                  contestStateManager.clearParlayForContest(
                    contest.id ? contest.id : 'INVALID_CONTEST_ID'
                  );
                }, 500);
              }}
            ></ContestParlayBuilder>
          </Sidebar>
          <Dialog
            header="Contest Info"
            visible={showContestDetails}
            style={{ width: '95vw' }}
            onHide={() => setShowContestDetails(false)}
          >
            <ContestInfo contest={contest}></ContestInfo>
          </Dialog>
          <Dialog
            header="Standings"
            visible={showContestStandings}
            style={{ width: '95vw' }}
            onHide={() => setShowContestStandings(false)}
          >
            <ContestStandings contest={contest}></ContestStandings>
          </Dialog>
          <PageHeader title={contest.name} subtitleTemplate={pageHeaderSubtitleTemplate()} />

          <div
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '.25em',
              marginBottom: '1em'
            }}
          >
            <PerformanceSpotlightPanels contest={contest}></PerformanceSpotlightPanels>
          </div>

          <div className="grid">
            <div className="col-12">
              <Panel header="Live Action" toggleable>
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    justifyContent: 'flex-start',
                    alignItems: 'top',
                    gap: '.25em',
                    marginBottom: '1em'
                  }}
                >
                  <LiveGameActionViewerList contest={contest}></LiveGameActionViewerList>
                </div>
              </Panel>
            </div>
          </div>

          <div className="grid">
            <div className="col-12">
              {/* <div className="col-12"> */}
              <Panel header="Bets" toggleable>
                <ContestBets
                  contest={contest}
                  showSelf={true}
                  forceRefresh={myBetsReloadNeeded}
                  onRefreshComplete={() => setMyBetsReloadNeeded(false)}
                ></ContestBets>
              </Panel>
            </div>
            {contest.status === ContestStatus.ACTIVE && (
              <>
                {isUserActiveInContest(auth, contest) && (
                  <div className="col-12">
                    <Panel header="Available Games" toggleable>
                      <GamesViewer
                        pageSize={5}
                        gameStatuses={[GameStatus.UPCOMING]}
                        sortOrder="asc"
                        sports={contest.sports}
                        teams={contest.teams}
                        startDate={contest.start}
                        endDate={contest.end}
                        contest={contest}
                        onBetPlaced={() => {
                          setMyBetsReloadNeeded(true);
                          // setContestSummaryReloadNeeded(true);
                        }}
                      ></GamesViewer>
                    </Panel>
                  </div>
                )}

                <div className="col-12">
                  <Panel header="Recent Games" toggleable>
                    <GamesViewer
                      pageSize={5}
                      gameStatuses={[
                        GameStatus.COMPLETE,
                        GameStatus.IN_PROGRESS,
                        GameStatus.UNAVAILABLE
                      ]}
                      sortOrder="desc"
                      sports={contest.sports}
                      teams={contest.teams}
                      startDate={contest.start}
                      endDate={contest.end}
                    ></GamesViewer>
                  </Panel>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Contests;
