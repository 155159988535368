import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  query,
  QueryConstraint,
  QuerySnapshot,
  setDoc,
  Timestamp,
  where
} from 'firebase/firestore';
import { AuthContextType } from '../auth/auth';
import { Bet } from '../common/models/bet';
import { BetStatus } from '../common/models/bet-status';
import { Contest, DEFAULT_MIN_NUM_BETS } from '../common/models/contest';
import { ContestSetup } from '../common/models/contest-setup';
import { ContestStanding } from '../common/models/contest-standing';
import { ContestStatus } from '../common/models/contest-status';
import { PoolSettings } from '../common/models/pool-settings';
import { Sport } from '../common/models/sport';
import { db } from '../firebase';
import { isAuthContextTypeValid } from './auth-utils';
import {
  calculateBalanceForContestBets,
  deleteBetsForContest,
  getBetsForContestByStatus
} from './bet-utils';
import { formatMoney } from './utils';
import { Season } from '../common/models/season';

export const buildContests = async (
  contestDocs: QuerySnapshot<DocumentData>
): Promise<Contest[]> => {
  if (!contestDocs) {
    throw new Error('Could not build contests');
  }
  const contests: Contest[] = [];
  for (const contestDoc of contestDocs.docs) {
    try {
      const contest = await buildContest(contestDoc);
      contests.push(contest);
    } catch (e) {
      console.error(`Error building contest ${contestDoc.id}`, e);
    }
  }
  return contests;
};

export const buildContest = async (
  contestDoc: DocumentSnapshot<DocumentData>
): Promise<Contest> => {
  if (!contestDoc.exists()) {
    throw new Error('Could not build contest, the contest does not exist');
  }

  const contest = contestDoc.data() as Contest;
  if (!contest) {
    throw new Error('Could not build contest');
  }

  if (contest?.contestSetupId) {
    const contestSetupRef = doc(db, 'contestSetups', contest.contestSetupId);
    const contestSetupDoc = await getDoc(contestSetupRef);
    const contestSetup = contestSetupDoc.data() as ContestSetup;
    if (contestSetup) {
      contest.start = contestSetup.start;
      contest.end = contestSetup.end;
      contest.sports = contestSetup.sports;
      contest.teams = contestSetup.teams;
      contest.contestSetupName = contestSetup.name;
    }
  }
  contest.id = contestDoc.id;
  return contest;
};

export const getContest = async (id: string): Promise<Contest> => {
  const contestRef = doc(db, 'contests', id);
  return await getDoc(contestRef).then(async (contestDoc) => {
    return await buildContest(contestDoc);
  });
};

export const getAllContests = async (): Promise<Contest[]> => {
  const contestsRef = collection(db, 'contests');
  const queryConstraints: QueryConstraint[] = [];
  const q = query(contestsRef, ...queryConstraints);
  const contestDocs = await getDocs(q);
  return await buildContests(contestDocs);
};

export const getContestsIOwn = async (auth: AuthContextType): Promise<Contest[]> => {
  if (!isAuthContextTypeValid(auth)) {
    throw new Error('User must be logged in and have a valid email');
  }
  const contestsRef = collection(db, 'contests');
  const queryConstraints = [];
  queryConstraints.push(where('owner', '==', auth?.user?.user?.email?.toLowerCase()));
  const q = query(contestsRef, ...queryConstraints);
  const contestDocs = await getDocs(q);
  return await buildContests(contestDocs);
};

export const getContestsImActiveIn = async (auth: AuthContextType): Promise<Contest[]> => {
  if (!isAuthContextTypeValid(auth)) {
    throw new Error('User must be logged in and have a valid email');
  }
  const contestsRef = collection(db, 'contests');
  const queryConstraints = [];
  queryConstraints.push(
    where('activeMembers', 'array-contains', auth?.user?.user?.email?.toLowerCase())
  );
  const q = query(contestsRef, ...queryConstraints);
  const contestDocs = await getDocs(q);
  return await buildContests(contestDocs);
};

export const getContestsImInvitedTo = async (auth: AuthContextType): Promise<Contest[]> => {
  if (!isAuthContextTypeValid(auth)) {
    throw new Error('User must be logged in and have a valid email');
  }
  const contestsRef = collection(db, 'contests');
  const queryConstraints = [];
  queryConstraints.push(
    where('invitedMembers', 'array-contains', auth?.user?.user?.email?.toLowerCase())
  );
  const q = query(contestsRef, ...queryConstraints);
  const contestDocs = await getDocs(q);
  return await buildContests(contestDocs);
};

export const saveContest = async (contest: Contest): Promise<void> => {
  if (!contest || !contest.id) {
    throw new Error('Contest must have an id');
  }
  const contestRef = doc(db, 'contests', contest.id);
  return setDoc(contestRef, contest);
};

export const deleteContest = async (
  contest: Contest,
  deleteAssociatedBets = true
): Promise<void> => {
  if (!contest || !contest.id) {
    throw new Error('Contest must have an id');
  }
  const contestRef = doc(db, 'contests', contest.id);
  if (!deleteAssociatedBets) {
    return deleteDoc(contestRef);
  }

  await deleteBetsForContest(contest.id);
  await deleteDoc(contestRef);
};

export const createContest = async (
  auth: AuthContextType,
  name: string,
  invitedMembers: string[],
  initialBalance: number,
  contestSetup?: ContestSetup,
  sports?: Sport[],
  teams?: { [key: string]: string[] },
  startDate?: Date,
  endDate?: Date,
  poolSettings?: PoolSettings,
  initialBalanceCurrency = 'USD'
): Promise<Contest> => {
  if (!isAuthContextTypeValid(auth)) {
    throw new Error('User must be logged in and have a valid email');
  }

  if (!name) {
    throw new Error('Contest name is required');
  }

  if (!initialBalance) {
    throw new Error('Initial balance is required');
  }

  if (!invitedMembers || invitedMembers.length < 2) {
    throw new Error('At least two invited members are required');
  }

  let contest: Contest;

  if (!contestSetup) {
    if (!sports?.length) {
      throw new Error('At least one sport is required');
    }

    if (!startDate) {
      throw new Error('Start date is required');
    }
    startDate.setHours(0, 0, 0, 0);

    if (!endDate) {
      throw new Error('End date is required');
    }
    endDate.setHours(23, 59, 59, 999);

    if (endDate < startDate) {
      throw new Error('End date must be after start date');
    }

    const activeTeams: { [key: string]: string[] } = {};
    sports.forEach((sport) => {
      const sportTeams = (teams ? teams : {})[sport];
      if (sportTeams && sportTeams.length > 0) {
        activeTeams[sport] = sportTeams;
      }
    });

    contest = {
      name,
      start: startDate ? Timestamp.fromDate(startDate) : undefined,
      end: endDate ? Timestamp.fromDate(endDate) : undefined,
      status: ContestStatus.ACTIVE,
      invitedMembers: invitedMembers.map((email) => email.toLowerCase()),
      activeMembers: invitedMembers.map((email) => email.toLowerCase()),
      owner: auth?.user?.user?.email?.toLowerCase(),
      sports: sports ? sports : [],
      teams: activeTeams,
      initialBalance,
      initialBalanceCurrency,
      poolSettings: poolSettings ? poolSettings : null
    } as Contest;
  } else {
    contest = {
      name,
      contestSetupId: contestSetup.id,
      contestSetupName: contestSetup.name,
      status: ContestStatus.ACTIVE,
      invitedMembers: invitedMembers.map((email) => email.toLowerCase()),
      activeMembers: invitedMembers.map((email) => email.toLowerCase()),
      owner: auth?.user?.user?.email?.toLowerCase(),
      initialBalance,
      initialBalanceCurrency,
      poolSettings: poolSettings ? poolSettings : {}
    } as Contest;
  }

  const contestsRef = collection(db, 'contests');
  const docRef = await addDoc(contestsRef, contest);
  const savedContest = { ...contest, id: docRef.id };
  return savedContest;
};

export const isContestOwner = (auth: AuthContextType, contest: Contest): boolean => {
  if (!contest || !contest.owner) {
    return false;
  }
  if (!isAuthContextTypeValid(auth)) {
    return false;
  }
  return auth?.user?.user?.email?.toLowerCase() === contest.owner.toLowerCase();
};

export const getAllContestSetups = async (): Promise<ContestSetup[]> => {
  const contestSetupsRef = collection(db, 'contestSetups');
  const q = query(contestSetupsRef);
  const contestSetupDocs = await getDocs(q);
  const contestSetups: ContestSetup[] = [];
  for (const contestSetupDoc of contestSetupDocs.docs) {
    const contestSetup = contestSetupDoc.data() as ContestSetup;
    if (contestSetup) {
      contestSetup.id = contestSetupDoc.id;
      contestSetups.push(contestSetup);
    }
  }
  return contestSetups.sort((a, b) => a.name.localeCompare(b.name));
};

export const getAllActiveUpcomingContestSetups = async (): Promise<ContestSetup[]> => {
  const contestSetupsRef = collection(db, 'contestSetups');
  const queryConstraints = [];
  const startOftoday = new Date();
  startOftoday.setHours(0, 0, 0, 0);
  startOftoday.setMinutes(0);
  queryConstraints.push(where('start', '>=', Timestamp.fromDate(startOftoday)));
  queryConstraints.push(where('active', '==', true));
  const q = query(contestSetupsRef, ...queryConstraints);
  const contestSetupDocs = await getDocs(q);
  const contestSetups: ContestSetup[] = [];
  for (const contestSetupDoc of contestSetupDocs.docs) {
    const contestSetup = contestSetupDoc.data() as ContestSetup;
    if (contestSetup) {
      contestSetup.id = contestSetupDoc.id;
      contestSetups.push(contestSetup);
    }
  }
  return contestSetups.sort((a, b) => a.name.localeCompare(b.name));
};

export const saveContestSetup = async (
  contestSetup: ContestSetup
): Promise<ContestSetup | undefined> => {
  if (!contestSetup) {
    throw new Error('Contest setup is required');
  }

  const currentEnd = contestSetup.end;
  const modifiedEnd = new Date(currentEnd?.toDate()).setHours(23, 59, 59, 999);
  contestSetup.end = Timestamp.fromDate(new Date(modifiedEnd));

  let savedContestSetup: ContestSetup | undefined;
  if (contestSetup.id?.length) {
    const contestSetupRef = doc(db, 'contestSetups', contestSetup.id);
    await setDoc(contestSetupRef, contestSetup);
    savedContestSetup = contestSetup;
  } else {
    const contestSetupsRef = collection(db, 'contestSetups');
    const docRef = await addDoc(contestSetupsRef, contestSetup);
    savedContestSetup = { ...contestSetup, id: docRef.id };
  }
  return savedContestSetup;
};

export const getContestSetup = async (
  contestSetupId: string
): Promise<ContestSetup | undefined> => {
  const contestSetupsRef = collection(db, 'contestSetups');
  const contestSetupDoc = await getDoc(doc(contestSetupsRef, contestSetupId));
  const contestSetup = contestSetupDoc.data() as ContestSetup;
  if (contestSetup) {
    contestSetup.id = contestSetupDoc.id;
  }
  return contestSetup;
};

export const getPoolPayoutByStandings = (
  hasPoolSettings: Contest | Season
): { [key: number]: number } => {
  const payouts: { [key: number]: number } = {};
  if (
    !hasPoolSettings ||
    !hasPoolSettings.activeMembers?.length ||
    !hasPoolSettings.poolSettings ||
    !hasPoolSettings.poolSettings.buyIn ||
    !hasPoolSettings.poolSettings.payoutByStandingsPosition
  ) {
    return payouts;
  }
  const totolPool = hasPoolSettings.activeMembers.length * hasPoolSettings.poolSettings.buyIn;

  const totalPayouts = Object.values(hasPoolSettings.poolSettings.payoutByStandingsPosition)
    .map((payout) => {
      if (payout === 'REMAINING') {
        return payout;
      } else if (payout === 'BUYIN') {
        return hasPoolSettings.poolSettings.buyIn;
      } else if (payout === 'HALFBUYIN') {
        return hasPoolSettings.poolSettings.buyIn / 2;
      } else if (payout === 'DOUBLEBUYIN') {
        return hasPoolSettings.poolSettings.buyIn * 2;
      } else {
        return payout;
      }
    })
    .filter((payout) => payout !== 'REMAINING' && !isNaN(payout))
    .reduce((a, b) => +a + +b, 0);
  Object.entries(hasPoolSettings.poolSettings.payoutByStandingsPosition).forEach((entry) => {
    const [position, payout] = entry;
    if (payout === 'REMAINING') {
      payouts[+position] = totolPool - +totalPayouts;
    } else if (payout === 'BUYIN') {
      payouts[+position] = hasPoolSettings.poolSettings.buyIn;
    } else if (payout === 'HALFBUYIN') {
      payouts[+position] = hasPoolSettings.poolSettings.buyIn / 2;
    } else if (payout === 'DOUBLEBUYIN') {
      payouts[+position] = hasPoolSettings.poolSettings.buyIn * 2;
    } else {
      payouts[+position] = +payout;
    }
  });
  const activePayouts: { [key: number]: number } = {};
  for (let i = 0; i < hasPoolSettings.activeMembers.length; i++) {
    activePayouts[i] = payouts[i];
  }
  return activePayouts;
};

export const getContestStandings = async (contest: Contest): Promise<ContestStanding[]> => {
  if (!contest) {
    return [];
  }
  const bets = await getBetsForContestByStatus(contest, [
    BetStatus.LOCKED,
    BetStatus.WON,
    BetStatus.LOST,
    BetStatus.PUSH
  ]);

  const standings = buildContestStandings(contest, bets);
  const activeStandings = standings.filter((standing) => standing.active && standing.qualifying);

  return activeStandings;
};

export const buildContestStandings = (contest: Contest, bets: Bet[]): ContestStanding[] => {
  if (!contest || !bets) {
    return [];
  }
  const betsByOwner: { [owner: string]: Bet[] } = {};
  bets.forEach((bet) => {
    if (!betsByOwner[bet.owner]) {
      betsByOwner[bet.owner] = [];
    }
    betsByOwner[bet.owner].push(bet);
  });

  const minNumBets =
    contest.minNumBets !== undefined && contest.minNumBets >= 0
      ? contest.minNumBets
      : DEFAULT_MIN_NUM_BETS;

  const contestStandings = contest.invitedMembers.map((member) => {
    const memberBets = betsByOwner[member];
    const active = contest.activeMembers?.includes(member);
    let winningBets = [];
    let losingBets = [];
    let pushedBets = [];
    let pendingBets = [];
    let balance = 0;
    if (memberBets) {
      balance = calculateBalanceForContestBets(contest, memberBets, false);
      winningBets = memberBets.filter((bet) => bet.status === BetStatus.WON);
      losingBets = memberBets.filter((bet) => bet.status === BetStatus.LOST);
      pushedBets = memberBets.filter((bet) => bet.status === BetStatus.PUSH);
      pendingBets = memberBets.filter(
        (bet) => bet.status === BetStatus.LOCKED || bet.status === BetStatus.PENDING
      );
    } else {
      balance = contest.initialBalance;
    }

    return {
      member: member,
      active: active,
      bets: memberBets ? memberBets : [],
      balance: balance,
      numBets: memberBets ? memberBets.length : 0,
      numWinningBets: winningBets.length,
      numLosingBets: losingBets.length,
      numPushedBets: pushedBets.length,
      numPendingBets: pendingBets.length,
      qualifying:
        (memberBets ? memberBets.length : 0) >=
        (minNumBets !== undefined && minNumBets >= 0 ? minNumBets : 0)
    } as ContestStanding;
  });

  const sortedStandings = contestStandings.sort((a: ContestStanding, b: ContestStanding) => {
    const aActiveBalance = a.active && a.qualifying ? a.balance : 0;
    const bActiveBalance = b.active && b.qualifying ? b.balance : 0;
    if (aActiveBalance === bActiveBalance) {
      const aWinPercentage = a.numBets ? a.numWinningBets / a.numBets : 0;
      const bWinPercentage = b.numBets ? b.numWinningBets / b.numBets : 0;
      if (aWinPercentage === bWinPercentage) {
        if (a.numBets === b.numBets) {
          return a.member.localeCompare(b.member);
        }
        return b.numBets - a.numBets;
      }
      return bWinPercentage - aWinPercentage;
    }
    return bActiveBalance - aActiveBalance;
  });

  if (!contest.poolSettings || !contest.poolSettings.payoutByStandingsPosition) {
    return sortedStandings;
  }

  const payoutByStandingsPosition = contest.poolSettings.payoutByStandingsPosition;

  const activeQualifiedStandings = sortedStandings.filter(
    (standing) => standing.active && standing.qualifying
  );

  const numPoolPayoutStandings = Object.keys(payoutByStandingsPosition).length;
  for (let i = numPoolPayoutStandings; i >= activeQualifiedStandings.length; i--) {
    delete payoutByStandingsPosition[i];
  }
  contest.poolSettings.payoutByStandingsPosition = payoutByStandingsPosition;

  const poolSettingsValidationError = validatePoolSettings(contest);
  if (poolSettingsValidationError) {
    console.warn(poolSettingsValidationError);
    return sortedStandings;
  }

  const poolPayoutByStandings = getPoolPayoutByStandings(contest);

  return sortedStandings.map((standing, index) => {
    const payout = poolPayoutByStandings[index];
    standing.poolBalance = payout ? payout : 0;
    return standing;
  });
};

export const getSuggestedContestMembers = async (auth: AuthContextType): Promise<string[]> => {
  const contestsImInvitedTo = await getContestsImInvitedTo(auth);
  if (!contestsImInvitedTo || !contestsImInvitedTo.length) {
    return [];
  }
  const suggestedMembers: { [member: string]: number } = {};
  contestsImInvitedTo.forEach((contest) => {
    if (!contest.activeMembers || !contest.activeMembers.length) {
      return;
    }
    contest.activeMembers.forEach((member) => {
      if (suggestedMembers[member]) {
        suggestedMembers[member] += 1;
      } else {
        suggestedMembers[member] = 1;
      }
    });
  });
  const sortedSuggestedMembers = Object.entries(suggestedMembers).sort((a, b) => b[1] - a[1]);
  return sortedSuggestedMembers.map((entry) => entry[0]);
};

export const isMemberActiveInContest = (
  email: string | undefined | null,
  contest: Contest
): boolean => {
  if (!contest || !email) {
    return false;
  }
  return contest.activeMembers?.includes(email) ? true : false;
};

export const isUserActiveInContest = (auth: AuthContextType, contest: Contest): boolean => {
  if (!isAuthContextTypeValid(auth) || !contest) {
    return false;
  }
  return isMemberActiveInContest(auth?.user?.user?.email, contest);
};

export const validatePoolSettings = (hasPoolSettings: Contest | Season): string | undefined => {
  if (!hasPoolSettings?.poolSettings) {
    return undefined;
  }

  if (!hasPoolSettings.activeMembers?.length) {
    return 'There are no active members in the contest';
  }

  const { buyIn, currency, payoutByStandingsPosition } = hasPoolSettings.poolSettings;
  if (!buyIn) {
    return 'The buy-in is missing';
  }
  if (!currency) {
    return 'The currency is missing';
  }
  if (!payoutByStandingsPosition) {
    return 'The payout structure is missing';
  }

  const poolValue = buyIn * hasPoolSettings.activeMembers.length;
  const poolPayoutByStandings = getPoolPayoutByStandings(hasPoolSettings);
  const totalPayouts = Object.values(poolPayoutByStandings)
    .filter((payout) => payout !== undefined)
    .reduce((a, b) => a + b, 0);

  if (totalPayouts !== poolValue) {
    return `The total payout (${formatMoney(
      totalPayouts,
      currency
    )}) does not match the total pool value (${formatMoney(poolValue, currency)})`;
  }
};
