import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { MyAccount } from './account/MyAccount';
import { Admin } from './admin/Admin';
import './App.css';
import useAuth from './auth/auth';
import { RequireAdmin } from './auth/RequireAdmin';
import { RequireAuth } from './auth/RequireAuth';
import { RequireCreator } from './auth/RequireCreator';
import { RequireSubscriber } from './auth/RequireSubscriber';
import SignIn from './auth/SignIn';
import Signout from './auth/Signout';
import { AppFooter } from './components/AppFooter';
import { AppHeader } from './components/AppHeader';
import { AdminContest } from './contests/AdminContest';
import { AdminContests } from './contests/AdminContests';
import Contest from './contests/Contest';
import Contests from './contests/Contests';
import { ContestSetups } from './contests/ContestSetups';
import EditContest from './contests/EditContest';
import { EditContestSetup } from './contests/EditContestSetup';
import NewContest from './contests/NewContest';
import { NewContestSetup } from './contests/NewContestSetup';
import Research from './research/Research';
import { Teams } from './admin/Teams';
import { Seasons } from './seasons/Seasons';
import { Season } from './seasons/Season';
import { Games } from './admin/Games';

const DEFAULT_PAGE_TITLE = 'Take The Points';
const DEFAULT_SHORT_PAGE_TITLE = 'TTP';

interface PageProps {
  title: string;
  children: JSX.Element;
}

function Page({ title, children }: PageProps) {
  document.title = title?.length ? `${DEFAULT_SHORT_PAGE_TITLE} | ${title}` : DEFAULT_PAGE_TITLE;
  return children;
}

function Redirect({ to }: { to: string }) {
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (!auth?.user?.user?.email?.length) {
      navigate('/signin');
    } else {
      navigate(to);
    }
  }, []);
  return <></>;
}

function App() {
  return (
    <div className="App">
      <AppHeader />
      <div className="App-content">
        <Routes>
          <Route
            path="/"
            index
            element={
              <Redirect to="/contests" />
              // <Page title="Home">
              //   <Welcome />
              // </Page>
            }
          />
          <Route
            path="/welcome"
            index
            element={
              <Redirect to="/contests" />
              // <Page title="Home">
              //   <Welcome />
              // </Page>
            }
          />
          <Route
            path="/signin"
            element={
              <Page title="Sign in">
                <SignIn />
              </Page>
            }
          />
          <Route
            path="/signout"
            element={
              <RequireAuth>
                <Page title="Sign out">
                  <Signout />
                </Page>
              </RequireAuth>
            }
          />
          <Route
            path="/home"
            element={
              <RequireAuth>
                <Redirect to="/contests" />
                {/* <Page title="Home">
                  <Home />
                </Page> */}
              </RequireAuth>
            }
          />
          <Route
            path="/admin"
            element={
              <RequireAdmin>
                <Page title="Admin">
                  <Admin />
                </Page>
              </RequireAdmin>
            }
          />
          <Route
            path="/teams"
            element={
              <RequireAdmin>
                <Page title="Teams">
                  <Teams />
                </Page>
              </RequireAdmin>
            }
          />
          <Route
            path="/games"
            element={
              <RequireAdmin>
                <Page title="Games">
                  <Games />
                </Page>
              </RequireAdmin>
            }
          />
          <Route
            path="/seasons"
            element={
              <RequireAuth>
                <RequireSubscriber>
                  <Page title="Seasons">
                    <Seasons />
                  </Page>
                </RequireSubscriber>
              </RequireAuth>
            }
          />
          <Route
            path="/seasons/:seasonId"
            element={
              <RequireAuth>
                <RequireSubscriber>
                  <Page title="Season">
                    <Season />
                  </Page>
                </RequireSubscriber>
              </RequireAuth>
            }
          />
          <Route
            path="/contests"
            element={
              <RequireAuth>
                <RequireSubscriber>
                  <Page title="Contests">
                    <Contests />
                  </Page>
                </RequireSubscriber>
              </RequireAuth>
            }
          />
          <Route
            path="/contests/admin"
            element={
              <RequireAdmin>
                <Page title="Contests Admin">
                  <AdminContests />
                </Page>
              </RequireAdmin>
            }
          />
          <Route
            path="/contests/new"
            element={
              <RequireAuth>
                <RequireCreator>
                  <Page title="New Contest">
                    <NewContest />
                  </Page>
                </RequireCreator>
              </RequireAuth>
            }
          />
          <Route
            path="/contests/:contestId"
            element={
              <RequireAuth>
                <RequireSubscriber>
                  <Page title="Contest">
                    <Contest allowEditing={true} />
                  </Page>
                </RequireSubscriber>
              </RequireAuth>
            }
          />
          <Route
            path="/contests/:contestId/edit"
            element={
              <RequireAuth>
                <RequireCreator>
                  <Page title="Edit Contest">
                    <EditContest />
                  </Page>
                </RequireCreator>
              </RequireAuth>
            }
          />
          <Route
            path="/contests/:contestId/admin"
            element={
              <RequireAdmin>
                <Page title="Contest Admin">
                  <AdminContest />
                </Page>
              </RequireAdmin>
            }
          />
          <Route
            path="/contest-setups"
            element={
              <RequireAdmin>
                <Page title="Contest Setups">
                  <ContestSetups />
                </Page>
              </RequireAdmin>
            }
          />
          <Route
            path="/contest-setups/:contestSetupId/edit"
            element={
              <RequireAdmin>
                <Page title="Edit Contest Setup">
                  <EditContestSetup />
                </Page>
              </RequireAdmin>
            }
          />
          <Route
            path="/contest-setups/new"
            element={
              <RequireAdmin>
                <Page title="Create Contest Setup">
                  <NewContestSetup />
                </Page>
              </RequireAdmin>
            }
          />
          <Route
            path="/research"
            element={
              <RequireAuth>
                <RequireSubscriber>
                  <Page title="Research">
                    <Research />
                  </Page>
                </RequireSubscriber>
              </RequireAuth>
            }
          />
          <Route
            path="/account"
            element={
              <RequireAuth>
                <Page title="My Account">
                  <MyAccount />
                </Page>
              </RequireAuth>
            }
          />
          <Route
            path="*"
            element={
              <RequireAuth>
                <Redirect to="/contests" />
                {/* <Page title="Home">
                  <Home />
                </Page> */}
              </RequireAuth>
            }
          />
        </Routes>
      </div>
      <AppFooter />
    </div>
  );
}

export default App;
