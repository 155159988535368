import { Timestamp } from 'firebase/firestore';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { useEffect, useMemo, useState } from 'react';
import { Contest } from '../common/models/contest';
import { Game } from '../common/models/game';
import { GameStatus } from '../common/models/game-status';
import { Sport } from '../common/models/sport';
import { getAllGamesWhereSportAndTeamsAndStatusAndDate } from '../utils/game-utils';
import GameFilter, { GameFilters } from './GameFilter';
import GameSummary from './GameSummary';
import { getUniqueListByKey } from '../utils/utils';
import { InputText } from 'primereact/inputtext';
import * as _ from 'lodash';

export interface GamesViewerProps {
  sports?: Sport[];
  teams?: { [key: string]: string[] };
  pageSize?: number;
  gameStatuses?: GameStatus[];
  sortOrder?: 'asc' | 'desc';
  startDate?: Timestamp;
  endDate?: Timestamp;
  contest?: Contest;
  onBetPlaced?: () => void;
}

const GamesViewer = (props: GamesViewerProps) => {
  const {
    sports,
    teams,
    pageSize,
    gameStatuses,
    sortOrder,
    startDate,
    endDate,
    contest,
    onBetPlaced
  } = props;
  const activePageSize = pageSize || 10;
  const activeGameStatuses = gameStatuses?.length ? gameStatuses : Object.values(GameStatus);
  const activeSortBy = 'startTime';
  const activeSortOrder = sortOrder || 'asc';

  const [games, setGames] = useState<Game[]>([]);
  const [allGames, setAllGames] = useState<Game[]>([]);
  const [loadingGames, setLoadingGames] = useState(true);
  const [errorGames, setErrorGames] = useState<string | undefined>();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(activePageSize);

  const [selectedSport, setSelectedSport] = useState<Sport | undefined>();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const loadGames = async () => {
    setLoadingGames(true);
    setErrorGames(undefined);
    getAllGamesWhereSportAndTeamsAndStatusAndDate(
      selectedSport,
      teams,
      activeGameStatuses,
      startDate ? startDate : contest?.start,
      endDate ? endDate : contest?.end
    )
      .then((allGames) => {
        allGames = getUniqueListByKey(allGames, 'id');
        const sortedGames = allGames.sort((a: Game, b: Game) =>
          activeSortOrder === 'asc'
            ? a[activeSortBy] > b[activeSortBy]
              ? 1
              : -1
            : a[activeSortBy] < b[activeSortBy]
            ? 1
            : -1
        );
        setAllGames(allGames);
        const filteredGames = searchGames(sortedGames, searchTerm);
        setGames(filteredGames);
        setFirst(0);
        setLoadingGames(false);
      })
      .catch((error) => {
        console.error('Unable to load game data.', error);
        setErrorGames('Unable to load game data.');
        setLoadingGames(false);
      });
  };

  useEffect(() => {
    loadGames();
  }, [selectedSport]);

  const searchGames = (games: Game[], searchTerm: string) => {
    if (!searchTerm?.length) {
      return games;
    }
    return games.filter((game) => {
      return (
        game.awayTeam.toLowerCase().includes(searchTerm.toLowerCase()) ||
        game.homeTeam.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  useEffect(() => {
    const filteredGames = searchGames(allGames, searchTerm);
    setGames(filteredGames);
  }, [searchTerm]);

  const renderGame = (game: Game) => {
    if (!game) {
      return;
    }

    return (
      <div
        key={game.id}
        style={{
          border: '1px solid #d9d9d9',
          borderRadius: '4px',
          marginTop: '.5em',
          marginBottom: '.5em'
        }}
      >
        <GameSummary
          game={game}
          teamDetails={false}
          teamRankings={false}
          teamRecords={false}
          contest={contest}
          onBetPlaced={() => {
            if (onBetPlaced) {
              onBetPlaced();
            }
          }}
        />
      </div>
    );
  };

  const renderGameList = () => {
    if (loadingGames) {
      return <div>Loading games...</div>;
    }
    if (errorGames) {
      return <div>{errorGames}</div>;
    }
    if (!games?.length) {
      return <div>No games available.</div>;
    }
    const currGames = [];
    for (let i = first; i < games.length && i < rows + first; i++) {
      currGames.push(games[i]);
    }
    return <div>{currGames.map((game) => renderGame(game))}</div>;
  };

  const searchTermChangeHandler = useMemo(
    () =>
      _.debounce((e) => {
        setSearchTerm(e.target.value);
      }, 300),
    []
  );

  return (
    <div className="games-viewer">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ marginRight: '.5em' }}>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              placeholder="Search"
              style={{ minWidth: '4em', width: '100%' }}
              disabled={loadingGames || errorGames ? true : false}
              onChange={searchTermChangeHandler}
            />
          </span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <GameFilter
              sports={sports}
              onFilterChange={(gameFilters: GameFilters) => {
                setSelectedSport(gameFilters.sport);
              }}
            ></GameFilter>
          </div>
          <div>
            <Button
              aria-label="Reload Games"
              title="Reload Games"
              icon="pi pi-refresh"
              className="p-button-text"
              onClick={() => loadGames()}
            />
          </div>
        </div>
      </div>
      <div className="games-viewer-list" style={{ padding: '1em 0em 1em 0em' }}>
        {renderGameList()}
      </div>
      <div
        className="games-viewer-list-controls"
        style={{ display: 'flex', 'justifyContent': 'center' }}
      >
        <Paginator
          first={first}
          rows={rows}
          totalRecords={games.length}
          rowsPerPageOptions={[1, 2, 5, 10, 20]}
          onPageChange={(e) => {
            setFirst(e.first);
            setRows(e.rows);
          }}
          template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
        />
      </div>
    </div>
  );
};

export default GamesViewer;
