import { Panel } from 'primereact/panel';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Bet } from '../common/models/bet';
import { BetStatus } from '../common/models/bet-status';
import { Contest } from '../common/models/contest';
import { BetTable } from '../components/BetTable';
import PageHeader from '../components/PageHeader';
import { getBetsForContestByStatus } from '../utils/bet-utils';
import { getContest } from '../utils/contest-utils';

export interface AdminContestProps {
  contestId?: string;
}

export const AdminContest = (props: AdminContestProps) => {
  const { contestId } = props;

  let activeContestId: string | undefined;
  const params = useParams();
  if (params?.contestId) {
    activeContestId = params.contestId;
  } else {
    activeContestId = contestId;
  }

  const [invalidContestId, setInvalidContestId] = useState(false);

  const [loadingContest, setLoadingContest] = useState(true);
  const [errorContest, setErrorContest] = useState<string | undefined>();
  const [contest, setContest] = useState<Contest | undefined>();

  const [loadingBets, setLoadingBets] = useState(true);
  const [errorBets, setErrorBets] = useState<string | undefined>();
  const [bets, setBets] = useState<Bet[]>([]);

  useEffect(() => {
    if (!activeContestId?.length) {
      setInvalidContestId(true);
      return;
    }
    setInvalidContestId(false);

    setLoadingContest(true);
    setErrorContest(undefined);
    getContest(activeContestId)
      .then((contest) => {
        setContest(contest);
        document.title = document.title + ' | ' + contest.name;
        setLoadingContest(false);
      })
      .catch((error) => {
        setErrorContest('Unable to load contest data.');
        setLoadingContest(false);
        console.error('Unable to load contest data.', error);
      });
  }, []);

  useEffect(() => {
    if (!contest) {
      setLoadingBets(false);
      setErrorBets('No contest selected.');
      return;
    }

    setLoadingBets(true);
    setErrorBets(undefined);
    getBetsForContestByStatus(contest, [
      BetStatus.LOCKED,
      BetStatus.PENDING,
      BetStatus.WON,
      BetStatus.LOST,
      BetStatus.PUSH
    ])
      .then((bets) => {
        setBets(bets);
        setLoadingBets(false);
      })
      .catch((error) => {
        console.error('Unable to load bets.', error);
        setErrorBets('Unable to load bets.');
        setLoadingBets(false);
      });
  }, [contest]);

  return (
    <>
      {(errorContest || invalidContestId) && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <i className="pi pi-exclamation-circle" style={{ fontSize: '1em', color: '#ef9a9a' }}></i>
          <span style={{ marginLeft: '.25em', color: '#ef9a9a' }}>Unable to load contest.</span>
        </div>
      )}
      {!(errorContest || invalidContestId) && loadingContest && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <i
            className="pi pi-spin pi-spinner"
            style={{ fontSize: '1em' }}
            title="Loading contest..."
          ></i>
        </div>
      )}
      {!(errorContest || invalidContestId) && !loadingContest && contest && (
        <>
          <PageHeader title="Contest" subtitle={contest.name} />
          <div className="grid">
            <div className="col-12">
              <Panel header="All Bets" style={{ marginBottom: '1em' }} toggleable>
                {errorBets && (
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <i
                      className="pi pi-exclamation-circle"
                      style={{ fontSize: '1em', color: '#ef9a9a' }}
                    ></i>
                    <span style={{ marginLeft: '.25em', color: '#ef9a9a' }}>
                      Unable to load bets.
                    </span>
                  </div>
                )}
                {!errorBets && loadingBets && (
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <i
                      className="pi pi-spin pi-spinner"
                      style={{ fontSize: '1em' }}
                      title="Loading bets..."
                    ></i>
                  </div>
                )}
                {!errorBets && !loadingBets && bets && (
                  <BetTable bets={bets} contest={contest} showBetId={true}></BetTable>
                )}
              </Panel>
            </div>
          </div>
        </>
      )}
    </>
  );
};
