export interface LoadingBlockProps {
  message?: string;
}

export const LoadingBlock = (props: LoadingBlockProps) => {
  const { message } = props;
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <i
        className="pi pi-spin pi-spinner"
        style={{ fontSize: '1em' }}
        title={message?.length ? message : 'Loading...'}
      ></i>
      <span style={{ marginLeft: '.25em' }}>{message ? message : 'Loading...'}</span>
    </div>
  );
};
