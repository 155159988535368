import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { TieredMenu } from 'primereact/tieredmenu';
import { useEffect, useRef, useState } from 'react';
import { Bet } from '../common/models/bet';
import { BetStatus, compareBetStatus } from '../common/models/bet-status';
import { Game } from '../common/models/game';
import { calculateWinAmountForBet, describeBet, describeBetSmall } from '../utils/bet-utils';
import { getCurrentGameStatus, getGamesForBets } from '../utils/game-utils';
import { formatMoney, formatReallyShortTimestamp } from '../utils/utils';
import { ErrorBlock } from './ErrorBlock';
import IconWithTooltip from './IconWithTooltip';
import { LoadingBlock } from './LoadingBlock';

import { Timestamp } from 'firebase/firestore';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MenuItem } from 'primereact/menuitem';
import { Link } from 'react-router-dom';
import { BetType } from '../common/models/bet-type';
import { Contest } from '../common/models/contest';
import { compareGameStatus, GameStatus } from '../common/models/game-status';
import { Sport, SportName } from '../common/models/sport';
import useTeamDataManager from '../hooks/team-data-manager';
import { BetDetails } from './BetDetails';
import GameDetails from './GameDetails';
import { GameStatusIcon } from './GameStatusIcon';
import { TeamAvatar } from './TeamAvatar';
import { UserAvatar } from './UserAvatar';

interface BetWithGame extends Bet {
  game: Game;
}

export interface BetTableProps {
  bets: Bet[];
  contest?: Contest;
  disableBetLinks?: boolean;
  showBetId?: boolean;
}

export const BetTable = (props: BetTableProps) => {
  const { bets, contest, disableBetLinks, showBetId } = props;

  const teamDataManager = useTeamDataManager();

  const [gamesById, setGamesById] = useState<{ [key: string]: Game }>({});
  const [loadingGames, setLoadingGames] = useState(true);
  const [errorGames, setErrorGames] = useState<string | undefined>();
  const [allBetsWithGames, setAllBetsWithGames] = useState<BetWithGame[]>([]);
  const [betsWithGames, setBetsWithGames] = useState<BetWithGame[]>([]);
  const [sorting, setSorting] = useState<boolean>(false);
  const [filtering, setFiltering] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<
    | 'timestamp'
    | 'owner'
    | 'status'
    | 'game'
    | 'game.status'
    | 'game.startTime'
    | 'game.awayTeam'
    | 'game.homeTeam'
    | 'amount'
  >('game.startTime');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [detailGame, setDetailGame] = useState<Game | undefined>(undefined);
  const [showGameDetails, setShowGameDetails] = useState(false);
  const [detailBet, setDetailBet] = useState<Bet | undefined>(undefined);
  const [showBetDetails, setShowBetDetails] = useState(false);
  const filterMenuRef = useRef<TieredMenu>(null);
  const [filterMenuItems, setFilterMenuItems] = useState<MenuItem[]>([]);
  const [filterByGameId, setFilterByGameId] = useState<string | undefined>(undefined);
  const [filterByOwner, setFilterByOwner] = useState<string | undefined>(undefined);
  const [filterByStatus, setFilterByStatus] = useState<BetStatus | undefined>(undefined);
  const [filterByGameStatus, setFilterByGameStatus] = useState<GameStatus | undefined>(undefined);
  const [filterByBetType, setFilterByBetType] = useState<BetType | undefined>(undefined);
  const [filterByGameSport, setFilterByGameSport] = useState<Sport | undefined>(undefined);

  const sortByOptions = [
    { label: 'Date/Time Placed', value: 'timestamp' },
    { label: 'Member', value: 'owner' },
    { label: 'Bet Status', value: 'status' },
    { label: 'Game Status', value: 'game.status' },
    { label: 'Game', value: 'game' },
    { label: 'Game Time', value: 'game.startTime' },
    { label: 'Away Team', value: 'game.awayTeam' },
    { label: 'Home Team', value: 'game.homeTeam' },
    { label: 'Amount', value: 'amount' }
  ];

  const userTemplate = (bet: Bet) => {
    return (
      <div className="flex flex-row flex-align-center">
        <UserAvatar email={bet.owner} />
      </div>
    );
  };

  const betTemplate = (bet: Bet) => {
    return (
      <>
        <div className="hidden md:block" style={{ fontSize: 'normal' }}>
          {describeBetSmall(bet)}
        </div>
        <div className="md:hidden" style={{ fontSize: 'small' }}>
          {describeBet(bet, false)}
        </div>
      </>
    );
  };

  const amountTemplate = (bet: Bet) => {
    return (
      <>
        <div className="md:hidden" style={{ fontSize: 'small' }}>
          {bet.amount ? formatMoney(bet.amount, bet.currency) : '--'}
        </div>
        <div className="hidden md:block" style={{ fontSize: 'normal' }}>
          {bet.amount ? formatMoney(bet.amount, bet.currency) : '--'}
        </div>
      </>
    );
  };

  const betIdTemplate = (bet: Bet) => {
    return (
      <a
        href={`https://console.firebase.google.com/u/0/project/take-the-points-c13fa/firestore/data/~2Fbets~2F${bet.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {bet.id}
      </a>
    );
  };
  const gameTemplate = (bet: BetWithGame) => {
    if (bet.betType === BetType.PARLAY) {
      return <div>Multiple Games</div>;
    } else if (!bet.game) {
      console.error('Bet with no game: ' + bet.id);
      return <div>Game Not Found</div>;
    }

    return (
      <div>
        <div className="font-size-small">{SportName[bet.game.sport]}</div>
        <div className="font-size-small">{formatReallyShortTimestamp(bet.game.startTime)}</div>
        <div>{bet.game.awayTeam}</div>
        <div>@ {bet.game.homeTeam}</div>
      </div>
    );
  };

  const getBetStatusIcon = (bet: Bet, showTooltip?: boolean) => {
    const activeShowTooltip = showTooltip ?? true;
    let betStatusIcon;
    switch (bet.status) {
      case BetStatus.LOST:
        betStatusIcon = (
          <IconWithTooltip
            icon="pi-times-circle"
            tooltip={
              activeShowTooltip
                ? bet.amount !== undefined && bet.currency?.length
                  ? `Bet lost (-${formatMoney(bet.amount, bet.currency)})`
                  : 'Bet lost'
                : undefined
            }
            iconStyle={{ color: 'red', fontSize: '1.5em' }}
          ></IconWithTooltip>
        );
        break;
      case BetStatus.WON:
        betStatusIcon = (
          <IconWithTooltip
            icon="pi-check-circle"
            tooltip={
              activeShowTooltip
                ? bet.amount !== undefined && bet.currency?.length
                  ? `Bet won (${formatMoney(calculateWinAmountForBet(bet), bet.currency)})`
                  : 'Bet won'
                : undefined
            }
            iconStyle={{ color: 'green', fontSize: '1.5em' }}
          ></IconWithTooltip>
        );
        break;
      case BetStatus.PUSH:
        betStatusIcon = (
          <IconWithTooltip
            icon="pi-minus-circle"
            tooltip={activeShowTooltip ? 'Bet is a push' : undefined}
            iconStyle={{ color: 'blue', fontSize: '1.5em' }}
          ></IconWithTooltip>
        );
        break;
      default:
        betStatusIcon = (
          <IconWithTooltip
            icon="pi-question-circle"
            tooltip={activeShowTooltip ? 'Bet is pending' : undefined}
            iconStyle={{ fontSize: '1.5em' }}
          ></IconWithTooltip>
        );
        break;
    }
    return betStatusIcon;
  };

  const compactGameTemplate = (bet: BetWithGame) => {
    if (bet.betType === BetType.PARLAY) {
      return <div style={{ textAlign: 'center' }}>Multiple Games</div>;
    }

    const homeTeam =
      bet.game && bet.game.homeTeam
        ? teamDataManager.getTeam(bet.game.homeTeam, bet.game.sport)
        : undefined;
    const awayTeam =
      bet.game && bet.game.awayTeam
        ? teamDataManager.getTeam(bet.game.awayTeam, bet.game.sport)
        : undefined;
    let betOnAwayTeam = bet.teamName && awayTeam && bet.teamName === awayTeam.name;
    let betOnHomeTeam = bet.teamName && homeTeam && bet.teamName === homeTeam.name;
    if (!betOnAwayTeam && !betOnHomeTeam) {
      betOnAwayTeam = true;
      betOnHomeTeam = true;
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ opacity: betOnAwayTeam ? 1 : 0.25 }}>
          <TeamAvatar team={awayTeam}></TeamAvatar>
        </div>
        <div style={{ marginLeft: '.25em', opacity: betOnHomeTeam ? 1 : 0.25 }}>
          <TeamAvatar team={homeTeam}></TeamAvatar>
        </div>
      </div>
    );
  };

  const statusTemplate = (bet: BetWithGame) => {
    if (!bet || !bet.status) {
      bet.status = BetStatus.PENDING;
    }

    const gameStatus = getCurrentGameStatus(bet.game);

    const betStatusIcon = getBetStatusIcon(bet, true);

    return (
      <div className="flex flex-row flex-align-center">
        {bet.betType !== BetType.PARLAY && (
          <Link
            to="#"
            onClick={() => {
              setDetailGame(gamesById[bet.gameId ? bet.gameId : 'INVLAID_GAME_ID']);
              setShowGameDetails(true);
            }}
          >
            <GameStatusIcon
              status={gameStatus}
              showLabel={false}
              iconStyle={{ fontSize: '1.5em', marginRight: '.25em' }}
            />
          </Link>
        )}
        {bet.betType === BetType.PARLAY && (
          <IconWithTooltip
            icon="pi-list"
            tooltip="Multiple Games"
            iconStyle={{ fontSize: '1.4em', marginRight: '.25em' }}
          />
        )}
        {!disableBetLinks && (
          <Link
            to="#"
            onClick={() => {
              setDetailBet(bet);
              setShowBetDetails(true);
            }}
          >
            {betStatusIcon}
          </Link>
        )}
        {disableBetLinks && <>{betStatusIcon}</>}
      </div>
    );
  };

  useEffect(() => {
    setLoadingGames(true);
    setErrorGames(undefined);
    getGamesForBets(bets)
      .then((games) => {
        const gamesById: { [key: string]: Game } = {};
        games.forEach((game) => {
          gamesById[game.id] = game;
        });
        setGamesById(gamesById);

        const betsWithGames: BetWithGame[] = bets.map((bet) => {
          return {
            ...bet,
            game: gamesById[bet.gameId ? bet.gameId : 'INVLAID_GAME_ID']
          };
        });
        setAllBetsWithGames(betsWithGames);
        setSorting(true);
        const sortedBets = betsWithGames.sort((a, b) => compareBets(a, b, sortBy, sortDirection));
        setBetsWithGames(sortedBets);
        setLoadingGames(false);
        setTimeout(() => {
          setSorting(false);
        }, 500);
      })
      .catch((error) => {
        console.error('Unable to load games.', error);
        setErrorGames('Unable to load bet data.');
        setLoadingGames(false);
      });
  }, [bets]);

  const getBetWithGameValueForSort = (
    bet: BetWithGame,
    sortBy:
      | 'timestamp'
      | 'owner'
      | 'status'
      | 'game'
      | 'game.status'
      | 'game.startTime'
      | 'game.awayTeam'
      | 'game.homeTeam'
      | 'amount'
  ): any => {
    let value;
    switch (sortBy) {
      case 'game':
        value = bet.game ? bet.game.awayTeam + ' @ ' + bet.game.homeTeam : 'Parlay';
        break;
      case 'game.status':
        value = bet.game ? bet.game.status : GameStatus.UNAVAILABLE;
        break;
      case 'game.startTime':
        value =
          bet.game && bet.game.startTime ? bet.game.startTime.seconds : Timestamp.now().seconds;
        break;
      case 'game.awayTeam':
        value = bet.game ? bet.game.awayTeam : 'Parlay';
        break;
      case 'game.homeTeam':
        value = bet.game ? bet.game.homeTeam : 'Parlay';
        break;
      default:
        value = bet[sortBy];
        break;
    }
    return value;
  };

  const compareBets = (
    a: BetWithGame,
    b: BetWithGame,
    sortBy:
      | 'timestamp'
      | 'owner'
      | 'status'
      | 'game'
      | 'game.status'
      | 'game.startTime'
      | 'game.awayTeam'
      | 'game.homeTeam'
      | 'amount',
    sortDirection: 'asc' | 'desc'
  ): number => {
    const aValue = getBetWithGameValueForSort(a, sortBy);
    const bValue = getBetWithGameValueForSort(b, sortBy);

    let result;

    if (sortBy === 'game.status') {
      result =
        sortDirection === 'asc'
          ? compareGameStatus(aValue as GameStatus, bValue as GameStatus)
          : -1 * compareGameStatus(aValue as GameStatus, bValue as GameStatus);
    } else if (sortBy === 'status') {
      result =
        sortDirection === 'asc'
          ? compareBetStatus(aValue as BetStatus, bValue as BetStatus)
          : -1 * compareBetStatus(aValue as BetStatus, bValue as BetStatus);
    } else if (
      sortBy === 'owner' ||
      sortBy === 'game.awayTeam' ||
      sortBy === 'game.homeTeam' ||
      sortBy === 'game'
    ) {
      result =
        sortDirection === 'asc'
          ? (aValue as string).localeCompare(bValue as string)
          : -1 * (aValue as string).localeCompare(bValue as string);
    } else {
      if (sortDirection === 'asc') {
        result = aValue > bValue ? 1 : aValue === bValue ? 0 : -1;
      } else {
        result = aValue < bValue ? 1 : aValue === bValue ? 0 : -1;
      }
    }

    if (result === 0) {
      if (sortBy === 'game.startTime') {
        result = 0;
      } else {
        result = compareBets(a, b, 'game.startTime', 'desc');
      }
    }
    return result;
  };

  useEffect(() => {
    if (!betsWithGames) {
      return;
    }
    setSorting(true);
    const sortedBets = betsWithGames.sort((a, b) => compareBets(a, b, sortBy, sortDirection));
    setBetsWithGames(sortedBets);
    setTimeout(() => {
      setSorting(false);
    }, 500);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (!allBetsWithGames?.length) {
      return;
    }

    let filteredBets = allBetsWithGames;
    if (filterByOwner) {
      filteredBets = filteredBets.filter((bet) => bet.owner === filterByOwner);
    }
    if (filterByGameSport) {
      filteredBets = filteredBets.filter((bet) => bet.game && bet.game.sport === filterByGameSport);
    }
    if (filterByGameId) {
      filteredBets = filteredBets.filter((bet) => bet.game && bet.game.id === filterByGameId);
    }
    if (filterByStatus) {
      filteredBets = filteredBets.filter((bet) => bet.status === filterByStatus);
    }
    if (filterByGameStatus) {
      filteredBets = filteredBets.filter(
        (bet) => bet.game && bet.game.status === filterByGameStatus
      );
    }
    if (filterByBetType) {
      const filterTypes: BetType[] = [];
      if (filterByBetType === BetType.PARLAY) {
        filterTypes.push(BetType.PARLAY);
      } else if (
        filterByBetType === BetType.AWAY_TEAM_MONEYLINE ||
        filterByBetType === BetType.HOME_TEAM_MONEYLINE
      ) {
        filterTypes.push(BetType.AWAY_TEAM_MONEYLINE);
        filterTypes.push(BetType.HOME_TEAM_MONEYLINE);
      } else if (
        filterByBetType === BetType.AWAY_TEAM_SPREAD ||
        filterByBetType === BetType.HOME_TEAM_SPREAD
      ) {
        filterTypes.push(BetType.AWAY_TEAM_SPREAD);
        filterTypes.push(BetType.HOME_TEAM_SPREAD);
      } else if (filterByBetType === BetType.OVER || filterByBetType === BetType.UNDER) {
        filterTypes.push(BetType.OVER);
        filterTypes.push(BetType.UNDER);
      }

      filteredBets = filteredBets.filter((bet) => filterTypes.includes(bet.betType));
    }

    setFiltering(true);
    setTimeout(() => {
      setBetsWithGames(filteredBets);
      setFiltering(false);
    }, 500);
  }, [
    filterByOwner,
    filterByGameSport,
    filterByGameId,
    filterByStatus,
    filterByGameStatus,
    filterByBetType
  ]);

  useEffect(() => {
    if (!betsWithGames || !gamesById) {
      return;
    }
    const filterMenuItems = buildFilterMenuItems(betsWithGames, gamesById);
    setFilterMenuItems(filterMenuItems);
  }, [betsWithGames]);

  const buildFilterMenuItems = (bets: BetWithGame[], gamesById: { [key: string]: Game }) => {
    const menuItems: MenuItem[] = [];

    const gameStatusMenuItems = {
      label: 'Game Status',
      items: Object.keys(GameStatus).map((gameStatus) => {
        const isActiveFilter =
          filterByGameStatus && filterByGameStatus === (gameStatus as GameStatus);
        return {
          template: (item: any, options: any) => {
            return (
              <div className="p-2 flex flex-row align-items-center">
                <div className="mr-2">
                  <Checkbox
                    checked={isActiveFilter ? true : false}
                    onChange={(e) => {
                      setFilterByGameStatus(e.checked ? (gameStatus as GameStatus) : undefined);
                      options.onClick(e);
                    }}
                  />
                </div>
                <div className="" style={{ overflow: 'auto', textOverflow: 'ellipsis' }}>
                  <GameStatusIcon
                    status={gameStatus as GameStatus}
                    showLabel={true}
                    showTooltip={false}
                    iconStyle={{ fontSize: '1.5em', marginRight: '.25em' }}
                  />
                </div>
              </div>
            );
          }
        };
      })
    };
    menuItems.push(gameStatusMenuItems);

    const betTypeMenuItems = {
      label: 'Bet Type',
      items: [
        BetType.OVER,
        BetType.AWAY_TEAM_MONEYLINE,
        BetType.AWAY_TEAM_SPREAD,
        BetType.PARLAY
      ].map((betType) => {
        const isActiveFilter = filterByBetType && filterByBetType === (betType as BetType);
        return {
          template: (item: any, options: any) => {
            return (
              <div className="p-2 flex flex-row align-items-center">
                <div className="mr-2">
                  <Checkbox
                    checked={isActiveFilter ? true : false}
                    onChange={(e) => {
                      setFilterByBetType(e.checked ? (betType as BetType) : undefined);
                      options.onClick(e);
                    }}
                  />
                </div>
                <div
                  className="highlight p-2"
                  style={{ overflow: 'auto', textOverflow: 'ellipsis' }}
                >
                  <div style={{ overflow: 'auto', textOverflow: 'ellipsis' }}>
                    {betType === BetType.AWAY_TEAM_MONEYLINE ||
                    betType == BetType.HOME_TEAM_MONEYLINE
                      ? 'Moneyline'
                      : betType == BetType.AWAY_TEAM_SPREAD || betType == BetType.HOME_TEAM_SPREAD
                      ? 'Spread'
                      : betType == BetType.OVER || betType == BetType.UNDER
                      ? 'Over/Under'
                      : betType === BetType.PARLAY
                      ? 'Parlay'
                      : 'Other'}
                  </div>
                </div>
              </div>
            );
          }
        };
      })
    };
    menuItems.push(betTypeMenuItems);

    const betStatusMenuItems = {
      label: 'Bet Status',
      items: Object.keys(BetStatus).map((betStatus) => {
        const isActiveFilter = filterByStatus && filterByStatus === (betStatus as BetStatus);
        return {
          template: (item: any, options: any) => {
            return (
              <div className="p-2 flex flex-row align-items-center">
                <div className="mr-2">
                  <Checkbox
                    name="betStatus"
                    checked={isActiveFilter ? true : false}
                    onChange={(e) => {
                      setFilterByStatus(e.checked ? (betStatus as BetStatus) : undefined);
                      options.onClick(e);
                    }}
                  />
                </div>
                <div className="flex flex-row align-items-center">
                  {getBetStatusIcon({ status: betStatus } as Bet, false)}
                  <span className="ml-2">
                    {betStatus === BetStatus.LOCKED
                      ? 'Locked'
                      : betStatus === BetStatus.PENDING
                      ? 'Pending'
                      : betStatus === BetStatus.PUSH
                      ? 'Pushed'
                      : betStatus === BetStatus.LOST
                      ? 'Lost'
                      : 'Won'}
                  </span>
                </div>
              </div>
            );
          }
        };
      })
    };
    menuItems.push(betStatusMenuItems);

    const uniqueGameOwners = [
      ...new Set(bets.map((bet) => bet.owner).sort((a, b) => a.localeCompare(b)))
    ];
    const membersMenuItem = {
      label: 'Members',
      items: uniqueGameOwners.map((owner) => {
        const isActiveFilter = filterByOwner && filterByOwner === owner.toLowerCase();
        return {
          template: (item: any, options: any) => {
            return (
              <div className="p-2 flex flex-row align-items-center">
                <div className="mr-2">
                  <Checkbox
                    checked={isActiveFilter ? true : false}
                    onChange={(e) => {
                      setFilterByOwner(e.checked ? owner.toLowerCase() : undefined);
                      options.onClick(e);
                    }}
                  />
                </div>
                <UserAvatar email={owner} labelOnly={true} />
              </div>
            );
          }
        };
      })
    };
    menuItems.push(membersMenuItem);

    const uniqueSports = [
      ...new Set(
        Object.values(gamesById)
          .map((game) => game.sport)
          .sort((a, b) => a.localeCompare(b))
      )
    ];
    const sportMenuItems = {
      label: 'Sports',
      items: uniqueSports
        .sort((a: string, b: string) => SportName[a as Sport].localeCompare(SportName[b as Sport]))
        .filter((sport) => sport !== Sport.ALL)
        .filter((sport) =>
          filterByGameId ? (gamesById[filterByGameId].sport as Sport) === sport : true
        )
        .map((sport) => {
          const isActiveFilter = filterByGameSport && filterByGameSport === (sport as Sport);
          return {
            template: (item: any, options: any) => {
              return (
                <div className="p-2 flex flex-row align-items-center">
                  <div className="mr-2">
                    <Checkbox
                      checked={isActiveFilter ? true : false}
                      onChange={(e) => {
                        setFilterByGameSport(e.checked ? (sport as Sport) : undefined);
                        options.onClick(e);
                      }}
                    />
                  </div>
                  <div
                    className="highlight p-2"
                    style={{ overflow: 'auto', textOverflow: 'ellipsis' }}
                  >
                    <div style={{ overflow: 'auto', textOverflow: 'ellipsis' }}>
                      {SportName[sport as Sport]}
                    </div>
                  </div>
                </div>
              );
            }
          };
        })
    };
    menuItems.push(sportMenuItems);

    const gamesMenuItem = {
      label: 'Games',
      items: Object.values(gamesById)
        .filter((game) => (filterByGameSport ? (game.sport as Sport) === filterByGameSport : true))
        .sort((a: Game, b: Game) => {
          return b.startTime.seconds - a.startTime.seconds;
        })
        .map((game) => {
          const isActiveFilter = filterByGameId && filterByGameId === game.id;
          return {
            template: (item: any, options: any) => {
              return (
                <div className="p-2 flex flex-row align-items-center">
                  <div className="mr-2">
                    <Checkbox
                      checked={isActiveFilter ? true : false}
                      onChange={(e) => {
                        setFilterByGameId(e.checked ? game.id : undefined);
                        options.onClick(e);
                      }}
                    />
                  </div>
                  <div className="font-size-small">
                    <div style={{ overflow: 'auto', textOverflow: 'ellipsis' }}>
                      {game.awayTeam}
                    </div>
                    <div style={{ overflow: 'auto', textOverflow: 'ellipsis' }}>
                      @ {game.homeTeam}
                    </div>
                    <div style={{ overflow: 'auto', textOverflow: 'ellipsis' }}>
                      {formatReallyShortTimestamp(game.startTime)}
                    </div>
                  </div>
                </div>
              );
            }
          };
        })
    };
    menuItems.push(gamesMenuItem);

    menuItems.push({
      separator: true
    });

    menuItems.push({
      label: 'Clear Filters',
      command: () => {
        setFilterByOwner(undefined);
        setFilterByGameId(undefined);
        setFilterByStatus(undefined);
        setFilterByGameStatus(undefined);
      }
    });
    return menuItems;
  };

  return (
    <>
      <Dialog
        header={detailGame ? detailGame.awayTeam + ' @ ' + detailGame.homeTeam : 'Game Details'}
        visible={showGameDetails}
        style={{ width: '95vw' }}
        onHide={() => setShowGameDetails(false)}
      >
        {detailGame && contest && <GameDetails game={detailGame} contest={contest}></GameDetails>}
        {(!detailGame || !contest) && (
          <ErrorBlock message="Unable to load game details"></ErrorBlock>
        )}
      </Dialog>

      <Dialog
        header={
          detailBet && detailBet.betType === BetType.PARLAY ? 'Parlay Details' : 'Bet Details'
        }
        visible={showBetDetails}
        style={{ width: '95vw' }}
        onHide={() => setShowBetDetails(false)}
      >
        {detailBet && contest && <BetDetails bet={detailBet} contest={contest} />}
        {(!detailBet || !contest) && <ErrorBlock message="Unable to load bet details"></ErrorBlock>}
      </Dialog>

      {errorGames && <ErrorBlock message="Unable to load bet."></ErrorBlock>}
      {!errorGames && loadingGames && <LoadingBlock message="Loading bets..." />}
      {!loadingGames && !errorGames && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              marginBottom: '.5em'
            }}
          >
            <div style={{ maxWidth: '70%' }}>
              <div className="mb-1 font-size-small">Sort by</div>
              <div className="p-inputgroup">
                <Dropdown
                  value={sortBy}
                  onChange={(e) => setSortBy(e.value)}
                  options={sortByOptions}
                  optionLabel="label"
                  optionValue="value"
                  disabled={sorting || filtering}
                />
                <Button
                  icon="pi pi-sort-amount-up-alt"
                  outlined={sortDirection === 'desc'}
                  disabled={sorting || filtering || sortDirection === 'asc'}
                  title="Sort Ascending"
                  onClick={() => {
                    setSortDirection('asc');
                  }}
                ></Button>
                <Button
                  icon="pi pi-sort-amount-down"
                  outlined={sortDirection === 'asc'}
                  disabled={sorting || filtering || sortDirection === 'desc'}
                  title="Sort Descending"
                  onClick={() => {
                    setSortDirection('desc');
                  }}
                ></Button>
              </div>
            </div>
            <div>
              <TieredMenu model={filterMenuItems} popup ref={filterMenuRef} breakpoint="767px" />
              <Button
                icon="pi pi-filter"
                label="Filter"
                title="Filter"
                disabled={sorting || filtering || !filterMenuItems?.length}
                onClick={(e) => filterMenuRef.current?.toggle(e)}
              ></Button>
            </div>
          </div>
          {(sorting || filtering) && (
            <div className="mt-4">
              <LoadingBlock message="Sorting/Filtering bets..." />
            </div>
          )}
          {!sorting && !filtering && (
            <>
              <div className="hidden md:block">
                <DataTable
                  size="small"
                  paginator
                  paginatorTemplate={{
                    layout: 'PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown'
                  }}
                  rows={5}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  value={betsWithGames}
                >
                  <Column field="owner" body={userTemplate}></Column>
                  <Column header="Game" field="game" body={gameTemplate}></Column>
                  <Column header="Status" field="status" body={statusTemplate}></Column>
                  <Column header="Amount" field="amount" body={amountTemplate}></Column>
                  {showBetId && <Column header="Bet ID" field="id" body={betIdTemplate}></Column>}
                  <Column header="Bet" body={betTemplate}></Column>
                </DataTable>
              </div>
              <div className="md:hidden">
                <DataTable
                  size="small"
                  paginator
                  rows={5}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  paginatorTemplate={{
                    layout: 'PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown'
                  }}
                  value={betsWithGames}
                >
                  <Column field="owner" body={userTemplate}></Column>
                  <Column header="Status" field="status" body={statusTemplate}></Column>
                  <Column header="" field="status" body={compactGameTemplate}></Column>
                  <Column header="Bet" body={betTemplate}></Column>
                </DataTable>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
