import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../auth/auth';
import { Contest } from '../common/models/contest';
import { ErrorBlock } from '../components/ErrorBlock';
import { LoadingBlock } from '../components/LoadingBlock';
import PageHeader from '../components/PageHeader';
import { getContestsImInvitedTo } from '../utils/contest-utils';
import { ContestTable } from './ContestTable';

function Contests() {
  const navigate = useNavigate();
  const auth = useAuth();

  const [contests, setContests] = useState<Contest[]>([]);
  const [loadingContests, setLoadingContests] = useState(true);
  const [errorContests, setErrorContests] = useState<string>();

  const loadContestsImInvitedToOrActiveIn = () => {
    setLoadingContests(true);
    setErrorContests(undefined);
    getContestsImInvitedTo(auth)
      .then((contests) => {
        setContests(contests);
        setLoadingContests(false);
      })
      .catch((error) => {
        console.error('Unable to load contests I own.', error);
        setErrorContests('Unable to load contests.');
        setLoadingContests(false);
      });
  };

  useEffect(() => {
    loadContestsImInvitedToOrActiveIn();
  }, []);

  const newContestLink = (
    <Button
      label="New Contest"
      className="p-button-text"
      icon="pi pi-plus-circle"
      onClick={() => navigate('/contests/new')}
    />
  );

  if (errorContests) {
    return <ErrorBlock message={errorContests} />;
  } else if (loadingContests) {
    return <LoadingBlock />;
  } else {
    return (
      <>
        <PageHeader title="Contests" context={newContestLink} />
        <ContestTable
          contests={contests}
          onContestDeleted={loadContestsImInvitedToOrActiveIn}
          allowDelete={false}
          allowEditing={true}
        ></ContestTable>
      </>
    );
  }
}

export default Contests;
