import { ReactElement, ReactNode } from 'react';
import { ErrorBlock } from './ErrorBlock';
import IconWithTooltip from './IconWithTooltip';
import { InfoBlock } from './InfoBlock';
import { LoadingBlock } from './LoadingBlock';

export const NO_BETS_ERROR = 'NO_BETS';
export const UNQUALIFIED_ERROR = 'UNQUALIFIED';
export const INACTIVE_ERROR = 'INACTIVE';
export const UNKNOWN_ERROR = 'UNKNOWN';

export interface SpotlightPanelProps {
  title?: string;
  info?: string;
  infoTemplate?: ReactNode;
  value: string | number | ReactElement;
  loading?: boolean;
  error?: boolean | string;
}

export const SpotlightPanel = (props: SpotlightPanelProps) => {
  const { title, info, infoTemplate, value, loading, error } = props;

  return (
    <>
      <div
        style={{
          width: '7.625em',
          height: '7.625em',
          overflow: 'auto',
          textOverflow: 'ellipsis',
          borderRadius: '4px',
          border: '1px solid #dee2e6',
          background: '#ffffff',
          textAlign: 'center',
          fontSize: '1em',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          padding: '.5em .5em .5em .5em'
        }}
      >
        {title && title.length && (
          <div
            style={{
              textAlign: 'left',
              fontSize: '.75em',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div>{title}</div>
            {(infoTemplate || info?.length) && (
              <div style={{ minWidth: '2em' }}>
                <IconWithTooltip
                  wrapperStyle={{ justifyContent: 'end' }}
                  icon="pi pi-info-circle"
                  tooltip={info}
                  tooltipTemplate={infoTemplate}
                />
              </div>
            )}
          </div>
        )}
        {error && (
          <>
            {error === NO_BETS_ERROR ? (
              <InfoBlock message="Not yet available" />
            ) : error === INACTIVE_ERROR ? (
              <ErrorBlock
                message="Not active"
                tooltip="Contact the owner of the contest to find out you are not marked as active for this contest."
              />
            ) : error === UNQUALIFIED_ERROR ? (
              <ErrorBlock
                message="Not yet qualified"
                tooltip="To quality you must meet the minimum bet requirements for this contest."
              />
            ) : (
              <ErrorBlock />
            )}
          </>
        )}
        {!error && loading && <LoadingBlock />}
        {!error && !loading && value !== undefined && value != null && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              fontWeight: 600
            }}
          >
            {value}
          </div>
        )}
      </div>
    </>
  );
};
