import { BetType } from '../common/models/bet-type';
import { Game } from '../common/models/game';
import useTeamDataManager from '../hooks/team-data-manager';
import IconWithTooltip from './IconWithTooltip';
import { TeamAvatar } from './TeamAvatar';

export interface BetTypeIconProps {
  betType?: BetType;
  game?: Game;
}

export const BetTypeIcon = (props: BetTypeIconProps) => {
  const { betType, game } = props;
  const teamDataManager = useTeamDataManager();
  const awayTeam = game?.awayTeam ? teamDataManager.getTeam(game.awayTeam, game.sport) : undefined;
  const homeTeam = game?.homeTeam ? teamDataManager.getTeam(game.homeTeam, game.sport) : undefined;

  switch (betType) {
    case BetType.AWAY_TEAM_MONEYLINE:
      return awayTeam ? (
        <TeamAvatar team={awayTeam}></TeamAvatar>
      ) : (
        <IconWithTooltip icon="pi-dollar" tooltip="Moneyline" />
      );
    case BetType.HOME_TEAM_MONEYLINE:
      return homeTeam ? (
        <TeamAvatar team={homeTeam}></TeamAvatar>
      ) : (
        <IconWithTooltip icon="pi-dollar" tooltip="Moneyline" />
      );
    case BetType.AWAY_TEAM_SPREAD:
      return awayTeam ? (
        <TeamAvatar team={awayTeam}></TeamAvatar>
      ) : (
        <IconWithTooltip icon="pi-arrows-h" tooltip="Spread" />
      );
    case BetType.HOME_TEAM_SPREAD:
      return homeTeam ? (
        <TeamAvatar team={homeTeam}></TeamAvatar>
      ) : (
        <IconWithTooltip icon="pi-arrows-h" tooltip="Spread" />
      );
    case BetType.OVER:
      return <IconWithTooltip icon="pi-caret-up" tooltip="Over" />;
    case BetType.UNDER:
      return <IconWithTooltip icon="pi-caret-down" tooltip="Under" />;
    default:
      return <IconWithTooltip icon="pi-question" tooltip="Unknown" />;
  }
};
