import { DateTime } from 'luxon';
import { Divider } from 'primereact/divider';
import { useEffect, useState } from 'react';
import { Contest, DEFAULT_MIN_NUM_BETS } from '../common/models/contest';
import { getContestStatusName } from '../common/models/contest-status';
import { SportName } from '../common/models/sport';
import { getPoolPayoutByStandings, validatePoolSettings } from '../utils/contest-utils';
import { formatMoney, formatTimestamp, getOrdinal } from '../utils/utils';
import { ErrorBlock } from './ErrorBlock';
import { UserAvatar } from './UserAvatar';

export interface ContestInfoProps {
  contest: Contest;
}
export const ContestInfo = (props: ContestInfoProps) => {
  const { contest } = props;

  const [contestPoolSettingsValidationError, setContestPoolSettingsValidationError] = useState(
    validatePoolSettings(contest)
  );

  useEffect(() => {
    setContestPoolSettingsValidationError(validatePoolSettings(contest));
  }, [contest]);

  return (
    <>
      <div className="grid">
        <div className="col">Status</div>
        <div className="col" style={{ textAlign: 'right' }}>
          {getContestStatusName(contest.status)}
        </div>
      </div>
      <div className="grid flex-row-center">
        <div className="col">Owner</div>
        <div
          className="col"
          style={{ textAlign: 'right', justifyContent: 'end', alignItems: 'center' }}
        >
          {<UserAvatar email={contest.owner} />}
        </div>
      </div>
      <div className="grid flex-row-center">
        <div className="col">Invited Members</div>
        <div
          className="col hidden md:flex"
          style={{ textAlign: 'right', alignItems: 'center', justifyContent: 'end' }}
        >
          {contest?.invitedMembers?.map((member) => {
            return (
              <span key={member} style={{ padding: '.1em .1em .1em .1em' }}>
                <UserAvatar email={member} />
              </span>
            );
          })}
        </div>
        <div className="col md:hidden" style={{ textAlign: 'right' }}>
          {`${contest?.invitedMembers?.length} members`}
        </div>
      </div>
      <div className="grid flex-row-center">
        <div className="col">Active Members</div>
        <div
          className="col hidden md:flex"
          style={{ textAlign: 'right', alignItems: 'center', justifyContent: 'end' }}
        >
          {contest?.activeMembers?.map((member) => {
            return (
              <span key={member} style={{ padding: '.1em .1em .1em .1em' }}>
                <UserAvatar email={member} />
              </span>
            );
          })}
        </div>
        <div className="col md:hidden" style={{ textAlign: 'right' }}>
          {`${contest?.activeMembers?.length} members`}
        </div>
      </div>
      <div className="grid">
        <div className="col">Initial Balance</div>
        <div className="col" style={{ textAlign: 'right' }}>
          {formatMoney(contest.initialBalance, contest.initialBalanceCurrency)}
        </div>
      </div>
      {contest.contestSetupId !== undefined && (
        <div className="hidden sm:flex grid">
          <div className="col">Based on</div>
          <div className="col" style={{ textAlign: 'right' }}>
            {contest.contestSetupName?.length
              ? contest.contestSetupName
              : `Setup #${contest.contestSetupId}`}
          </div>
        </div>
      )}
      <div className="grid">
        <div className="col">Starts</div>
        <div className="col" style={{ textAlign: 'right' }}>
          {formatTimestamp(contest.start, DateTime.DATE_MED)}
        </div>
      </div>
      <div className="grid">
        <div className="col">Ends</div>
        <div className="col" style={{ textAlign: 'right' }}>
          {formatTimestamp(contest.end, DateTime.DATE_MED)}
        </div>
      </div>
      <div className="grid">
        <div className="col">Sports</div>
        <div className="col" style={{ textAlign: 'right' }}>
          {contest?.sports?.map((sport) => SportName[sport as keyof typeof SportName]).join(', ')}
        </div>
      </div>
      {(contest.minNumBets !== undefined && contest.minNumBets >= 0
        ? contest.minNumBets
        : DEFAULT_MIN_NUM_BETS) > 0 && (
        <div className="grid">
          <div className="col">Minimum # Bets</div>
          <div className="col" style={{ textAlign: 'right' }}>
            {contest.minNumBets !== undefined && contest.minNumBets >= 0
              ? contest.minNumBets
              : DEFAULT_MIN_NUM_BETS}
          </div>
        </div>
      )}
      {contest.poolSettings && <Divider className="mt-1" />}
      {contest.poolSettings && contestPoolSettingsValidationError && (
        <ErrorBlock
          message={`The pool settings are invalid: ${contestPoolSettingsValidationError}`}
        />
      )}
      {contest.poolSettings && !contestPoolSettingsValidationError && (
        <div className="grid">
          <div className="col">Pool Buy-in</div>
          <div className="col" style={{ textAlign: 'right' }}>
            {formatMoney(contest.poolSettings.buyIn, contest.poolSettings.currency)}
          </div>
        </div>
      )}
      {contest.poolSettings && !contestPoolSettingsValidationError && (
        <div className="grid">
          <div className="col">Total Pool Value</div>
          <div className="col" style={{ textAlign: 'right' }}>
            {formatMoney(
              contest.poolSettings.buyIn *
                (contest.activeMembers ? contest.activeMembers.length : 0),
              contest.poolSettings.currency
            )}
          </div>
        </div>
      )}
      {contest.poolSettings &&
        !contestPoolSettingsValidationError &&
        Object.entries(getPoolPayoutByStandings(contest))
          .filter(([, payout]) => payout !== undefined)
          .map((entry) => {
            const [position, payout] = entry;
            return (
              <div className="grid" key={position}>
                <div className="col">
                  Payout for {+position + 1}
                  {getOrdinal(+position + 1)}
                </div>
                <div className="col" style={{ textAlign: 'right' }}>
                  {formatMoney(payout, contest.poolSettings.currency)}
                </div>
              </div>
            );
          })}
    </>
  );
};
