import { useEffect, useState } from 'react';
import useAuth from '../auth/auth';
import { Bet } from '../common/models/bet';
import { Contest } from '../common/models/contest';
import {
  getNonPendingBetsForContest,
  getUserAndNonPendingBetsForContest,
  getUserBetsForContest
} from '../utils/bet-utils';
import { BetTable } from './BetTable';
import { ErrorBlock } from './ErrorBlock';
import { LoadingBlock } from './LoadingBlock';

export interface ContestBetProps {
  contest: Contest;
  showSelf?: boolean;
  showOnlySelf?: boolean;
  forceRefresh?: boolean;
  pageSize?: number;
  onRefreshComplete?: () => void;
}

const DEFAULT_SHOW_ONLY_SELF = false;
const DEFAULT_SHOW_SELF = true;

export const ContestBets = (props: ContestBetProps) => {
  const auth = useAuth();
  const { contest, showSelf, showOnlySelf, forceRefresh, onRefreshComplete } = props;
  const activeShowOnlySelf = showOnlySelf ?? DEFAULT_SHOW_ONLY_SELF;
  const activeShowSelf = (showSelf || activeShowOnlySelf) ?? DEFAULT_SHOW_SELF;

  const [loadingBets, setLoadingBets] = useState(true);
  const [errorBets, setErrorBets] = useState<string | undefined>();
  const [bets, setBets] = useState<Bet[]>([]);

  const loadBets = () => {
    setLoadingBets(true);
    setErrorBets(undefined);

    if (!contest?.id?.length) {
      setLoadingBets(false);
      setErrorBets('Unable to load bets, the contest is not valid.');
      return;
    }

    const betRetrieveFunction = activeShowOnlySelf
      ? getUserBetsForContest(auth, contest.id)
      : showSelf
      ? getUserAndNonPendingBetsForContest(auth, contest)
      : getNonPendingBetsForContest(contest);

    betRetrieveFunction
      .then((bets) => {
        setBets(
          activeShowSelf
            ? bets
            : bets.filter(
                (bet) => bet.owner.toLowerCase() !== auth?.user?.user?.email?.toLowerCase()
              )
        );
        setLoadingBets(false);
        if (forceRefresh && onRefreshComplete) {
          onRefreshComplete();
        }
      })
      .catch((error) => {
        console.error('Unable to load bets.', error);
        setErrorBets('Unable to load bets.');
        setLoadingBets(false);
        if (forceRefresh && onRefreshComplete) {
          onRefreshComplete();
        }
      });
  };

  useEffect(() => {
    loadBets();
  }, []);

  useEffect(() => {
    if (forceRefresh) {
      loadBets();
    }
  }, [forceRefresh]);

  return (
    <>
      {errorBets && <ErrorBlock message={errorBets}></ErrorBlock>}
      {loadingBets && !errorBets && <LoadingBlock message="Loading bets..."></LoadingBlock>}
      {!loadingBets && !errorBets && <BetTable bets={bets} contest={contest}></BetTable>}
    </>
  );
};
