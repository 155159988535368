import { Tooltip } from 'primereact/tooltip';
import { useEffect, useState } from 'react';
import { Bet, ParlayBet } from '../common/models/bet';
import { Game, isAwayTeamScoreValid, isHomeTeamScoreValid } from '../common/models/game';
import { Team } from '../common/models/team';
import useTeamDataManager from '../hooks/team-data-manager';
import { getGame } from '../utils/game-utils';
import { BetPanel } from './BetPanel';
import { ErrorBlock } from './ErrorBlock';
import { LoadingBlock } from './LoadingBlock';
import { TeamAvatar } from './TeamAvatar';
import useAuth from '../auth/auth';

const DEFAULT_REFRESH_FREQUENCY_MS = 30000;

export interface LiveGameActionViewerProps {
  gameId: string | undefined;
  bets: (Bet | ParlayBet)[];
  refreshFrequencyMs?: number;
}

export const LiveGameActionViewer = (props: LiveGameActionViewerProps) => {
  const teamDataManager = useTeamDataManager();
  const auth = useAuth();

  const { gameId, bets, refreshFrequencyMs } = props;
  const activeRefreshFrequency = refreshFrequencyMs
    ? refreshFrequencyMs
    : DEFAULT_REFRESH_FREQUENCY_MS;
  const [loadingGame, setLoadingGame] = useState(true);
  const [errorGame, setErrorGame] = useState<string | undefined>();
  const [game, setGame] = useState<Game | undefined>();
  const [user] = useState(auth?.user?.user?.email);
  const [myBets] = useState(bets.filter((bet) => bet.owner === auth?.user?.user?.email));

  const [awayTeam, setAwayTeam] = useState<Team | undefined>();
  const [homeTeam, setHomeTeam] = useState<Team | undefined>();

  useEffect(() => {
    if (game) {
      const awayTeam = teamDataManager.getTeam(game.awayTeam, game.sport);
      const homeTeam = teamDataManager.getTeam(game.homeTeam, game.sport);
      setAwayTeam(awayTeam);
      setHomeTeam(homeTeam);
    }
  }, [game]);

  const loadGame = () => {
    setLoadingGame(true);
    getGame(gameId)
      .then((gameUpdate) => {
        if (gameUpdate) {
          setGame(gameUpdate);
        } else if (!game) {
          setErrorGame('Unable to load game.');
        } else {
          console.warn('No game update found for', gameId);
        }
        setLoadingGame(false);
      })
      .catch((error) => {
        console.error('Unable to load game.', error);
        if (!game) {
          setErrorGame('Unable to load game.');
        } else {
          console.warn('No game update found for', gameId);
        }
        setLoadingGame(false);
      });
  };

  useEffect(() => {
    // console.log('Loading initial status for game', gameId);
    loadGame();

    // console.log('Scheduling updates for game', gameId);
    const gameUpdateInterval = setInterval(() => {
      // console.log('Loading scheduled status update for game', gameId);
      loadGame();
    }, activeRefreshFrequency);

    return () => {
      // console.log('Cancelling updates for game', gameId);
      gameUpdateInterval && clearInterval(gameUpdateInterval);
    };
  }, []);

  return (
    <div
      style={{
        overflow: 'auto',
        textOverflow: 'ellipsis',
        borderRadius: '4px',
        border: myBets?.length > 0 ? '2px solid #386dd1' : '1px solid #dee2e6',
        background: '#ffffff',
        // display: 'flex',
        // flexDirection: 'column',
        width: '100%',
        maxWidth: '24em',
        minWidth: '13em',
        height: '100%',
        minHeight: '5em',
        padding: '.5em .5em .5em .5em'
      }}
    >
      {!game && !loadingGame && errorGame && <ErrorBlock></ErrorBlock>}
      {!game && loadingGame && <LoadingBlock></LoadingBlock>}
      {game && (
        <>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ textAlign: 'left' }}>
                    {awayTeam ? (
                      <TeamAvatar team={awayTeam} includeRanking={false} />
                    ) : (
                      game.awayTeam
                    )}
                  </div>
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      wordWrap: 'break-word',
                      paddingLeft: '.25em'
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '400',
                        paddingRight:
                          awayTeam && awayTeam.rankingLatest !== undefined ? '.25em' : '0em'
                      }}
                    >
                      {awayTeam && awayTeam.rankingLatest !== undefined
                        ? '#' + awayTeam.rankingLatest
                        : ''}
                    </span>
                    {awayTeam ? awayTeam.name : game.awayTeam}{' '}
                  </div>
                </div>
                <div>{isAwayTeamScoreValid(game) ? game.awayTeamScore : ''}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ textAlign: 'left' }}>
                    {homeTeam ? (
                      <TeamAvatar team={homeTeam} includeRanking={false} />
                    ) : (
                      game.homeTeam
                    )}
                  </div>
                  <div
                    style={{
                      overflow: 'auto',
                      textOverflow: 'ellipsis',
                      paddingLeft: '.25em'
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '400',
                        paddingRight:
                          homeTeam && homeTeam.rankingLatest !== undefined ? '.25em' : '0em'
                      }}
                    >
                      {homeTeam && homeTeam.rankingLatest !== undefined
                        ? '#' + homeTeam.rankingLatest
                        : ''}
                    </span>
                    {homeTeam ? homeTeam.name : game.homeTeam}{' '}
                  </div>
                </div>
                <div>{isHomeTeamScoreValid(game) ? game.homeTeamScore : ''}</div>
              </div>
            </div>
            <div style={{ flex: 0, marginLeft: '1em', fontWeight: 200 }} title="Total Score">
              {isAwayTeamScoreValid(game) && isHomeTeamScoreValid(game)
                ? game.homeTeamScore + game.awayTeamScore
                : ''}
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <Tooltip target=".target-live" />
            <div
              style={{ fontSize: '.75em' }}
              className="target-live"
              data-pr-tooltip={'Scores update automatically'}
              data-pr-position={'left'}
            >
              Live
            </div>
          </div>

          <div style={{ marginTop: '.5em' }}>
            <BetPanel bets={bets} collapsed={true} game={game} highlightUser={user}></BetPanel>
          </div>
        </>
      )}
    </div>
  );
};
