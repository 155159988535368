import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { isDev } from './utils/utils';

const firebaseConfig = {
  apiKey: 'AIzaSyBKs2Qhx0cf7Y34CA7wq_pZWlh42A58wbo',
  // authDomain: 'take-the-points-c13fa.firebaseapp.com',
  authDomain: 'www.takethepoints.app',
  databaseURL: 'https://take-the-points-c13fa-default-rtdb.firebaseio.com',
  projectId: 'take-the-points-c13fa',
  storageBucket: 'take-the-points-c13fa.appspot.com',
  messagingSenderId: '454957971897',
  appId: '1:454957971897:web:3b90298ee3d8e27b339e68',
  measurementId: 'G-3RMV1V1K04'
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const analytics = getAnalytics(firebaseApp);
const functions = getFunctions(firebaseApp);
if (isDev()) {
  console.log('connecting functions emulator...');
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export { db, auth, analytics, functions };
