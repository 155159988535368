import { UserCredential } from 'firebase/auth';
import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { AuthContextType } from '../auth/auth';
import { CombinedUserProfile } from '../common/models/combined-user-profile';
import { PublicUserProfile } from '../common/models/public-user-profile';
import { UserProfile } from '../common/models/user-profile';
import { db } from '../firebase';
import { isAuthContextTypeValid } from './auth-utils';
import { generateAvatarInitialsForEmail } from './utils';

const USER_PROFILE_COLLECTION = 'userProfiles';
const PUBLIC_USER_PROFILE_COLLECTION = 'publicUserProfiles';

export const getUserProfileForAuthContext = async (auth: AuthContextType): Promise<UserProfile> => {
  if (!isAuthContextTypeValid(auth)) {
    throw new Error('User must be logged in and have a valid email');
  }
  return getUserProfileForEmail(auth.user?.user?.email);
};

export const getUserProfileForEmail = async (
  email: string | undefined | null
): Promise<UserProfile> => {
  if (!email?.length) {
    throw new Error('Email provided is invalid.');
  }

  const docRef = doc(db, USER_PROFILE_COLLECTION, email.trim().toLowerCase());
  return await getDoc(docRef).then(async (doc) => {
    return doc.data() as UserProfile;
  });
};

export const getPublicUserProfileForEmail = async (
  email: string | undefined | null
): Promise<PublicUserProfile> => {
  if (!email?.length) {
    throw new Error('Email provided is invalid.');
  }

  const docRef = doc(db, PUBLIC_USER_PROFILE_COLLECTION, email.trim().toLowerCase());
  return await getDoc(docRef).then(async (doc) => {
    return doc.data() as PublicUserProfile;
  });
};

export const updateUserProfile = async (userProfile: UserProfile): Promise<UserProfile> => {
  if (!userProfile.email?.length) {
    throw new Error('Email provided is invalid.');
  }
  userProfile.updatedAt = Timestamp.now();
  const docRef = doc(db, USER_PROFILE_COLLECTION, userProfile.email.trim().toLowerCase());
  await setDoc(docRef, userProfile);
  return getUserProfileForEmail(userProfile.email);
};

export const createDefaultOrUpdateUserProfile = async (
  user: UserCredential | null | undefined
): Promise<CombinedUserProfile> => {
  if (!user) {
    throw new Error('User must be logged in');
  }

  const email = user?.user?.email;
  if (!email?.length) {
    throw new Error('Email provided is invalid.');
  }

  const now = Timestamp.now();

  const userProfile: UserProfile = {
    email: email.trim().toLowerCase(),
    name: user?.user?.displayName?.length ? user?.user?.displayName : email.trim().toLowerCase(),
    photoUrl: user?.user?.photoURL?.length ? user?.user?.photoURL : null,
    phoneNumber: user?.user?.phoneNumber?.length ? user?.user?.phoneNumber : null,
    createdAt: now,
    updatedAt: now,
    notificationPreferences: {
      contestInviteEmailActive: true,
      contestClosedEmailActive: false,
      betClosedEmailActive: false
    }
  };

  const displayName = user?.user?.displayName;

  const shadowName: string = await window.crypto.subtle
    .digest('SHA-256', new TextEncoder().encode(email.trim().toLowerCase()))
    .then((hash) => {
      return Array.from(new Uint8Array(hash))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
    })
    .catch((err) => {
      console.error('Error creating shadow name', err);
      return window.crypto.getRandomValues(new Uint32Array(1))[0].toString();
    });

  const initials = generateAvatarInitialsForEmail(email.trim().toLowerCase())?.toUpperCase();

  const publicUserProfile: PublicUserProfile = {
    name: displayName?.length ? displayName : initials?.length ? initials : shadowName,
    showPhoto: true,
    photoUrl: user?.user?.photoURL?.length ? user?.user?.photoURL : null
  };

  const existingPublicProfile = await getPublicUserProfileForEmail(email);
  if (existingPublicProfile) {
    publicUserProfile.photoUrl = existingPublicProfile.photoUrl;
  }

  const existingUserProfile = await getUserProfileForEmail(email);
  if (existingUserProfile) {
    userProfile.createdAt = existingUserProfile.createdAt ? existingUserProfile.createdAt : now;
    userProfile.email = existingUserProfile.email;
    userProfile.notificationPreferences = existingUserProfile.notificationPreferences;
  }

  const profileDocRef = doc(db, USER_PROFILE_COLLECTION, email.trim().toLowerCase());
  await setDoc(profileDocRef, userProfile);

  const publicProfileDocRef = doc(db, PUBLIC_USER_PROFILE_COLLECTION, email.trim().toLowerCase());
  await setDoc(publicProfileDocRef, publicUserProfile);

  return { profile: userProfile, publicProfile: publicUserProfile };
};
