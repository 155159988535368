import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Sport } from '../common/models/sport';
import { Team } from '../common/models/team';
import { getAllTeams } from '../utils/team-utils';

export interface TeamDataManagerContextType {
  getTeam: (teamName: string, sport: Sport) => Team | undefined;
  getTeamsBySport: () => { [sport: string]: Team[] };
}

const TeamDataManagerContext = createContext<TeamDataManagerContextType>(
  {} as TeamDataManagerContextType
);

export function TeamDataManagerContextProvider({ children }: { children: ReactNode }): JSX.Element {
  const [teamsById, setTeamsById] = useState<{ [key: string]: Team }>({});

  useEffect(() => {
    getAllTeams()
      .then((teams) => {
        const newTeamsById = { ...teamsById };
        teams.forEach((team) => {
          newTeamsById[team.id] = team;
        });
        setTeamsById(newTeamsById);
      })
      .catch((error) => {
        console.error('Error getting teams', error);
      });
  }, []);

  const value = {
    getTeam: (teamName: string, sport: Sport): Team | undefined => {
      const teamId = `${teamName}${sport}`;
      return teamsById[btoa(teamId)];
    },
    getTeamsBySport: (): { [sport: string]: Team[] } => {
      const teamsBySport: { [sport: string]: Team[] } = {};
      Object.keys(teamsById).forEach((teamId) => {
        const team = teamsById[teamId];
        if (team && team.sport) {
          const teamsForSport = teamsBySport[team.sport as string] || [];
          teamsForSport.push(team);
          teamsBySport[team.sport] = teamsForSport;
        }
      });
      return teamsBySport;
    }
  } as TeamDataManagerContextType;

  return (
    <>
      <TeamDataManagerContext.Provider value={value}>{children}</TeamDataManagerContext.Provider>
    </>
  );
}

export default function useTeamDataManager(): TeamDataManagerContextType {
  return useContext(TeamDataManagerContext);
}
