import { useState } from 'react';
import { ErrorBlock } from '../components/ErrorBlock';
import { LoadingBlock } from '../components/LoadingBlock';
import PageHeader from '../components/PageHeader';
import { InfoBlock } from '../components/InfoBlock';

export const Seasons = () => {
  const [errorSeasons] = useState<string>();
  const [loadingSeasons] = useState(false);

  if (errorSeasons) {
    return <ErrorBlock message={errorSeasons} />;
  } else if (loadingSeasons) {
    return <LoadingBlock />;
  } else {
    return (
      <>
        <PageHeader title="Seasons" />
        <InfoBlock message={'Stay tuned! Our seasons feature is coming soon...'} />
      </>
    );
  }
};
