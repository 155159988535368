import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Panel } from 'primereact/panel';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../auth/auth';
import { Contest } from '../common/models/contest';
import { ContestInfo } from '../components/ContestInfo';
import { ErrorBlock } from '../components/ErrorBlock';
import { LoadingBlock } from '../components/LoadingBlock';
import PageHeader from '../components/PageHeader';
import useGlobalToasts from '../hooks/global-toasts';
import { isUserActiveAdmin } from '../utils/admin-utils';
import { getContest, isContestOwner, saveContest } from '../utils/contest-utils';

export interface EditContestProps {
  contestId?: string;
}

const EditContest = (editContestProps: EditContestProps) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const globalToasts = useGlobalToasts();

  const { contestId } = editContestProps;

  let activeContestId: string | undefined;
  const params = useParams();
  if (params?.contestId) {
    activeContestId = params.contestId;
  } else {
    activeContestId = contestId;
  }

  const [invalidContestId, setInvalidContestId] = useState(false);

  const [loadingContest, setLoadingContest] = useState(true);
  const [errorContest, setErrorContest] = useState<string | undefined>();
  const [contest, setContest] = useState<Contest | undefined>();
  const [activeMembers, setActiveMembers] = useState<string[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingAdmin, setLoadingAdmin] = useState(true);

  const [savingContest, setSavingContest] = useState(false);

  useEffect(() => {
    setLoadingAdmin(true);
    isUserActiveAdmin(auth).then((isAdmin) => {
      setIsAdmin(isAdmin);
      setLoadingAdmin(false);
    });
  }, [auth]);

  useEffect(() => {
    if (!activeContestId?.length) {
      setInvalidContestId(true);
      return;
    }
    setInvalidContestId(false);

    setLoadingContest(true);
    setErrorContest(undefined);
    getContest(activeContestId)
      .then((contest) => {
        if (!contest) {
          setInvalidContestId(true);
          return;
        }
        if (!isContestOwner(auth, contest)) {
          navigate(`/contests/${contest?.id}`);
        } else {
          setContest(contest);
          document.title = document.title + ' | ' + contest.name;
          if (contest.activeMembers) {
            setActiveMembers(contest.activeMembers);
          } else {
            setActiveMembers([]);
          }
        }
      })
      .catch((error) => {
        setErrorContest('Unable to load contest data.');
        console.error('Unable to load contest data.', error);
      })
      .finally(() => {
        setLoadingContest(false);
      });
  }, []);

  const launchContestLink = (
    <Button
      label="Launch Contest"
      className="p-button-text hidden md:block"
      icon="pi pi-external-link"
      onClick={() => navigate(`/contests/${contest?.id}`)}
    />
  );

  const renderDetails = () => {
    return (
      <>
        {(errorContest || invalidContestId) && (
          <ErrorBlock message="Unable to load contest details" />
        )}
        {!(errorContest || invalidContestId) && loadingContest && (
          <LoadingBlock message="Loading contest details..." />
        )}
        {!(errorContest || invalidContestId) && !loadingContest && contest && (
          <ContestInfo contest={contest} />
        )}
      </>
    );
  };

  const renderMembers = () => {
    return (
      <>
        <div className="grid">
          <div className="col fw-bold">Member</div>
          <div className="col fw-bold text-align-right">Active</div>
        </div>
        {contest?.invitedMembers
          .sort((a, b) => a.localeCompare(b))
          .map((invitedMember) => {
            return (
              <div key={invitedMember} className="grid">
                <div className="col">{invitedMember}</div>
                <div className="col text-align-right">
                  <Checkbox
                    disabled={
                      savingContest || loadingAdmin || (contest?.status === 'COMPLETE' && !isAdmin)
                    }
                    onChange={(e) => {
                      if (e.checked) {
                        setActiveMembers((prevActiveMembers) => {
                          return [...prevActiveMembers, invitedMember];
                        });
                      } else {
                        setActiveMembers((prevActiveMembers) => {
                          return prevActiveMembers.filter((member) => member !== invitedMember);
                        });
                      }
                    }}
                    checked={
                      activeMembers.filter((member) => member === invitedMember).length === 1
                    }
                  ></Checkbox>
                </div>
              </div>
            );
          })}
        {
          <div>
            <Button
              label="Save"
              loading={savingContest || loadingAdmin}
              disabled={
                savingContest || loadingAdmin || (contest?.status === 'COMPLETE' && !isAdmin)
              }
              onClick={() => {
                if (contest) {
                  contest.activeMembers = activeMembers;
                  setContest(contest);

                  setSavingContest(true);
                  saveContest(contest)
                    .then(() => {
                      globalToasts.sendToast('success', 'Success', 'Contest saved.', 3000);
                      setContest(contest);
                    })
                    .catch((error) => {
                      globalToasts.sendToast('error', 'Error', 'Unable to save contest.', 3000);
                      console.error('Unable to save contest.', error);
                    })
                    .finally(() => {
                      setSavingContest(false);
                    });
                }
              }}
            ></Button>
          </div>
        }
      </>
    );
  };

  return (
    <>
      {(errorContest || invalidContestId) && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <i className="pi pi-exclamation-circle" style={{ fontSize: '1em', color: '#ef9a9a' }}></i>
          <span style={{ marginLeft: '.25em', color: '#ef9a9a' }}>Unable to load contest.</span>
        </div>
      )}
      {!(errorContest || invalidContestId) && loadingContest && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <i
            className="pi pi-spin pi-spinner"
            style={{ fontSize: '1em' }}
            title="Loading contest..."
          ></i>
        </div>
      )}
      {!(errorContest || invalidContestId) && !loadingContest && contest && (
        <>
          <PageHeader title="Edit Contest" subtitle={contest.name} context={launchContestLink} />
          <Panel header="Contest Details" style={{ marginBottom: '.5em' }}>
            {renderDetails()}
          </Panel>
          <Panel header="Members" style={{ marginBottom: '.5em' }}>
            {renderMembers()}
          </Panel>
        </>
      )}
    </>
  );
};

export default EditContest;
