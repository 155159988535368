import { Button } from 'primereact/button';

export interface IconButtonWithTooltipProps {
  icon: string;
  buttonType?: 'button' | 'reset' | 'submit';
  iconStyle?: any;
  disabled?: boolean;
  title?: string;
  tooltip?: string;
  tooltipOptions?: any;
  onClick?: (event: any) => void;
}

// const defaultTooltipOptions = { position: 'left' };

const IconButtonWithTooltip = (iconButtonWithTooltipProps: IconButtonWithTooltipProps) => {
  const { icon, buttonType, disabled, iconStyle, title, tooltip, onClick } =
    iconButtonWithTooltipProps;
  // const activeTooltipOptions = tooltipOptions || defaultTooltipOptions;
  const activeButtonType = buttonType || 'button';

  return (
    <Button
      className={'p-button-text'}
      type={activeButtonType}
      icon={'pi ' + icon}
      onClick={(e) => onClick && onClick(e)}
      title={tooltip}
      disabled={disabled !== undefined ? disabled : false}
      // tooltip={tooltip}
      // tooltipOptions={activeTooltipOptions}
      style={iconStyle ? iconStyle : {}}
    >
      {title?.length && <span style={{ marginLeft: '.25em' }}>{title}</span>}
    </Button>
  );
};

export default IconButtonWithTooltip;
