import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from './auth';

function Signout() {
  const authContext = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    authContext.signout();
    navigate('/', { replace: true });
  });

  return <div className="Disconnect">Signing out...</div>;
}

export default Signout;
