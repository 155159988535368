import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { Game } from '../common/models/game';
import { GameStatus } from '../common/models/game-status';
import { Sport } from '../common/models/sport';
import { ErrorBlock } from '../components/ErrorBlock';
import { LoadingBlock } from '../components/LoadingBlock';
import PageHeader from '../components/PageHeader';
import {
  deleteGame,
  gameHasFullValidLines,
  gameHasValidLines,
  getAllGamesWhereSportAndTeamsAndStatusAndDate
} from '../utils/game-utils';
import { formatReallyShortTimestamp } from '../utils/utils';
import { GameStatusIcon } from '../components/GameStatusIcon';
import { TeamAvatar } from '../components/TeamAvatar';
import useTeamDataManager from '../hooks/team-data-manager';
import IconWithTooltip from '../components/IconWithTooltip';
import IconButtonWithTooltip from '../components/IconButtonWithTooltip';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InfoBlock } from '../components/InfoBlock';
import useGlobalToasts from '../hooks/global-toasts';
import { getBetsForGame } from '../utils/bet-utils';
import { Bet } from '../common/models/bet';
import GameFilter, { GameFilters } from '../components/GameFilter';

export const Games = () => {
  const globalToasts = useGlobalToasts();
  const teamDataManager = useTeamDataManager();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [games, setGames] = useState<Game[]>([]);
  const [actionGame, setActionGame] = useState<Game | undefined>();
  const [showDeleteGameDialog, setShowDeleteGameDialog] = useState<boolean>(false);
  const [errorActionGameBets, setErrorActionGameBets] = useState<string | undefined>(undefined);
  const [actionGameBets, setActionGameBets] = useState<Bet[]>([]);
  const [loadingActionGameBets, setLoadingActionGameBets] = useState<boolean>(false);

  const [deletingGame, setDeletingGame] = useState<boolean>(false);

  const sports = [Sport.NCAAF, Sport.NFL, Sport.MLB, Sport.NCAAB, Sport.NBA, Sport.NCAABASE];
  const [selectedSport, setSelectedSport] = useState<Sport | undefined>(Sport.NCAAF);

  const loadGames = () => {
    setLoading(true);
    getAllGamesWhereSportAndTeamsAndStatusAndDate(selectedSport, {}, [
      GameStatus.UPCOMING,
      GameStatus.IN_PROGRESS
    ])
      .then((games) => {
        setGames(
          games.sort((a, b) => {
            if (a.homeTeam === b.homeTeam) {
              if (a.awayTeam === b.awayTeam) {
                return a.startTime.nanoseconds - b.startTime.nanoseconds;
              } else {
                return a.awayTeam.localeCompare(b.awayTeam);
              }
            } else {
              return a.homeTeam.localeCompare(b.homeTeam);
            }
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error('Unable to load games.', error);
        setError('Unable to load games.');
        setLoading(false);
      });
  };

  useEffect(() => {
    loadGames();
  }, [selectedSport]);

  useEffect(() => {
    if (actionGame) {
      setLoadingActionGameBets(true);
      setErrorActionGameBets(undefined);
      getBetsForGame(actionGame.id)
        .then((bets) => {
          setActionGameBets(bets);
          setLoadingActionGameBets(false);
        })
        .catch((error) => {
          console.error('Unable to load bets for game.', error);
          setErrorActionGameBets('Unable to load bets for game.');
          setLoadingActionGameBets(false);
        });
    }
  }, [actionGame]);

  const deleteSelectedGame = () => {
    setDeletingGame(true);
    if (!actionGame) {
      setDeletingGame(false);
      setShowDeleteGameDialog(false);
      globalToasts.sendToast('error', 'Error', 'Unable to delete game.', 3000);
      return;
    }

    deleteGame(actionGame.id)
      .then(() => {
        setDeletingGame(false);
        setShowDeleteGameDialog(false);
        loadGames();
      })
      .catch((error) => {
        console.error('Unable to delete game.', error);
        setDeletingGame(false);
        setShowDeleteGameDialog(false);
        globalToasts.sendToast('error', 'Error', 'Unable to delete game.', 3000);
      });
  };

  const titleActionTemplate = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', paddingLeft: '1em' }}>
        <GameFilter
          sports={sports}
          onFilterChange={(gameFilters: GameFilters) => {
            setSelectedSport(gameFilters.sport);
          }}
        ></GameFilter>
        <IconButtonWithTooltip
          icon="pi pi-refresh"
          tooltip="Refresh Games"
          onClick={loadGames}
        ></IconButtonWithTooltip>
      </div>
    );
  };

  return (
    <>
      <Dialog
        header={`Delete Game: ${actionGame?.awayTeam} @ ${actionGame?.homeTeam}?`}
        visible={showDeleteGameDialog}
        style={{ width: '95vw' }}
        onHide={() => {
          setShowDeleteGameDialog(false);
          setActionGame(undefined);
        }}
      >
        <InfoBlock message="Are you sure you want to delete this game? This will delete all associated bets and cannot be undone?"></InfoBlock>

        <div className="mt-2" style={{ textAlign: 'center' }}>
          {loadingActionGameBets && <LoadingBlock message="Collecting bets for game..." />}
          {errorActionGameBets && (
            <ErrorBlock message="Unable to load bets for game, it cannot be deleted." />
          )}
          {!loadingActionGameBets && !errorActionGameBets && (
            <div style={{ padding: '1em', textAlign: 'center', fontWeight: 'bold' }}>
              There {actionGameBets?.length === 1 ? 'is' : 'are'} {actionGameBets?.length} bet
              {actionGameBets?.length === 1 ? '' : 's'} associated with this game.
            </div>
          )}
          <Button
            className="mr-1"
            label="Delete"
            disabled={deletingGame || loadingActionGameBets || errorActionGameBets ? true : false}
            onClick={deleteSelectedGame}
          />
          <Button
            label="Cancel"
            disabled={deletingGame || loadingActionGameBets}
            onClick={() => setShowDeleteGameDialog(false)}
          />
        </div>
      </Dialog>

      <PageHeader title="Games" subtitleTemplate={titleActionTemplate()}></PageHeader>
      {error && <ErrorBlock message={error} />}
      {!error && loading && <LoadingBlock message="Loading teams..." />}
      {!error && !loading && (
        <>
          <DataTable
            size="small"
            paginator
            paginatorTemplate={{
              layout: 'PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown'
            }}
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            value={games}
          >
            <Column
              field="awayTeam"
              body={(game: Game) => (
                <TeamAvatar team={teamDataManager.getTeam(game.awayTeam, game.sport)} />
              )}
            ></Column>
            {/* <Column header="Away Team" field="awayTeam"></Column> */}
            <Column
              field="homeTeam"
              body={(game: Game) => (
                <TeamAvatar team={teamDataManager.getTeam(game.homeTeam, game.sport)} />
              )}
            ></Column>
            {/* <Column header="Home Team" field="homeTeam"></Column> */}
            <Column
              field="status"
              body={(game: Game) => <GameStatusIcon status={game.status} showLabel={false} />}
            ></Column>
            <Column
              field="id"
              body={(game: Game) => (
                <IconButtonWithTooltip
                  icon="pi-external-link"
                  tooltip="Open in Firebase Console"
                  onClick={() =>
                    window.open(
                      `https://console.firebase.google.com/u/0/project/take-the-points-c13fa/firestore/data/~2Fgames~2F${game.id}`,
                      '_blank'
                    )
                  }
                ></IconButtonWithTooltip>
              )}
            ></Column>
            <Column
              header="Game Time"
              field="startTime"
              body={(game: Game) => formatReallyShortTimestamp(game.startTime)}
            ></Column>
            {/* <Column header="ID" field="id"></Column> */}
            <Column
              field="id"
              header="Valid Lines"
              body={(game: Game) =>
                gameHasFullValidLines(game) ? (
                  <IconWithTooltip
                    icon="pi pi-check-circle"
                    iconStyle={{ color: 'green' }}
                    tooltip="All lines are valid"
                  />
                ) : gameHasValidLines(game) ? (
                  <IconWithTooltip
                    icon="pi pi-minus-circle"
                    iconStyle={{ color: 'orange' }}
                    tooltip="At least one line is valid"
                  />
                ) : (
                  <IconWithTooltip
                    icon="pi pi-times-circle"
                    iconStyle={{ color: 'red' }}
                    tooltip="No lines are valid"
                  />
                )
              }
            ></Column>
            <Column
              header="Actions"
              field="id"
              body={(game: Game) => {
                return (
                  <div>
                    <IconButtonWithTooltip
                      icon="pi pi-times"
                      tooltip="Delete"
                      onClick={() => {
                        setActionGame(game);
                        setShowDeleteGameDialog(true);
                      }}
                    ></IconButtonWithTooltip>
                  </div>
                );
              }}
            ></Column>
            <Column header="Away Moneyline" field="awayTeamMoneyline"></Column>
            <Column header="Away Spread" field="awayTeamSpread"></Column>
            <Column header="Away Price" field="awayTeamSpreadPrice"></Column>
            <Column header="Home Moneyline" field="homeTeamMoneyline"></Column>
            <Column header="Home Spread" field="homeTeamSpread"></Column>
            <Column header="Home Price" field="homeTeamSpreadPrice"></Column>
            <Column header="OU" field="overUnderLine"></Column>
            <Column header="Under Price" field="underPrice"></Column>
            <Column header="Over Price" field="overPrice"></Column>
          </DataTable>
        </>
      )}
    </>
  );
};
