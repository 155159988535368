import IconWithTooltip from './IconWithTooltip';

export interface ErrorBlockProps {
  message?: string;
  tooltip?: string;
}

export const ErrorBlock = (props: ErrorBlockProps) => {
  const { message, tooltip } = props;
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <IconWithTooltip
        icon="pi pi-exclamation-circle"
        tooltip={tooltip ? tooltip : message}
        iconStyle={{ color: '#ef9a9a' }}
      ></IconWithTooltip>
      <span style={{ color: '#ef9a9a' }}>{message?.length ? message : 'An error occurred.'}</span>
    </div>
  );
};
