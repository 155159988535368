import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import useAuth from '../auth/auth';
import { Contest } from '../common/models/contest';
import { Game } from '../common/models/game';
import {
  calculateWinAmountForBet,
  describeBet,
  getUserBalanceForContest,
  saveBet
} from '../utils/bet-utils';

import { Timestamp } from 'firebase/firestore';
import { RadioButton } from 'primereact/radiobutton';
import { Bet } from '../common/models/bet';
import { BetStatus } from '../common/models/bet-status';
import { BetType } from '../common/models/bet-type';
import useGlobalToasts from '../hooks/global-toasts';
import { formatMoney } from '../utils/utils';
// import { getFunctions, httpsCallable } from 'firebase/functions';

export interface PlaceBetProps {
  contest: Contest;
  game: Game;
  onBetPlaced?: () => void;
}

const PlaceBet = (placeBetProps: PlaceBetProps) => {
  const { contest, game, onBetPlaced } = placeBetProps;
  const auth = useAuth();
  const globalToasts = useGlobalToasts();
  // const functions = getFunctions();

  const [loadingContestBalance, setLoadingContestBalance] = useState(true);
  const [contestBalance, setContestBalance] = useState<number | undefined>();
  const [errorContestBalance, setErrorContestBalance] = useState<string | undefined>();

  const [amount, setAmount] = useState<number | undefined>();
  const [betType, setBetType] = useState<BetType>(BetType.OVER);
  const [savingBet, setSavingBet] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    setLoadingContestBalance(true);
    getUserBalanceForContest(auth, contest)
      .then((balance) => {
        setContestBalance(balance);
        setLoadingContestBalance(false);
      })
      .catch((error) => {
        console.error('Unable to load balance.', error);
        setErrorContestBalance('Unable to load balance.');
      });
  }, []);

  const confirmBet = () => {
    setShowConfirmation(true);
  };

  const cancelBet = () => {
    setShowConfirmation(false);
  };

  const placeBet = () => {
    setSavingBet(true);
    const bet = {
      betType: betType,
      owner: auth.user?.user.email?.toLowerCase(),
      timestamp: Timestamp.now(),
      amount: amount,
      gameId: game.id,
      contestId: contest.id,
      teamName:
        betType === BetType.HOME_TEAM_MONEYLINE
          ? game.homeTeam
          : betType === BetType.AWAY_TEAM_MONEYLINE
          ? game.awayTeam
          : betType === BetType.HOME_TEAM_SPREAD
          ? game.homeTeam
          : betType === BetType.AWAY_TEAM_SPREAD
          ? game.awayTeam
          : `${game.awayTeam}/${game.homeTeam}`,
      line:
        betType === BetType.OVER
          ? game.overUnderLine
          : betType === BetType.UNDER
          ? game.overUnderLine
          : betType === BetType.HOME_TEAM_SPREAD
          ? game.homeTeamSpread
          : betType === BetType.AWAY_TEAM_SPREAD
          ? game.awayTeamSpread
          : undefined,
      price:
        betType === BetType.OVER
          ? game.overPrice
          : betType === BetType.UNDER
          ? game.underPrice
          : betType === BetType.HOME_TEAM_SPREAD
          ? game.homeTeamSpreadPrice
          : betType === BetType.AWAY_TEAM_SPREAD
          ? game.awayTeamSpreadPrice
          : betType === BetType.HOME_TEAM_MONEYLINE
          ? game.homeTeamMoneyline
          : betType === BetType.AWAY_TEAM_MONEYLINE
          ? game.awayTeamMoneyline
          : undefined,

      status: BetStatus.PENDING
    } as Bet;

    saveBet(auth, bet)
      .then(() => {
        globalToasts.sendToast('success', 'Bet Placed', 'Your bet has been placed.');
        setTimeout(() => {
          if (onBetPlaced) {
            onBetPlaced();
          }
          setSavingBet(false);
        }, 500);
      })
      .catch((error) => {
        setSavingBet(false);
        console.error('Unable to save bet.', error);
        let message = 'Unable to save bet.';
        if (error.message) {
          message = error.message;
        }
        globalToasts.sendToast('error', 'Error', message);
      });
  };

  return (
    <>
      {loadingContestBalance && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <i
            className="pi pi-spin pi-spinner"
            style={{ fontSize: '1em' }}
            title="Checking your contest balance..."
          ></i>
        </div>
      )}
      {errorContestBalance && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <i className="pi pi-exclamation-circle" style={{ fontSize: '1em', color: '#ef9a9a' }}></i>
          <span style={{ marginLeft: '.25em', color: '#ef9a9a' }}>
            Unable to check current contest balance.
          </span>
        </div>
      )}
      {!loadingContestBalance &&
        !errorContestBalance &&
        (contestBalance === undefined || contestBalance == null || contestBalance === 0) && (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <i
              className="pi pi-exclamation-circle"
              style={{ fontSize: '1em', color: '#ef9a9a' }}
            ></i>
            <span style={{ marginLeft: '.25em', color: '#ef9a9a' }}>
              Sorry, you don't have a sufficient balance to place a bet on this game.
            </span>
          </div>
        )}
      {!loadingContestBalance &&
        !errorContestBalance &&
        contestBalance !== 0 &&
        contestBalance !== undefined &&
        contestBalance != null &&
        !showConfirmation && (
          <>
            <div>
              Contest Balance Available:
              <span className="fw-bold ml-1">
                {formatMoney(contestBalance, contest.initialBalanceCurrency)}
              </span>
            </div>
            <div style={{ marginTop: '2em' }}>
              <form className="flex flex-column gap-2">
                <div>
                  <label htmlFor="amount" className="fw-bold">
                    Amount
                  </label>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">$</span>
                      <InputNumber
                        id="amount"
                        name="amount"
                        placeholder={'1 - ' + Math.floor(contestBalance).toFixed(0)}
                        value={amount}
                        onChange={(e) => setAmount(e.value ? e.value : undefined)}
                        className={classNames({
                          'p-invalid':
                            amount === undefined ||
                            isNaN(amount) ||
                            amount < 0 ||
                            amount > contestBalance
                        })}
                        style={{ width: '100%' }}
                      />
                      <span className="p-inputgroup-addon">.00</span>
                    </div>
                    {amount === undefined ||
                      isNaN(amount) ||
                      amount < 0 ||
                      (amount > contestBalance && (
                        <>
                          <Tooltip target=".invalid-amount-tooltip" />
                          <span
                            className="pi pi-exclamation-circle invalid-amount-tooltip"
                            style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                            data-pr-tooltip="Bet amount cannot exceed your current contest balance"
                            data-pr-position="left"
                          ></span>
                        </>
                      ))}
                  </div>
                </div>

                <div className="mt-2">
                  <label className="fw-bold">Outcome</label>
                  {game.overUnderLine !== undefined && game.overUnderLine !== null && (
                    <>
                      <div className="flex align-items-center mt-2">
                        <RadioButton
                          inputId={BetType.OVER}
                          name="betType"
                          value={BetType.OVER}
                          onChange={(e) => setBetType(e.value)}
                          checked={betType === BetType.OVER}
                        />
                        <label htmlFor={BetType.OVER} className="ml-2">
                          {'Total score over ' +
                            game.overUnderLine +
                            ' to win ' +
                            formatMoney(
                              calculateWinAmountForBet({
                                betType: BetType.OVER,
                                amount: amount === undefined || isNaN(amount) ? 0 : amount,
                                line: game.overUnderLine,
                                price: game.overPrice
                              } as Bet),
                              contest.initialBalanceCurrency
                            )}
                        </label>
                      </div>
                    </>
                  )}
                  {game.overUnderLine !== undefined && game.overUnderLine !== null && (
                    <>
                      <div className="flex align-items-center mt-3">
                        <RadioButton
                          inputId={BetType.UNDER}
                          name="betType"
                          value={BetType.UNDER}
                          onChange={(e) => setBetType(e.value)}
                          checked={betType === BetType.UNDER}
                        />
                        <label htmlFor={BetType.UNDER} className="ml-2">
                          {'Total score under ' +
                            game.overUnderLine +
                            ' to win ' +
                            formatMoney(
                              calculateWinAmountForBet({
                                betType: BetType.UNDER,
                                amount: amount === undefined || isNaN(amount) ? 0 : amount,
                                line: game.overUnderLine,
                                price: game.underPrice
                              } as Bet),
                              contest.initialBalanceCurrency
                            )}
                        </label>
                      </div>
                    </>
                  )}
                  {game.homeTeamSpread !== undefined && game.homeTeamSpread !== null && (
                    <>
                      <div className="flex align-items-center mt-3">
                        <RadioButton
                          inputId={BetType.HOME_TEAM_SPREAD}
                          name="betType"
                          value={BetType.HOME_TEAM_SPREAD}
                          onChange={(e) => setBetType(e.value)}
                          checked={betType === BetType.HOME_TEAM_SPREAD}
                        />
                        <label htmlFor={BetType.HOME_TEAM_SPREAD} className="ml-2">
                          {game.homeTeam +
                            (game.homeTeamSpread < 0
                              ? ' to win by more than ' + Math.abs(game.homeTeamSpread)
                              : ' to win or lose by less than ' + game.homeTeamSpread) +
                            ' to win ' +
                            formatMoney(
                              calculateWinAmountForBet({
                                betType: BetType.HOME_TEAM_SPREAD,
                                amount: amount === undefined || isNaN(amount) ? 0 : amount,
                                line: game.homeTeamSpread,
                                price: game.homeTeamSpreadPrice
                              } as Bet),
                              contest.initialBalanceCurrency
                            )}
                        </label>
                      </div>
                    </>
                  )}
                  {game.awayTeamSpread !== undefined && game.awayTeamSpread !== null && (
                    <>
                      <div className="flex align-items-center mt-3">
                        <RadioButton
                          inputId={BetType.AWAY_TEAM_SPREAD}
                          name="betType"
                          value={BetType.AWAY_TEAM_SPREAD}
                          onChange={(e) => setBetType(e.value)}
                          checked={betType === BetType.AWAY_TEAM_SPREAD}
                        />
                        <label htmlFor={BetType.AWAY_TEAM_SPREAD} className="ml-2">
                          {game.awayTeam +
                            (game.awayTeamSpread < 0
                              ? ' to win by more than ' + Math.abs(game.awayTeamSpread)
                              : ' to win or lose by less than ' + game.awayTeamSpread) +
                            ' to win ' +
                            formatMoney(
                              calculateWinAmountForBet({
                                betType: BetType.AWAY_TEAM_SPREAD,
                                amount: amount === undefined || isNaN(amount) ? 0 : amount,
                                line: game.awayTeamSpread,
                                price: game.awayTeamSpreadPrice
                              } as Bet),
                              contest.initialBalanceCurrency
                            )}
                        </label>
                      </div>
                    </>
                  )}
                  {game.homeTeamMoneyline !== undefined && game.homeTeamMoneyline !== null && (
                    <>
                      <div className="flex align-items-center mt-3">
                        <RadioButton
                          inputId={BetType.HOME_TEAM_MONEYLINE}
                          name="betType"
                          value={BetType.HOME_TEAM_MONEYLINE}
                          onChange={(e) => setBetType(e.value)}
                          checked={betType === BetType.HOME_TEAM_MONEYLINE}
                        />
                        <label htmlFor={BetType.HOME_TEAM_MONEYLINE} className="ml-2">
                          {game.homeTeam +
                            ' to win outright to win ' +
                            formatMoney(
                              calculateWinAmountForBet({
                                betType: BetType.HOME_TEAM_MONEYLINE,
                                amount: amount === undefined || isNaN(amount) ? 0 : amount,
                                price: game.homeTeamMoneyline
                              } as Bet),
                              contest.initialBalanceCurrency
                            )}
                        </label>
                      </div>
                    </>
                  )}
                  {game.awayTeamMoneyline !== undefined && game.awayTeamMoneyline !== null && (
                    <>
                      <div className="flex align-items-center mt-3">
                        <RadioButton
                          inputId={BetType.AWAY_TEAM_MONEYLINE}
                          name="betType"
                          value={BetType.AWAY_TEAM_MONEYLINE}
                          onChange={(e) => setBetType(e.value)}
                          checked={betType === BetType.AWAY_TEAM_MONEYLINE}
                        />
                        <label htmlFor={BetType.AWAY_TEAM_MONEYLINE} className="ml-2">
                          {game.awayTeam +
                            ' to win outright to win ' +
                            formatMoney(
                              calculateWinAmountForBet({
                                betType: BetType.AWAY_TEAM_MONEYLINE,
                                amount: amount === undefined || isNaN(amount) ? 0 : amount,
                                price: game.awayTeamMoneyline
                              } as Bet),
                              contest.initialBalanceCurrency
                            )}
                        </label>
                      </div>
                    </>
                  )}
                </div>

                <Button
                  className="mt-2"
                  color="primary"
                  type="button"
                  style={{ justifyContent: 'center' }}
                  loading={savingBet}
                  disabled={
                    amount === undefined ||
                    isNaN(amount) ||
                    amount < 0 ||
                    amount > contestBalance ||
                    savingBet ||
                    game.startTime <= Timestamp.now()
                  }
                  onClick={confirmBet}
                >
                  Place Bet
                </Button>
              </form>
            </div>
          </>
        )}
      {!loadingContestBalance &&
        !errorContestBalance &&
        contestBalance !== 0 &&
        contestBalance !== undefined &&
        contestBalance != null &&
        showConfirmation && (
          <>
            <div>
              Please confirm your bet below, once a bet is placed it{' '}
              <span className="fw-bold">cannot be changed or cancelled</span>.
            </div>
            <div className="mt-2 fw-bold fs-italics">
              {describeBet({
                betType: betType,
                owner: auth.user?.user?.email?.toLowerCase(),
                timestamp: Timestamp.now(),
                amount: amount,
                gameId: game.id,
                contestId: contest.id,
                teamName:
                  betType === BetType.HOME_TEAM_MONEYLINE
                    ? game.homeTeam
                    : betType === BetType.AWAY_TEAM_MONEYLINE
                    ? game.awayTeam
                    : betType === BetType.HOME_TEAM_SPREAD
                    ? game.homeTeam
                    : betType === BetType.AWAY_TEAM_SPREAD
                    ? game.awayTeam
                    : undefined,
                line:
                  betType === BetType.OVER
                    ? game.overUnderLine
                    : betType === BetType.UNDER
                    ? game.overUnderLine
                    : betType === BetType.HOME_TEAM_SPREAD
                    ? game.homeTeamSpread
                    : betType === BetType.AWAY_TEAM_SPREAD
                    ? game.awayTeamSpread
                    : undefined,
                price:
                  betType === BetType.OVER
                    ? game.overPrice
                    : betType === BetType.UNDER
                    ? game.underPrice
                    : betType === BetType.HOME_TEAM_SPREAD
                    ? game.homeTeamSpreadPrice
                    : betType === BetType.AWAY_TEAM_SPREAD
                    ? game.awayTeamSpreadPrice
                    : betType === BetType.HOME_TEAM_MONEYLINE
                    ? game.homeTeamMoneyline
                    : betType === BetType.AWAY_TEAM_MONEYLINE
                    ? game.awayTeamMoneyline
                    : undefined,
                status: BetStatus.PENDING
              } as Bet)}
            </div>
            <div className="flex align-items-center justify-content-center mt-2">
              <Button
                className="mt-2 mr-1"
                color="primary"
                type="button"
                style={{ justifyContent: 'center' }}
                loading={savingBet}
                disabled={
                  amount === undefined ||
                  isNaN(amount) ||
                  amount < 0 ||
                  amount > (contestBalance ? contestBalance : 0) ||
                  savingBet ||
                  game.startTime <= Timestamp.now()
                }
                onClick={placeBet}
              >
                Place Bet
              </Button>

              <Button
                className="mt-2 ml-1"
                color="primary"
                type="button"
                style={{ justifyContent: 'center' }}
                onClick={cancelBet}
              >
                Cancel Bet
              </Button>
            </div>
          </>
        )}
    </>
  );
};

export default PlaceBet;
