import { Timestamp } from 'firebase/firestore';
import { GameStatus } from './game-status';
import { Sport } from './sport';

export interface Game {
  id: string;
  sport: Sport;
  startTime: Timestamp;
  homeTeam: string;
  awayTeam: string;
  status: GameStatus;
  updatedStatusScoreTime: Timestamp;
  homeTeamScore: number;
  awayTeamScore: number;
  updatedOddsTime: Timestamp;
  overUnderLine: number;
  overPrice: number;
  underPrice: number;
  homeTeamMoneyline: number;
  awayTeamMoneyline: number;
  homeTeamSpread: number;
  homeTeamSpreadPrice: number;
  awayTeamSpread: number;
  awayTeamSpreadPrice: number;
  updatedTime: Timestamp;
}

export const isHomeTeamSpreadValid = (game: Game) => {
  return (
    game.homeTeamSpread !== undefined &&
    game.homeTeamSpread != null &&
    game.homeTeamSpreadPrice !== undefined &&
    game.homeTeamSpreadPrice != null
  );
};

export const isHomeTeamMoneylineValid = (game: Game) => {
  return game.homeTeamMoneyline !== undefined && game.homeTeamMoneyline != null;
};

export const isAwayTeamMoneylineValid = (game: Game) => {
  return game.awayTeamMoneyline !== undefined && game.awayTeamMoneyline != null;
};

export const isAwayTeamSpreadValid = (game: Game) => {
  return (
    game.awayTeamSpread !== undefined &&
    game.awayTeamSpread != null &&
    game.awayTeamSpreadPrice !== undefined &&
    game.awayTeamSpreadPrice != null
  );
};

export const isHomeTeamScoreValid = (game: Game) => {
  return game.homeTeamScore !== undefined && game.homeTeamScore != null;
};

export const isAwayTeamScoreValid = (game: Game) => {
  return game.awayTeamScore !== undefined && game.awayTeamScore != null;
};

export const isOverUnderLineValid = (game: Game) => {
  return (
    game.overUnderLine !== undefined &&
    game.overUnderLine != null &&
    game.overPrice !== undefined &&
    game.overPrice != null &&
    game.underPrice !== undefined &&
    game.underPrice != null
  );
};
