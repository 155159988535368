import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ErrorBlock } from '../components/ErrorBlock';
import { isUserActiveCreator } from '../utils/admin-utils';
import useAuth from './auth';

export const RequireCreator = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    if (!auth?.user?.user?.email?.length) {
      setIsSignedIn(false);
      setLoading(false);
      return;
    }
    isUserActiveCreator(auth)
      .then((isCreator) => {
        setIsCreator(isCreator);
        setIsSignedIn(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Unable to check if user is a subscribed creator', error);
        setErrorLoading(true);
      });
  }, []);

  return (
    <>
      {errorLoading && (
        <ErrorBlock message="There was an error confirming your subscription, please try again." />
      )}
      {!errorLoading && loading && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <i
            className="pi pi-spin pi-spinner"
            style={{ fontSize: '1em' }}
            title="Checking subscription..."
          ></i>
        </div>
      )}
      {!errorLoading && !loading && !isSignedIn && (
        <Navigate to="/signin" state={{ from: location }} replace />
      )}
      {!errorLoading && !loading && isSignedIn && !isCreator && (
        <ErrorBlock message="Your subscription does not allow for creating new contests." />
      )}
      {!errorLoading && !loading && isSignedIn && isCreator && children}
    </>
  );
};
