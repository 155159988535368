import { Timestamp } from 'firebase/firestore';
import { ContestStatus } from './contest-status';
import { PoolSettings } from './pool-settings';
import { Sport } from './sport';

export interface Contest {
  id?: string;
  name: string;
  start: Timestamp;
  end: Timestamp;
  status: ContestStatus;
  invitedMembers: string[];
  activeMembers?: string[];
  owner: string;
  sports: Sport[];
  teams?: { [key: string]: string[] };
  initialBalance: number;
  initialBalanceCurrency: string;
  contestSetupId?: string;
  contestSetupName?: string;
  minNumBets: number | undefined;
  poolSettings: PoolSettings;
}

export const DEFAULT_MIN_NUM_BETS = 0;
