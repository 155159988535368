export interface InfoBlockProps {
  message: string;
  showIcon?: boolean;
}

export const InfoBlock = (props: InfoBlockProps) => {
  const { message, showIcon } = props;
  const activeShowIcon = showIcon === undefined ? true : showIcon;

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      {activeShowIcon && <i className="pi pi-info-circle" style={{ fontSize: '1em' }}></i>}
      <span style={{ marginLeft: '.25em' }}>{message}</span>
    </div>
  );
};
