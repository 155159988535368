import { doc, getDoc } from 'firebase/firestore';
import { AuthContextType } from '../auth/auth';
import { Admin } from '../common/models/admin';
import { Creator } from '../common/models/creator';
import { Subscriber } from '../common/models/subscriber';
import { db } from '../firebase';

const getCreator = async (email: string): Promise<Creator | undefined> => {
  const creatorDocRef = doc(db, 'creators', email.trim().toLowerCase());
  const creatorDoc = await getDoc(creatorDocRef);
  if (creatorDoc.exists()) {
    return creatorDoc.data() as Creator;
  }
};

const getAdmin = async (email: string): Promise<Admin | undefined> => {
  const adminDocRef = doc(db, 'admins', email.trim().toLowerCase());
  const adminDoc = await getDoc(adminDocRef);
  if (adminDoc.exists()) {
    return adminDoc.data() as Admin;
  }
};

const getSubscriber = async (email: string): Promise<Subscriber | undefined> => {
  const subscriberDocRef = doc(db, 'subscribers', email.trim().toLowerCase());
  const subscriberDoc = await getDoc(subscriberDocRef);
  if (subscriberDoc.exists()) {
    return subscriberDoc.data() as Subscriber;
  }
};

export const isUserActiveAdmin = async (auth: AuthContextType): Promise<boolean> => {
  if (!auth?.user?.user?.email?.length) {
    return false;
  }
  const admin = await getAdmin(auth.user.user.email);
  return admin ? admin.active : false;
};

export const isUserActiveCreator = async (auth: AuthContextType): Promise<boolean> => {
  if (!auth?.user?.user?.email?.length) {
    return false;
  }
  const creator = await getCreator(auth.user.user.email);
  return creator ? creator.active : false;
};

export const isUserActiveSubscriber = async (auth: AuthContextType): Promise<boolean> => {
  if (!auth?.user?.user?.email?.length) {
    return false;
  }
  const subscriber = await getSubscriber(auth.user.user.email);
  return subscriber ? subscriber.active : false;
};
