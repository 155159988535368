import { Timestamp } from 'firebase/firestore';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { array, date, object, ref, string } from 'yup';
import { ContestSetup } from '../common/models/contest-setup';
import { buildSportOptions, Sport, SportName } from '../common/models/sport';
import { Team } from '../common/models/team';

export interface ContestSetupFormProps {
  contestSetup?: ContestSetup;
  teams: Team[];
  loading?: boolean;
  disabled?: boolean;
  onSubmit: (contestSetup: ContestSetup) => void;
  onCancel?: () => void;
}

export const ContestSetupForm = (props: ContestSetupFormProps) => {
  const { contestSetup, teams, loading, disabled, onSubmit, onCancel } = props;

  const sportOptions = buildSportOptions(Object.values(Sport).filter((s) => s !== Sport.ALL));

  const getTeamOptions = (sport: Sport) => {
    return teams
      .filter((team) => team.sport === sport)
      .map((team) => ({ name: team.name, value: team.name }));
  };

  const getErrorMessage = (errors: any, field: string) => {
    const es = (errors as unknown as any)[field];
    if (es instanceof Array) {
      return es.filter((e) => e?.length).join('\n');
    }
    return errors;
  };

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  currentDate.setMinutes(0);

  const validationSchema = object({
    name: string().min(1, 'A name is required').required('A name is required'),
    sports: array()
      .min(1, 'At least one sport must be selected')
      .required('Selecting a sport is required'),
    start: date().required('Start date is required'),
    end: date().min(ref('start'), 'End date cannot be before start date')
  });

  const initialName = contestSetup?.name || '';
  const initialStartDate = contestSetup?.start?.toDate() || currentDate;
  const initialEndDate = contestSetup?.end?.toDate() || currentDate;
  const initialSports = contestSetup?.sports || [];
  const initialSelectTeams: { [key: string]: boolean } = {};
  const initialTeams: { [key: string]: string[] } = contestSetup?.teams || {};
  const initialActive = contestSetup?.active || false;
  Object.values(Sport)
    .filter((sport) => sport != Sport.ALL)
    .forEach((sport) => {
      if (
        contestSetup?.teams &&
        contestSetup.teams[sport] &&
        contestSetup.teams[sport].length > 0
      ) {
        initialSelectTeams[sport] = true;
      } else {
        initialSelectTeams[sport] = false;
      }
      if (!contestSetup) {
        initialTeams[sport] = [];
      }
    });

  const initialValues = {
    name: initialName,
    start: initialStartDate,
    end: initialEndDate,
    sports: initialSports,
    selectTeams: initialSelectTeams,
    teams: initialTeams,
    active: initialActive
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      if (onSubmit) {
        const contestSetupToSave: ContestSetup = {
          id: contestSetup?.id || '',
          name: values.name,
          sports: values.sports,
          start: Timestamp.fromDate(values.start),
          end: Timestamp.fromDate(values.end),
          teams: values.teams,
          active: values.active
        };
        onSubmit(contestSetupToSave);
      }
    }
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
        <div>
          <label htmlFor="name">Name</label>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
            <InputText
              id="name"
              name="name"
              placeholder="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              className={classNames({
                'p-invalid': formik.dirty && formik.errors.name
              })}
              style={{ width: '100%' }}
            />
            {formik.dirty && formik.errors.name && (
              <>
                <Tooltip target=".invalid-name-tooltip" />
                <span
                  className="pi pi-exclamation-circle invalid-name-tooltip"
                  style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                  data-pr-tooltip={getErrorMessage(formik.errors, 'name')}
                  data-pr-position="left"
                ></span>
              </>
            )}
          </div>
        </div>

        <div>
          <label htmlFor="sports">Select Sports</label>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
            <MultiSelect
              id="sports"
              name="sports"
              filter
              value={formik.values.sports}
              onChange={(e) => {
                formik.setFieldValue('sports', e.value);
              }}
              options={sportOptions}
              optionLabel="name"
              optionValue="value"
              placeholder="Select Sports"
              className={classNames({
                'p-invalid': formik.dirty && Boolean(formik.errors.sports)
              })}
              style={{ width: '100%' }}
            />
            {formik.dirty && formik.errors.sports && (
              <>
                <Tooltip target=".invalid-sports-tooltip" />
                <span
                  className="pi pi-exclamation-circle invalid-sports-tooltip"
                  style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                  data-pr-tooltip={getErrorMessage(formik.errors, 'sports')}
                  data-pr-position="left"
                ></span>
              </>
            )}
          </div>
        </div>

        {formik.values.sports.length > 0 &&
          formik.values.sports.map((sport) => {
            return (
              <div key={`select-teams-for-${sport}`}>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                  <Checkbox
                    onChange={(e) => {
                      const selectTeams = { ...formik.values.selectTeams };
                      selectTeams[sport] = e.checked ? true : false;
                      formik.setFieldValue('selectTeams', selectTeams);
                    }}
                    checked={formik.values.selectTeams[sport]}
                    disabled={formik.values.sports.length === 0}
                  ></Checkbox>
                  <label className="pl-2" htmlFor={`select-teams-for-${sport}`}>
                    Select Teams for {SportName[sport]}
                  </label>
                </div>
                {formik.values.selectTeams[sport] && (
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
                    <MultiSelect
                      id={`teams-for-${sport}`}
                      name={`teams-for-${sport}`}
                      value={formik.values.teams[sport]}
                      filter
                      onChange={(e) => {
                        const teams = { ...formik.values.teams };
                        teams[sport] = e.value;
                        formik.setFieldValue('teams', teams);
                      }}
                      options={getTeamOptions(sport)}
                      optionLabel="name"
                      optionValue="value"
                      placeholder="Select Teams"
                      className={classNames({
                        'p-invalid': formik.dirty && Boolean(formik.errors.sports)
                      })}
                      style={{ width: '100%' }}
                    />
                  </div>
                )}
              </div>
            );
          })}

        <div>
          <label htmlFor="start">Start Date</label>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
            <Calendar
              id="start"
              name="start"
              value={formik.values.start}
              onChange={(e) => formik.setFieldValue('start', e.value)}
              placeholder="Select a start date"
              className={classNames({
                'p-invalid': formik.dirty && Boolean(formik.errors.start)
              })}
              style={{ width: '100%' }}
            />
            {formik.dirty && formik.errors.start && (
              <>
                <Tooltip target=".invalid-start-tooltip" />
                <span
                  className="pi pi-exclamation-circle invalid-start-tooltip"
                  style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                  data-pr-tooltip={getErrorMessage(formik.errors, 'start')}
                  data-pr-position="left"
                ></span>
              </>
            )}
          </div>
        </div>

        <div>
          <label htmlFor="end">End Date</label>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
            <Calendar
              id="end"
              name="end"
              value={formik.values.end}
              onChange={(e) => formik.setFieldValue('end', e.value)}
              placeholder="Select a end date"
              className={classNames({
                'p-invalid': formik.dirty && Boolean(formik.errors.end)
              })}
              style={{ width: '100%' }}
            />
            {formik.dirty && formik.errors.end && (
              <>
                <Tooltip target=".invalid-end-tooltip" />
                <span
                  className="pi pi-exclamation-circle invalid-end-tooltip"
                  style={{ fontSize: '1.5rem', marginLeft: '.25em', color: '#ef9a9a' }}
                  data-pr-tooltip={getErrorMessage(formik.errors, 'end')}
                  data-pr-position="left"
                ></span>
              </>
            )}
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', marginTop: '.4em' }}>
          <Checkbox
            name="active"
            id="active"
            onChange={(e) => {
              formik.setFieldValue('active', e.checked ? true : false);
            }}
            checked={formik.values.active}
          ></Checkbox>
          <label className="pl-2" htmlFor="active">
            Active
          </label>
        </div>

        <div className="grid">
          <div className="col">
            <Button
              color="primary"
              type="submit"
              style={{ justifyContent: 'center', width: '100%' }}
              loading={loading}
              disabled={!(formik.isValid && formik.dirty) || disabled || loading}
            >
              Save
            </Button>
          </div>
          {onCancel !== undefined && (
            <div className="col">
              <Button
                color="primary"
                type="button"
                style={{ justifyContent: 'center', width: '100%' }}
                loading={loading}
                disabled={loading}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};
