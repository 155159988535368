import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import { useEffect, useState } from 'react';
import { Link, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../auth/auth';
import { Contest } from '../common/models/contest';
import { ContestStatus } from '../common/models/contest-status';
import {
  isUserActiveAdmin,
  isUserActiveCreator,
  isUserActiveSubscriber
} from '../utils/admin-utils';
import { getContestsImActiveIn } from '../utils/contest-utils';
import './AppHeader.css';
import { getSeasonsImActiveIn } from '../utils/season-utils';
import { Season } from '../common/models/season';

const excludeRoutes = [{ path: '/signin' }, { path: '/signout' }];

export function AppHeader() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [hide, setHide] = useState(false);
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCreator, setIsCreator] = useState(false);

  const [contests, setContests] = useState<Contest[]>([]);
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    const matchedExcludedRoutes = matchRoutes(excludeRoutes, location);
    if (matchedExcludedRoutes?.length) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [location]);

  useEffect(() => {
    isUserActiveSubscriber(auth).then((isSubscriber) => {
      setIsSubscriber(isSubscriber);
      getContestsImActiveIn(auth)
        .then((contests) => {
          setContests(contests);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
      getSeasonsImActiveIn(auth)
        .then((seasons) => {
          setSeasons(seasons);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    });
    isUserActiveCreator(auth).then((isCreator) => {
      setIsCreator(isCreator);
    });
    isUserActiveAdmin(auth).then((isAdmin) => {
      setIsAdmin(isAdmin);
    });
  }, [auth]);

  useEffect(() => {
    const currentContests = contests.filter((contest) => contest.status === ContestStatus.ACTIVE);
    const contestsMenuItems = menuItems.find((item) => item.label === 'Contests');
    if (contestsMenuItems?.items) {
      const newActiveContestsMenuItems: MenuItem[] = currentContests.map((contest) => {
        return {
          label: contest.name,
          command: () => {
            navigate(`/contests/${contest.id}`);
          }
        };
      });
      if (newActiveContestsMenuItems.length) {
        (contestsMenuItems.items as MenuItem[]).unshift({
          label: 'Current Contests',
          icon: 'pi pi-spin pi-spinner',
          items: newActiveContestsMenuItems
        } as MenuItem);
      }
    }
  }, [contests]);

  useEffect(() => {
    const currentSeasons = seasons.filter((season) => season.status === ContestStatus.ACTIVE);
    const seasonsMenuItems = menuItems.find((item) => item.label === 'Seasons');
    if (seasonsMenuItems?.items) {
      const newActiveSeasonsMenuItems: MenuItem[] = currentSeasons.map((season) => {
        return {
          label: season.name,
          command: () => {
            navigate(`/seasons/${season.id}`);
          }
        };
      });
      if (newActiveSeasonsMenuItems.length) {
        (seasonsMenuItems.items as MenuItem[]).unshift({
          label: 'Current Seasons',
          icon: 'pi pi-spin pi-spinner',
          items: newActiveSeasonsMenuItems
        } as MenuItem);
      }
    }
  }, [seasons]);

  useEffect(() => {
    const contestItems: MenuItem[] = [];
    if (isSubscriber) {
      contestItems.push({
        label: 'All Contests',
        icon: 'pi pi-fw pi-list',
        command: () => {
          navigate('/contests');
        }
      });
    }
    const seasonItems: MenuItem[] = [];
    if (isSubscriber) {
      seasonItems.push({
        label: 'All Seasons',
        icon: 'pi pi-fw pi-list',
        command: () => {
          navigate('/seasons');
        }
      });
    }
    if (isCreator) {
      contestItems.push({
        label: 'Create a Contest',
        icon: 'pi pi-fw pi-plus-circle',
        command: () => {
          navigate('/contests/new');
        }
      });
    }

    const adminItems: MenuItem[] = [];
    if (isAdmin) {
      adminItems.push({
        label: 'Admin Home',
        icon: 'pi pi-fw pi-cog',
        command: () => {
          navigate('/admin');
        }
      });
      adminItems.push({
        label: 'Teams',
        icon: 'pi pi-fw pi-list',
        command: () => {
          navigate('/teams');
        }
      });
      adminItems.push({
        label: 'Games',
        icon: 'pi pi-fw pi-list',
        command: () => {
          navigate('/games');
        }
      });
      adminItems.push({
        label: 'Contests',
        icon: 'pi pi-fw pi-list',
        command: () => {
          navigate('/contests/admin');
        }
      });
      adminItems.push({
        label: 'Contest Setups',
        icon: 'pi pi-fw pi-list',
        command: () => {
          navigate('/contest-setups');
        }
      });
      adminItems.push({
        label: 'Create a Contest Setup',
        icon: 'pi pi-fw pi-plus-circle',
        command: () => {
          navigate('/contest-setups/new');
        }
      });
    }

    const items: MenuItem[] = [];
    if (auth.user) {
      if (contestItems?.length) {
        items.push({
          label: 'Contests',
          items: contestItems
        });
      }
      if (seasonItems?.length) {
        items.push({
          label: 'Seasons',
          items: seasonItems
        });
      }
      // if (isSubscriber) {
      //   items.push({
      //     label: 'Research',
      //     command: () => {
      //       navigate('/research');
      //     }
      //   });
      // }
      if (isAdmin) {
        items.push({
          label: 'Admin',
          icon: 'pi pi-fw pi-cog',
          items: adminItems
        });
      }
      items.push({
        label: auth.user?.user.displayName || auth.user?.user.email || 'Guest',
        icon: 'pi pi-fw pi-user',
        items: [
          {
            label: 'Account Settings',
            icon: 'pi pi-fw pi-cog',
            command: () => {
              navigate('/account');
            }
          },
          {
            label: 'Sign Out',
            icon: 'pi pi-fw pi-sign-out',
            command: () => {
              navigate('/signout');
            }
          }
        ]
      });
    } else {
      items.push({
        label: 'Sign In',
        icon: 'pi pi-fw pi-sign-in',
        command: () => {
          navigate('/signin');
        }
      });
    }
    setMenuItems(items);
  }, [auth, isSubscriber, isAdmin, isCreator]);

  const title = (
    <Link to={auth.user ? '/home' : '/'} style={{ textDecoration: 'none' }}>
      <span style={{ color: '#386dd1', fontFamily: 'Pacifico Regular', fontSize: '1.25em' }}>
        Take The Points
      </span>
    </Link>
  );

  return (
    <>
      {!hide && (
        <div
          className="app-header"
          style={{ paddingBottom: '0em', marginBottom: '.5em', borderBottom: '.5px #91a5cb solid' }}
        >
          <Menubar model={menuItems} start={title} />
        </div>
      )}
    </>
  );
}
