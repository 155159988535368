import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Team } from '../common/models/team';
import { ErrorBlock } from '../components/ErrorBlock';
import { LoadingBlock } from '../components/LoadingBlock';
import PageHeader from '../components/PageHeader';
import useGlobalToasts from '../hooks/global-toasts';
import { saveContestSetup } from '../utils/contest-utils';
import { getAllTeams } from '../utils/team-utils';
import { ContestSetupForm } from './ContestSetupForm';

export const NewContestSetup = () => {
  const navigate = useNavigate();
  const globalToasts = useGlobalToasts();

  const [teams, setTeams] = useState<Team[]>([]);
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [errorTeams, setErrorTeams] = useState<string | undefined>(undefined);

  const [savingContestSetup, setSavingContestSetup] = useState(false);

  useEffect(() => {
    setErrorTeams(undefined);
    setLoadingTeams(false);
    getAllTeams()
      .then((teams) => {
        setTeams(teams);
        setLoadingTeams(false);
      })
      .catch((error) => {
        setErrorTeams(error.message);
        setLoadingTeams(false);
      });
  }, []);

  return (
    <>
      {errorTeams && <ErrorBlock />}
      {!errorTeams && loadingTeams && <LoadingBlock />}
      {!errorTeams && !loadingTeams && (
        <>
          <PageHeader title="New Contest Setup" />
          <ContestSetupForm
            teams={teams}
            loading={savingContestSetup}
            disabled={savingContestSetup}
            onSubmit={(contestSetup) => {
              setSavingContestSetup(true);
              saveContestSetup(contestSetup)
                .then(() => {
                  globalToasts.sendToast(
                    'success',
                    'Contest Setup Saved',
                    'Contest Setup saved successfully'
                  );
                  navigate('/contest-setups');
                })
                .catch((error) => {
                  setSavingContestSetup(false);
                  globalToasts.sendToast('error', 'Error Saving Contest Setup', error.message);
                });
            }}
            onCancel={() => {
              navigate('/contest-setups');
            }}
          />
        </>
      )}
    </>
  );
};
