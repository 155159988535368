export enum GameStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  UPCOMING = 'UPCOMING',
  COMPLETE = 'COMPLETE',
  UNAVAILABLE = 'UNAVAILABLE'
}

export const GameStatusSortOrder = {
  [GameStatus.UPCOMING]: 0,
  [GameStatus.IN_PROGRESS]: 1,
  [GameStatus.COMPLETE]: 2,
  [GameStatus.UNAVAILABLE]: 3
};

export const compareGameStatus = (a: GameStatus, b: GameStatus) => {
  return GameStatusSortOrder[a] - GameStatusSortOrder[b];
};
