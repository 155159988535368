import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../auth/auth';
import { Contest } from '../common/models/contest';
import { InputSwitch } from 'primereact/inputswitch';
import {
  ContestStatus,
  ContestStatusIcon,
  ContestStatusName
} from '../common/models/contest-status';
import { ErrorBlock } from '../components/ErrorBlock';
import IconButtonWithTooltip from '../components/IconButtonWithTooltip';
import IconWithTooltip from '../components/IconWithTooltip';
import { InfoBlock } from '../components/InfoBlock';
import { LoadingBlock } from '../components/LoadingBlock';
import useGlobalToasts from '../hooks/global-toasts';
import { isUserActiveAdmin } from '../utils/admin-utils';
import { deleteContest } from '../utils/contest-utils';
import { formatShortDatestamp } from '../utils/utils';

export interface ContestTableProps {
  contests: Contest[];
  allowDelete?: boolean;
  allowEditing?: boolean;
  onContestDeleted?: (contest: Contest) => void;
}

export const ContestTable = (props: ContestTableProps) => {
  const { contests: incomingContests, allowDelete, allowEditing, onContestDeleted } = props;
  const activeAllowDelete = !allowDelete ? false : allowDelete;
  const activeAllowEditing = !allowEditing ? false : allowEditing;

  const auth = useAuth();
  const globalToasts = useGlobalToasts();

  const [contests, setContests] = useState<Contest[]>([]);
  const [loading, setLoading] = useState(false);
  const [error] = useState<string>();

  const [activeAdmin, setActiveAdmin] = useState<boolean>(false);

  const [contestToDelete, setContestToDelete] = useState<Contest | undefined>(undefined);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [deletingContest, setDeletingContest] = useState(false);

  const [sortBy, setSortBy] = useState<'start' | 'end' | 'status'>('status');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const sortByOptions = [
    { label: 'Start Date', value: 'start' },
    { label: 'End Date', value: 'end' },
    { label: 'Status', value: 'status' }
  ];

  const [showCompletedContests, setShowCompletedContests] = useState<boolean>(false);

  const [sortingOrFiltering, setSortingOrFiltering] = useState<boolean>(false);

  const deleteSelectedContest = () => {
    setDeletingContest(true);
    if (!contestToDelete) {
      setDeletingContest(false);
      setShowConfirmDeleteDialog(false);
      globalToasts.sendToast('error', 'Error', 'Unable to delete contest.', 3000);
      return;
    }

    deleteContest(contestToDelete, true)
      .then(() => {
        setDeletingContest(false);
        setShowConfirmDeleteDialog(false);
        if (onContestDeleted) {
          onContestDeleted(contestToDelete);
        }
      })
      .catch((error) => {
        console.error('Unable to delete contest.', error);
        setDeletingContest(false);
        setShowConfirmDeleteDialog(false);
        globalToasts.sendToast('error', 'Error', 'Unable to delete contest.', 3000);
      });
  };

  useEffect(() => {
    isUserActiveAdmin(auth)
      .then((activeAdmin) => {
        setActiveAdmin(activeAdmin);
        setLoading(false);
      })
      .catch(() => {
        setActiveAdmin(false);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setSortingOrFiltering(true);
    const filteredContests = incomingContests.filter((contest) => {
      if (showCompletedContests) {
        return true;
      } else {
        return contest.status === ContestStatus.ACTIVE;
      }
    });
    const sortedContests = filteredContests.sort((a: Contest, b: Contest) => {
      let compareValue = 0;
      if (sortBy === 'start') {
        if (sortDirection === 'asc') {
          compareValue = a.start.toMillis() - b.start.toMillis();
        } else {
          compareValue = b.start.toMillis() - a.start.toMillis();
        }
      } else if (sortBy === 'end') {
        if (sortDirection === 'asc') {
          compareValue = a.end.toMillis() - b.end.toMillis();
        } else {
          compareValue = b.end.toMillis() - a.end.toMillis();
        }
      } else if (sortBy === 'status') {
        const aStatus =
          a.status === ContestStatus.ACTIVE
            ? 1
            : a.status === ContestStatus.COMPLETE
            ? 2
            : a.status === ContestStatus.LOCKED
            ? 3
            : 0;
        const bStatus =
          b.status === ContestStatus.ACTIVE
            ? 1
            : b.status === ContestStatus.COMPLETE
            ? 2
            : b.status === ContestStatus.LOCKED
            ? 3
            : 0;
        if (sortDirection === 'asc') {
          compareValue = aStatus - bStatus;
        } else {
          compareValue = bStatus - aStatus;
        }
      }
      if (compareValue !== 0) {
        return compareValue;
      } else {
        if (sortDirection === 'asc') {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      }
    });
    setTimeout(() => {
      setContests(sortedContests);
      setSortingOrFiltering(false);
    }, 100);
  }, [sortBy, sortDirection, showCompletedContests]);

  const contestNameTemplate = (contest: Contest) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <IconWithTooltip
            icon={ContestStatusIcon[contest.status]}
            tooltip={`${ContestStatusName[contest.status]} (${formatShortDatestamp(
              contest.start
            )} - ${formatShortDatestamp(contest.end)})`}
          ></IconWithTooltip>
        </div>
        <div style={{ marginLeft: '.4em' }}>
          <Link to={`/contests/${contest.id}`}>{contest.name}</Link>
        </div>
      </div>
    );
  };

  const contestActionsTemplate = (contest: Contest) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center'
        }}
      >
        {activeAllowEditing && contest.owner === auth?.user?.user?.email?.toLowerCase() && (
          <div style={{ paddingLeft: '.1em', paddingRight: '.1em' }}>
            <Link to={`/contests/${contest.id}/edit`}>
              <IconButtonWithTooltip icon="pi pi-file-edit" tooltip="Edit Contest" />
            </Link>
          </div>
        )}
        {activeAllowDelete && activeAdmin && (
          <div style={{ paddingLeft: '.1em', paddingRight: '.1em' }}>
            <IconButtonWithTooltip
              icon="pi-delete-left"
              tooltip="Delete Contest"
              onClick={() => {
                setContestToDelete(contest);
                setShowConfirmDeleteDialog(true);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  if (error) {
    return <ErrorBlock message={error} />;
  } else if (loading) {
    return <LoadingBlock />;
  } else {
    return (
      <>
        <Dialog
          header={`Delete Contest: ${contestToDelete?.name}?`}
          visible={showConfirmDeleteDialog}
          style={{ width: '95vw' }}
          onHide={() => {
            setContestToDelete(undefined);
            setShowConfirmDeleteDialog(false);
          }}
        >
          <InfoBlock message="Are you sure you want to delete this contest? This will delete all associated bets and cannot be undone?"></InfoBlock>
          <div className="mt-2" style={{ textAlign: 'center' }}>
            <Button
              className="mr-1"
              label="Delete"
              disabled={deletingContest}
              onClick={deleteSelectedContest}
            />
            <Button
              label="Cancel"
              disabled={deletingContest}
              onClick={() => setShowConfirmDeleteDialog(false)}
            />
          </div>
        </Dialog>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            marginBottom: '.5em'
          }}
        >
          <div style={{ maxWidth: '70%' }}>
            <div className="mb-1 font-size-small">Sort by</div>
            <div className="p-inputgroup">
              <Dropdown
                value={sortBy}
                onChange={(e) => setSortBy(e.value)}
                options={sortByOptions}
                optionLabel="label"
                optionValue="value"
                disabled={sortingOrFiltering}
              />
              <Button
                icon="pi pi-sort-amount-up-alt"
                outlined={sortDirection === 'desc'}
                disabled={sortingOrFiltering || sortDirection === 'asc'}
                title="Sort Ascending"
                onClick={() => {
                  setSortDirection('asc');
                }}
              ></Button>
              <Button
                icon="pi pi-sort-amount-down"
                outlined={sortDirection === 'asc'}
                disabled={sortingOrFiltering || sortDirection === 'desc'}
                title="Sort Descending"
                onClick={() => {
                  setSortDirection('desc');
                }}
              ></Button>
            </div>
          </div>
          <div style={{ textAlign: 'right' }}>
            <div className="mb-1 font-size-small">Show All</div>
            <div>
              <InputSwitch
                checked={showCompletedContests}
                onChange={(e) => setShowCompletedContests(e.value ? true : false)}
              />
            </div>
          </div>
        </div>
        {sortingOrFiltering && (
          <div className="mt-4">
            <LoadingBlock message="Sorting/Filtering contests..." />
          </div>
        )}
        {!sortingOrFiltering && (
          <DataTable
            size="small"
            paginator
            paginatorTemplate={{
              layout: 'PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown'
            }}
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            value={contests}
          >
            <Column header="Contest" field="name" body={contestNameTemplate}></Column>
            <Column field="id" body={contestActionsTemplate}></Column>
          </DataTable>
        )}
      </>
    );
  }
};
