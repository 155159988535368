import axios from 'axios';
import { Avatar } from 'primereact/avatar';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useState } from 'react';
import useAuth from '../auth/auth';
import { PublicUserProfile } from '../common/models/public-user-profile';
import { getPublicUserProfileForEmail } from '../utils/user-utils';
import {
  generateAvatarInitialsForDisplayName,
  generateAvatarInitialsForEmail
} from '../utils/utils';

export interface UserAvatarProps {
  email?: string;
  labelOnly?: boolean;
  size?: 'normal' | 'large' | 'xlarge' | undefined;
}

const defaultTooltipPosition = 'left';

export const UserAvatar = (props: UserAvatarProps) => {
  const auth = useAuth();
  const { email, labelOnly, size } = props;
  const activeSize = size ?? 'normal';
  const activeLabelOnly = labelOnly ?? false;

  let activeEmail: string | undefined | null = email?.trim().toLowerCase();
  if (!email?.length) {
    activeEmail = auth?.user?.user?.email?.trim().toLowerCase();
  }

  const [userEmail] = useState<string | undefined | null>(activeEmail);
  const [publicUserProfile, setPublicUserProfile] = useState<PublicUserProfile | null>(null);
  const [loadingPublicUserProfile, setLoadingPublicUserProfile] = useState<boolean>(true);

  const isProfilePhotoUrlValid = async (url: string): Promise<boolean> => {
    try {
      const response = await axios.get(url, {
        responseType: 'arraybuffer'
      });
      if (!response || response.status !== 200) {
        throw new Error(`Unable to fetch image, response status was ${response?.status} not 200`);
      }
      return true;
    } catch (error) {
      throw new Error(`Unable to fetch image, an error occurred: ${error}`);
    }
  };

  useEffect(() => {
    getPublicUserProfileForEmail(userEmail)
      .then((publicUserProfile) => {
        if (!publicUserProfile) {
          setPublicUserProfile(null);
          setLoadingPublicUserProfile(false);
          return;
        }

        if (publicUserProfile.photoUrl?.length) {
          isProfilePhotoUrlValid(publicUserProfile.photoUrl)
            .then((isValid) => {
              if (isValid) {
                setPublicUserProfile(publicUserProfile);
              } else {
                publicUserProfile.photoUrl = null;
                setPublicUserProfile(publicUserProfile);
              }
              setLoadingPublicUserProfile(false);
            })
            .catch(() => {
              publicUserProfile.photoUrl = null;
              setPublicUserProfile(publicUserProfile);
              setLoadingPublicUserProfile(false);
            });
        } else {
          setLoadingPublicUserProfile(false);
        }
      })
      .catch(() => {
        setPublicUserProfile(null);
        setLoadingPublicUserProfile(false);
      });
  }, [userEmail]);

  return (
    <>
      {loadingPublicUserProfile && (
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%'
          }}
        >
          {!activeLabelOnly && (
            <>
              <Tooltip target=".target-icon" />
              <Avatar
                label={'...'}
                className="target-icon"
                size={activeSize}
                shape="circle"
                data-pr-tooltip={'Loading...'}
                data-pr-position={defaultTooltipPosition}
              />
            </>
          )}
          {activeLabelOnly && (
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>Loading...</div>
          )}
        </div>
      )}
      {!loadingPublicUserProfile &&
        publicUserProfile &&
        publicUserProfile.photoUrl?.length &&
        publicUserProfile.showPhoto && (
          <>
            {!activeLabelOnly && (
              <>
                <Tooltip target=".target-icon" />
                <Avatar
                  image={publicUserProfile.photoUrl}
                  className="target-icon"
                  size={activeSize}
                  shape="circle"
                  data-pr-tooltip={publicUserProfile.name}
                  data-pr-position={defaultTooltipPosition}
                />
              </>
            )}
            {activeLabelOnly && (
              <div style={{ paddingLeft: '.25em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {publicUserProfile.name}
              </div>
            )}
          </>
        )}
      {!loadingPublicUserProfile &&
        publicUserProfile &&
        (!publicUserProfile.photoUrl?.length || !publicUserProfile.showPhoto) && (
          <>
            {!activeLabelOnly && (
              <>
                <Tooltip target=".target-icon" />
                <Avatar
                  label={generateAvatarInitialsForDisplayName(publicUserProfile.name).toUpperCase()}
                  className="target-icon"
                  size={activeSize}
                  shape="circle"
                  data-pr-tooltip={publicUserProfile.name}
                  data-pr-position={defaultTooltipPosition}
                />
              </>
            )}
            {activeLabelOnly && (
              <div style={{ paddingLeft: '.25em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {publicUserProfile.name}
              </div>
            )}
          </>
        )}
      {!loadingPublicUserProfile && !publicUserProfile && (
        <>
          {!activeLabelOnly && (
            <>
              <Tooltip target=".target-icon" />
              <Avatar
                label={generateAvatarInitialsForEmail(userEmail).toUpperCase()}
                className="target-icon"
                size={activeSize}
                shape="circle"
                data-pr-tooltip={
                  userEmail?.length ? userEmail.trim().toLowerCase() : 'Guest Member'
                }
                data-pr-position={defaultTooltipPosition}
              />
            </>
          )}
          {activeLabelOnly && (
            <div style={{ paddingLeft: '.25em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {userEmail?.length ? userEmail.trim().toLowerCase() : 'Guest Member'}
            </div>
          )}
        </>
      )}
    </>
  );
};
